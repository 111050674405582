import { getTeethKeyFromLabel, TEETH_SELECT_ARRAY } from "../../../utils/studyUtils";
import TeethCheckItem from "./TeethCheckItem";
import * as S from "./style/TeethTable.style";

export function getTeethLabelList(label, age) {
  let teeth_label = [18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 48, 47, 46, 45, 44, 43, 42, 41, 31, 32, 33, 34, 35, 36, 37, 38];

  if (label === "permanent" && age === "child") {
    teeth_label = [16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 46, 45, 44, 43, 42, 41, 31, 32, 33, 34, 35, 36];
  } else if (label === "permanent_predict") {
    teeth_label = [15, 14, 13, 23, 24, 25, 45, 44, 43, 33, 34, 35];
  }

  return teeth_label;
}

function TeethCheck({ values, studyInfo, handleChange, label, code, readonly, config = TEETH_SELECT_ARRAY }) {
  const teeth_label = getTeethLabelList(label, studyInfo.age);

  /**자르는 선 계산용 */
  const sliceNumber = teeth_label.length / 4;

  /**어린이면서 영구치 */
  const isChildPerment = label === "permanent" && studyInfo.age === "child";
  const excludeTeethNumberWhenIsChild = ["1", "2", "15", "16", "17", "18", "31", "32"];
  const filteredConfig = isChildPerment || label === "permanent_predict" ? Object.entries(config).filter(([key]) => !excludeTeethNumberWhenIsChild.includes(key)) : Object.entries(config);

  /**어린이일 경우 */
  const babyTeethValues = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "T", "S", "R", "Q", "P", "O", "N", "M", "L", "K"];
  const babyTeeth_label = [55, 54, 53, 52, 51, 61, 62, 63, 64, 65, 85, 84, 83, 82, 81, 71, 72, 73, 74, 75];

  /**자르는 선 계산용 */
  const sliceNumberBaby = babyTeeth_label.length / 4;

  return (
    <>
      <S.TeethContainer>
        <S.TeethDirection>R</S.TeethDirection>
        <S.TeethTable>
          <tbody>
            {(!studyInfo.SI01 || studyInfo.SI01 === "1" || studyInfo.SI01 === "2") && (
              <tr>
                <td>
                  <div>
                    {(studyInfo.SI01 === "1" || studyInfo.SI01 === "2") && label === "permanent_predict" && (
                      <S.TeethItem>
                        <S.TeethItemLabel>
                          <S.TeethItemInput
                            $TeethRadio
                            type="checkbox"
                            name="SI14_02_2_01"
                            onChange={handleChange}
                            checked={values.SI14_01 === "2" && Array.isArray(values.SI14_02_2_01) && values.SI14_02_2_01.includes("2")}
                            disabled={values.SI14_01 === "1" || !values.SI14_01}
                            value="2"
                          />
                          <S.TeethItemSpan>17</S.TeethItemSpan>
                        </S.TeethItemLabel>
                      </S.TeethItem>
                    )}
                    {filteredConfig
                      .slice(0, sliceNumber)
                      .map(
                        ([key, value], index) =>
                          value && (
                            <TeethCheckItem
                              code={code}
                              itemName={`${code}_2_01`}
                              key={key}
                              value={key}
                              teethLabel={teeth_label[index]}
                              values={values}
                              handleChange={handleChange}
                              readonly={readonly}
                              TeethCheckbox
                            />
                          )
                      )}
                  </div>
                  {studyInfo.age === "child" && (
                    <div>
                      {filteredConfig
                        .slice(0, sliceNumberBaby)
                        .map(
                          ([key, value], index) =>
                            value && (
                              <TeethCheckItem
                                code={code}
                                itemName={`${code}_2_02`}
                                key={key}
                                value={babyTeethValues[index]}
                                teethLabel={babyTeeth_label[index]}
                                values={values}
                                handleChange={handleChange}
                                readonly={readonly}
                                TeethRadio
                              />
                            )
                        )}
                    </div>
                  )}
                </td>
                <td>
                  <div>
                    {filteredConfig.slice(sliceNumber, sliceNumber * 2).map(([key, value], index) => {
                      return (
                        value && (
                          <TeethCheckItem
                            code={code}
                            itemName={`${code}_2_01`}
                            key={key}
                            value={key}
                            teethLabel={teeth_label[sliceNumber + index]}
                            values={values}
                            handleChange={handleChange}
                            readonly={readonly}
                            TeethCheckbox
                          />
                        )
                      );
                    })}
                    {(studyInfo.SI01 === "1" || studyInfo.SI01 === "2") && label === "permanent_predict" && (
                      <S.TeethItem>
                        <S.TeethItemLabel>
                          <S.TeethItemInput
                            $TeethRadio
                            type="checkbox"
                            name="SI14_02_2_02"
                            onChange={handleChange}
                            checked={values.SI14_01 === "2" && Array.isArray(values.SI14_02_2_02) && values.SI14_02_2_02.includes("15")}
                            disabled={values.SI14_01 === "1" || !values.SI14_01}
                            value="15"
                          />
                          <S.TeethItemSpan>27</S.TeethItemSpan>
                        </S.TeethItemLabel>
                      </S.TeethItem>
                    )}
                  </div>
                  {studyInfo.age === "child" && (
                    <div>
                      {filteredConfig.slice(sliceNumberBaby, sliceNumberBaby * 2).map(([key, value], index) => {
                        return (
                          value && (
                            <TeethCheckItem
                              code={code}
                              itemName={`${code}_2_02`}
                              key={key}
                              value={babyTeethValues[sliceNumberBaby + index]}
                              teethLabel={babyTeeth_label[sliceNumberBaby + index]}
                              values={values}
                              handleChange={handleChange}
                              readonly={readonly}
                              TeethRadio
                            />
                          )
                        );
                      })}
                    </div>
                  )}
                </td>
              </tr>
            )}

            {(!studyInfo.SI01 || studyInfo.SI01 === "1" || studyInfo.SI01 === "3") && (
              <tr>
                <td>
                  {studyInfo.age === "child" && (
                    <div>
                      {filteredConfig
                        .slice(sliceNumberBaby * 2, sliceNumberBaby * 3)
                        .map(
                          ([key, value], index) =>
                            value && (
                              <TeethCheckItem
                                code={code}
                                itemName={`${code}_2_02`}
                                key={key}
                                value={babyTeethValues[sliceNumberBaby * 2 + index]}
                                teethLabel={babyTeeth_label[sliceNumberBaby * 2 + index]}
                                values={values}
                                handleChange={handleChange}
                                readonly={readonly}
                                TeethRadio
                              />
                            )
                        )}
                    </div>
                  )}
                  <div>
                    {(studyInfo.SI01 === "1" || studyInfo.SI01 === "3") && label === "permanent_predict" && (
                      <S.TeethItem>
                        <S.TeethItemLabel>
                          <S.TeethItemInput
                            $TeethRadio
                            type="checkbox"
                            name="SI14_02_2_03"
                            onChange={handleChange}
                            checked={values.SI14_01 === "2" && Array.isArray(values.SI14_02_2_03) && values.SI14_02_2_03.includes("31")}
                            disabled={values.SI14_01 === "1" || !values.SI14_01}
                            value="31"
                          />
                          <S.TeethItemSpan>47</S.TeethItemSpan>
                        </S.TeethItemLabel>
                      </S.TeethItem>
                    )}

                    {filteredConfig
                      .slice(sliceNumber * 2, sliceNumber * 3)
                      .map(
                        ([key, value], index) =>
                          value && (
                            <TeethCheckItem
                              code={code}
                              itemName={`${code}_2_01`}
                              key={key}
                              value={getTeethKeyFromLabel(teeth_label[sliceNumber * 2 + index])}
                              teethLabel={teeth_label[sliceNumber * 2 + index]}
                              values={values}
                              handleChange={handleChange}
                              readonly={readonly}
                              TeethCheckbox
                            />
                          )
                      )}
                  </div>
                </td>

                <td>
                  {studyInfo.age === "child" && (
                    <div>
                      {filteredConfig
                        .slice(sliceNumberBaby * 3, sliceNumberBaby * 4)
                        .map(
                          ([key, value], index) =>
                            value && (
                              <TeethCheckItem
                                code={code}
                                itemName={`${code}_2_02`}
                                key={key}
                                value={babyTeethValues[sliceNumberBaby * 3 + index]}
                                teethLabel={babyTeeth_label[sliceNumberBaby * 3 + index]}
                                values={values}
                                handleChange={handleChange}
                                readonly={readonly}
                                TeethRadio
                              />
                            )
                        )}
                    </div>
                  )}
                  <div>
                    {filteredConfig
                      .slice(sliceNumber * 3, sliceNumber * 4)
                      .map(
                        ([key, value], index) =>
                          value && (
                            <TeethCheckItem
                              code={code}
                              itemName={`${code}_2_01`}
                              key={key}
                              value={getTeethKeyFromLabel(teeth_label[sliceNumber * 3 + index])}
                              teethLabel={teeth_label[sliceNumber * 3 + index]}
                              values={values}
                              handleChange={handleChange}
                              readonly={readonly}
                              TeethCheckbox
                            />
                          )
                      )}

                    {(studyInfo.SI01 === "1" || studyInfo.SI01 === "3") && label === "permanent_predict" && (
                      <S.TeethItem>
                        <S.TeethItemLabel>
                          <S.TeethItemInput
                            $TeethRadio
                            type="checkbox"
                            name="SI14_02_2_04"
                            onChange={handleChange}
                            checked={values.SI14_01 === "2" && Array.isArray(values.SI14_02_2_04) && values.SI14_02_2_04.includes("18")}
                            disabled={values.SI14_01 === "1" || !values.SI14_01}
                            value="18"
                          />
                          <S.TeethItemSpan>37</S.TeethItemSpan>
                        </S.TeethItemLabel>
                      </S.TeethItem>
                    )}
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </S.TeethTable>
        <S.TeethDirection>L</S.TeethDirection>
      </S.TeethContainer>
    </>
  );
}

export default TeethCheck;
