import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useSaveStep } from "../../../../../apis/studyApi";
import { CardTail, CardTitle } from "../../../../../components/Layout/CardLayout/CardLayout";
import * as S3 from "../../../../../components/Layout/CardLayout/style/CardLayout.style";
import * as S from "../../../../../components/element/Button/style/ButtonLayout.style";
import { FieldMessageErrorClick } from "../../../../../components/element/FieldMessage/FieldMessage";
import { FormControlsStudyBox } from "../../../../../components/element/Form/FormLayout";
import { IK } from "../../../../../utils/i18n/keyStore";
import { useStudyContext } from "../../../../Study/StudyProvider";
const DeviceDeliveryUnit = () => {
  const { t } = useTranslation(["translation"]);
  const { studyInfo, setStudyInfo, next, prev, setCurrentStepIndex, studyStep, isFinish } = useStudyContext();
  const { mutate: saveStepMutation, isLoading } = useSaveStep();

  /**요약인지 다음인지 구분 */
  const [skip, setSkip] = useState(false);

  const validationSchema = Yup.object().shape({
    SI17: Yup.string().required(t(IK.device_delivery_unit_required)),
  });

  const handleSubmit = (data) => {
    saveStepMutation(
      {
        step: "SI17",
        data,
        params: {
          studyId: studyInfo.studyId,
          patientId: studyInfo.patientId,
          isSaveStep: !isFinish,
        },
      },
      {
        onSuccess: () => {
          setStudyInfo({ ...studyInfo, ...data });
          isFinish && skip ? setCurrentStepIndex(studyStep.length - 2) : next();
        },
      }
    );
  };

  return (
    <>
      <CardTitle title={studyInfo.patientName} subTitle={`${t(IK[studyInfo.packages])}`} required />
      <Formik
        initialValues={{
          SI17: studyInfo.SI17 || "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnMount={true}
      >
        <Form>
          <FormControlsStudyBox title={t(IK.device_delivery_unit)} required>
            <div className="frm_column">
              <div className="frm_div">
                <div className="radio">
                  <label className="label_radio">
                    <Field type="radio" name="SI17" className="input_radio" value="1" />
                    <span>{t(IK.device_delivery_unit_item1_1)}</span>
                  </label>
                </div>
              </div>

              <div className="frm_div">
                <div className="radio">
                  <label className="label_radio">
                    <Field type="radio" name="SI17" className="input_radio" value="2" />
                    <span>{t(IK.device_delivery_unit_item1_2)}</span>
                  </label>
                </div>
              </div>
            </div>
            <FieldMessageErrorClick name="SI17" />
          </FormControlsStudyBox>
          <CardTail line study>
            <S3.CardFlex>
              <S.StyledButton $secondary as="button" type="button" onClick={() => setCurrentStepIndex(1)}>
                {t(IK.select_treatment_options)}
              </S.StyledButton>
              <S.ButtonLtBox>
                <S.StyledButton as="button" type="button" onClick={prev}>
                  {t(IK.prev)}
                </S.StyledButton>
                <S.StyledButton as="button" type="submit" disabled={isLoading} $primary>
                  {t(IK.next)}
                </S.StyledButton>
                {isFinish && (
                  <S.StyledButton as="button" type="submit" onClick={() => setSkip(true)} disabled={isLoading} $primary>
                    {t(IK.Skip_to_prescription_summary)}
                  </S.StyledButton>
                )}
              </S.ButtonLtBox>
            </S3.CardFlex>
          </CardTail>
        </Form>
      </Formik>
    </>
  );
};

export default DeviceDeliveryUnit;
