import { FieldMessageError } from "../../../../components/element/FieldMessage/FieldMessage";
import { ScanPreview } from "../../../../components/element/ScanPreview/ScanPreview";

const Scan = ({ getFieldProps, setFieldValue, errors, setMeditScan, meditScan }) => {
  return (
    <>
      <ScanPreview setFieldValue={setFieldValue} files={getFieldProps("scan").value} meditScan={meditScan || []} setMeditScan={setMeditScan} />
      {errors.scan && <FieldMessageError text={errors.scan} />}
    </>
  );
};

export default Scan;
