import { createContext, useContext } from "react";

const StudyContext = createContext(null);

export const StudyProvider = ({ children, value }) => {
  return <StudyContext.Provider value={value}>{children}</StudyContext.Provider>;
};

export const useStudyContext = () => {
  const context = useContext(StudyContext);
  if (!context) {
    throw new Error("provider 컴포넌트 내부에서 사용해야 합니다.");
  }
  return context;
};
