import React from "react";
import { useTranslation } from "react-i18next";
import * as S from "../../components/element/Step/style/Step.style";
import { IK } from "../../utils/i18n/keyStore";

/**추가 처방전 스텝 라벨 */
const AdditionalStudyStepLabel = ({ additionalStudyStep, currentStepIndex }) => {
  const { t } = useTranslation(["translation"]);

  //스텝 활성화 표시
  const stepActive = additionalStudyStep[currentStepIndex];

  //스탭 여부 확인
  const STEP_CONFIG = new Set([...additionalStudyStep]);

  const activeStep = (label) => {
    return label === stepActive ? "active" : "";
  };

  return (
    <S.StepType2>
      <ol className="progress_index">
        {STEP_CONFIG.has("UPDATE_ADDITIONAL") && (
          <li className={activeStep("UPDATE_ADDITIONAL")}>
            <h3>{t(IK.additional_option)}</h3>
          </li>
        )}
        <li className={stepActive.startsWith("AI") ? "active" : ""}>
          <h3>{t(IK.details_treatment_plan)}</h3>
        </li>
        {STEP_CONFIG.has("EXTRAORAL") && (
          <li className={activeStep("EXTRAORAL")}>
            <h3>{t(IK.extraoral)}</h3>
          </li>
        )}
        {STEP_CONFIG.has("INTRAORAL") && (
          <li className={activeStep("INTRAORAL")}>
            <h3>{t(IK.intraoral)}</h3>
          </li>
        )}
        {STEP_CONFIG.has("RADIOGRAPH") && (
          <li className={activeStep("RADIOGRAPH")}>
            <h3>{t(IK.radiograph)}</h3>
          </li>
        )}
        {STEP_CONFIG.has("SCAN_FILE") && (
          <li className={activeStep("SCAN_FILE")}>
            <h3>{t(IK.scan_file_registration)}</h3>
          </li>
        )}
        {STEP_CONFIG.has("SUMMARY") && (
          <li className={activeStep("SUMMARY")}>
            <h3>{t(IK.prescription_summary)}</h3>
          </li>
        )}
      </ol>
    </S.StepType2>
  );
};

export default AdditionalStudyStepLabel;
