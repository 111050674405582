import { useParams } from "react-router-dom";
import * as S from "../../../components/Layout/CardLayout/style/CardLayout.style";
import ProfileCard from "./section/ProfileCard";
import StudyList from "./section/StudyList";

function Profile() {
  const { patientId } = useParams();

  return (
    <S.CardRow>
      <S.CardCol>
        <S.CardFlex>
          <ProfileCard patientId={patientId} />
          <StudyList patientId={patientId} />
        </S.CardFlex>
      </S.CardCol>
    </S.CardRow>
  );
}

export default Profile;
