import { useTranslation } from "react-i18next";
import { downloadFile } from "../../../apis/boardApi";
import * as S4 from "../../../components/element/Button/style/ButtonLayout.style";
import * as S2 from "../../../components/element/Drop/style/Drop.style";
import * as S from "../../../components/element/Summary/style/Summary.style";
import * as S3 from "../../../styles/Common";
import { IK } from "../../../utils/i18n/keyStore";
import { getSummaryLabel, PERMANENT_TOOTH_ARRAY } from "../../../utils/studyUtils";
import DragImageBox from "../Drop/style/DragImageBox";
import { FormControlsBox } from "../Form/FormLayout";
import TeethSummaryBox from "../TeethTable/TeethSummaryBox";
import { SummaryTableLi } from "./summaryTableLi";

export const SummaryTable = ({ studyInfo, summaryStepMap, handleClickForUpdate }) => {
  const { t } = useTranslation(["translation"]);

  return (
    <S.SummaryList>
      {/* 의사명 */}
      <SummaryTableLi title={t(IK.doctor_name)}>
        {studyInfo.doctor && (
          <p>
            {studyInfo.doctor.name} {studyInfo.doctor?.koreaName ? `(${studyInfo.doctor.koreaName})` : ""}
          </p>
        )}
      </SummaryTableLi>

      {/* 인쇄시 환자명, 치료옵션 보이도록 */}
      <SummaryTableLi printshow title={t(IK.patient_name)}>
        {studyInfo?.patient && <p>{`${studyInfo.patient?.firstName} ${studyInfo.patient?.lastName}${studyInfo.patient?.korName ? ` (${studyInfo.patient?.korName})` : ""}`}</p>}
      </SummaryTableLi>
      <SummaryTableLi printshow title={t(IK.treatment_option)}>
        {studyInfo.study && <p>{`${t(IK[studyInfo.study?.packages])}`}</p>}
      </SummaryTableLi>

      {/* 배송지 */}
      <SummaryTableLi title={t(IK.shipping_address)}>{studyInfo.patient && <p>{studyInfo.patient.shipAddressName}</p>}</SummaryTableLi>
      {/* 청구지 */}
      <SummaryTableLi title={t(IK.billing_address)}>{studyInfo.patient && <p>{studyInfo.patient.billAddressName}</p>}</SummaryTableLi>
      {/* 구외 사진 */}
      <SummaryTableLi title={t(IK.extraoral)} handleClickForUpdate={handleClickForUpdate} dataId="EXTRAORAL">
        <S2.DropListWrap>
          <DragImageBox file={studyInfo.extraoral_front} preview="extraoral_front" />
          <DragImageBox file={studyInfo.front_rest} preview="front_rest" />
          <DragImageBox file={studyInfo.front_smile} preview="front_smile" />
          <DragImageBox file={studyInfo.side45} preview="side45" />
          <DragImageBox file={studyInfo.side90} preview="side90" />
          <DragImageBox file={studyInfo.side90_smile} preview="side90_smile" />
        </S2.DropListWrap>
      </SummaryTableLi>
      {/* 구내 사진 */}
      <SummaryTableLi title={t(IK.intraoral)} handleClickForUpdate={handleClickForUpdate} dataId="INTRAORAL">
        <S2.DropListWrap>
          <DragImageBox file={studyInfo.occlusal_upper} preview="occlusal_upper" />
          <DragImageBox file={studyInfo.intraoral_front} preview="intraoral_front" />
          <DragImageBox file={studyInfo.occlusal_lower} preview="occlusal_lower" />
          <DragImageBox file={studyInfo.buccal_right} preview="buccal_right" />
          <DragImageBox file={studyInfo.overjet} preview="overjet" />
          <DragImageBox file={studyInfo.buccal_left} preview="buccal_left" />
        </S2.DropListWrap>
      </SummaryTableLi>
      {/* 방사선 사진 */}
      <SummaryTableLi title={t(IK.radiograph)} handleClickForUpdate={handleClickForUpdate} dataId="RADIOGRAPH">
        <S2.DropListWrap $radiation>
          <DragImageBox file={studyInfo.panorama} preview="panorama" isRadiation />
          <DragImageBox file={studyInfo.cephalo} preview="cephalo" isRadiation />
        </S2.DropListWrap>
      </SummaryTableLi>
      {/* 스캔파일 */}
      <SummaryTableLi title={t(IK.scan_file_registration)} handleClickForUpdate={handleClickForUpdate} dataId="SCAN_FILE">
        {studyInfo?.scan?.length > 0 ? (
          <S4.ButtonFlex>
            {studyInfo.scan.map((item, index) => {
              return (
                <S4.StyledSmallButton as="button" key={index} onClick={() => downloadFile(item.fileId, item.orgFileName)}>
                  {item.orgFileName}
                </S4.StyledSmallButton>
              );
            })}
          </S4.ButtonFlex>
        ) : (
          <p>
            {/** 석고모델 하위옵션이 있을때만 표출 */}
            {t(IK.send_model)}
            {studyInfo?.SIPlasterModel && `: ${getSummaryLabel(studyInfo, "SIPlasterModel", t)}`} {studyInfo?.SIPhoneNumber && `(${studyInfo?.SIPhoneNumber})`}
          </p>
        )}
      </SummaryTableLi>
      {/* 치료해야 할 악궁 */}
      {summaryStepMap.has("SI01") && (
        <SummaryTableLi title={t(IK.dental_arch_treated)} handleClickForUpdate={handleClickForUpdate} dataId="SI01">
          <p>
            {getSummaryLabel(studyInfo, "SI01", t)}
            {studyInfo.SI01 === "2" && !!studyInfo.SI01_2_01 && `(${getSummaryLabel(studyInfo, "SI01_2_01", t)})`}
            {studyInfo.SI01 === "3" && !!studyInfo.SI01_3_01 && `(${getSummaryLabel(studyInfo, "SI01_3_01", t)})`}
          </p>
        </SummaryTableLi>
      )}
      {/* 발치 할 치아 */}
      {summaryStepMap.has("SI12") && (
        <SummaryTableLi title={t(IK.extraction)} handleClickForUpdate={handleClickForUpdate} dataId="SI12">
          {studyInfo.SI12 === "1" && <p>{t(IK.extraction_item1)}</p>}
          {studyInfo.SI12 === "2" && <TeethSummaryBox label="permanent" studyInfo={studyInfo} code="SI12" />}
        </SummaryTableLi>
      )}
      {/* 이동에 제한이 있는 치아 */}
      {summaryStepMap.has("SI02") && (
        <SummaryTableLi title={t(IK.teeth_limited_mobility)} handleClickForUpdate={handleClickForUpdate} dataId="SI02">
          {studyInfo.SI02 === "1" && <p>{t(IK.teeth_limited_mobility_item1)}</p>}
          {studyInfo.SI02 === "2" && <TeethSummaryBox label="permanent" studyInfo={studyInfo} code="SI02" />}
        </SummaryTableLi>
      )}
      {/* 어태치먼트 부여를 원치 않는 치아 */}
      {summaryStepMap.has("SI03") && (
        <SummaryTableLi title={t(IK.attachment)} handleClickForUpdate={handleClickForUpdate} dataId="SI03">
          {studyInfo.SI03 === "1" && <p>{t(IK.attachment_item1)}</p>}
          {studyInfo.SI03 === "2" && <TeethSummaryBox label="permanent" studyInfo={studyInfo} code="SI03" />}
        </SummaryTableLi>
      )}

      {/* 어태치먼트 AP*/}
      {summaryStepMap.has("SI16") && (
        <SummaryTableLi title={t(IK.ap_attachment)} handleClickForUpdate={handleClickForUpdate} dataId="SI16">
          <p>{getSummaryLabel(studyInfo, "SI16", t)}</p>
        </SummaryTableLi>
      )}

      {/* 악궁 확장 */}
      {summaryStepMap.has("SI04") && (
        <SummaryTableLi title={t(IK.dental_arch_extension)} handleClickForUpdate={handleClickForUpdate} dataId="SI04">
          <div className="frm_column">
            {studyInfo.SI04_01 && (
              <p>
                {t(IK.maxilla)} : {getSummaryLabel(studyInfo, "SI04_01", t)}
                {studyInfo?.SI04_01 === "1" && `, ${t(IK.dental_arch_extension_item)} ${studyInfo?.SI04_01_01} mm`}
              </p>
            )}
            {studyInfo.SI04_02 && (
              <p>
                {t(IK.mandible)} : {getSummaryLabel(studyInfo, "SI04_02", t)}
                {studyInfo?.SI04_02 === "1" && `, ${t(IK.dental_arch_extension_item)} ${studyInfo?.SI04_02_01} mm`}
              </p>
            )}
          </div>
        </SummaryTableLi>
      )}
      {/* 전후방관계(A-P) */}
      {summaryStepMap.has("SI05") && (
        <SummaryTableLi title={t(IK.ap_relation)} handleClickForUpdate={handleClickForUpdate} dataId="SI05">
          <div className="frm_column">
            {studyInfo?.SI05_01 && (
              <p>
                {`${t(IK.ap_item1_title)} : ${getSummaryLabel(studyInfo, "SI05_01", t)}`}
                {studyInfo["SI05_01"] === "2" && `(${studyInfo["SI05_01_2_01"]}mm)`}
                {studyInfo["SI05_01"] === "3" && `(${studyInfo["SI05_01_3_01"]}mm)`}
              </p>
            )}
            {studyInfo["SI05_05"] === "1" ? (
              <p>{t(IK.ap_item2_title)}</p>
            ) : (
              <div>
                {studyInfo["SI05_02"] && <p>{getSummaryLabel(studyInfo, "SI05_02", t)}</p>}
                {studyInfo["SI05_03"] && <p>{getSummaryLabel(studyInfo, "SI05_03", t)}</p>}
              </div>
            )}
            {studyInfo["SI05_04"] && (
              <div>
                <p>
                  {t(IK.teeth_move_option)} : {getSummaryLabel(studyInfo, "SI05_04", t)}
                </p>
              </div>
            )}
          </div>
        </SummaryTableLi>
      )}
      {/* 수평피개(Overjet) */}
      {summaryStepMap.has("SI06") && (
        <SummaryTableLi title={t(IK.overjet)} handleClickForUpdate={handleClickForUpdate} dataId="SI06">
          <p>
            {getSummaryLabel(studyInfo, "SI06", t)}
            {studyInfo.SI06 === "3" && `(${studyInfo.SI06_3_01}mm)`}
          </p>
        </SummaryTableLi>
      )}
      {/* 수직피개(Overbite) */}
      {summaryStepMap.has("SI07") && (
        <SummaryTableLi title={t(IK.deepbite_title)} handleClickForUpdate={handleClickForUpdate} dataId="SI07">
          <div className="frm_column">
            {studyInfo?.SI07_01 && (
              <p>
                {t(IK.deepbite_item1)} : {getSummaryLabel(studyInfo, "SI07_01", t)}
                {studyInfo.SI07_01 === "2" && `(${studyInfo.SI07_01_2_01}mm)`}
                {studyInfo.SI07_01 === "3" && `(${studyInfo.SI07_01_3_01}mm)`}
              </p>
            )}
            {studyInfo.SI07_02 && (
              <p>
                {t(IK.overbite_item)} : {getSummaryLabel(studyInfo, "SI07_02", t)}
                {studyInfo.SI07_02 === "3" && `(${studyInfo.SI07_02_3_01}mm)`}
              </p>
            )}
            {studyInfo.SI07_03 && (
              <p>
                {t(IK.deepbite_item3)}
                {studyInfo["SI07_03"] < 5 ? (
                  <>
                    {`: ${getSummaryLabel(studyInfo, "SI07_03", t)}`}
                    {studyInfo[`SI07_03_${studyInfo.SI07_03}_01`].includes("1") && `(${t(IK.maxilla)})`}
                    {studyInfo[`SI07_03_${studyInfo.SI07_03}_01`].includes("2") && `(${t(IK.mandible)})`}
                  </>
                ) : studyInfo?.SI07_03_5_01 ? (
                  `: ${studyInfo.SI07_03_5_01}(${getSummaryLabel(studyInfo, "SI07_03", t)})`
                ) : (
                  ""
                )}
              </p>
            )}
            {studyInfo.SI07_04 && (
              <p>
                {t(IK.deepbite_item4)}
                {studyInfo["SI07_04"] < 5 ? (
                  <>
                    {`: ${getSummaryLabel(studyInfo, "SI07_04", t)}`}
                    {studyInfo[`SI07_04_${studyInfo.SI07_04}_01`].includes("1") && `(${t(IK.maxilla)})`}
                    {studyInfo[`SI07_04_${studyInfo.SI07_04}_01`].includes("2") && `(${t(IK.mandible)})`}
                  </>
                ) : studyInfo?.SI07_04_5_01 ? (
                  `: ${studyInfo.SI07_04_5_01}(${getSummaryLabel(studyInfo, "SI07_04", t)})`
                ) : (
                  ""
                )}
              </p>
            )}
          </div>
        </SummaryTableLi>
      )}
      {/* 교합이개장치(Bite Ramps) */}
      {/**교합이개를 처방전 작성시에 표출안하면 요약상세에도 없도록 분기 */}
      {summaryStepMap.has("SI10") && studyInfo?.SI10 && (
        <SummaryTableLi title={t(IK.biteramp)} handleClickForUpdate={handleClickForUpdate} dataId="SI10">
          <p>
            {getSummaryLabel(studyInfo, "SI10", t)}
            {studyInfo["SI10"] === "2" && `(${getSummaryLabel(studyInfo, "SI10_2_01", t)}${studyInfo["SI10_2_01"] === "1" ? " : " + getSummaryLabel(studyInfo, "SI10_2_01_1_01", t) : ""})`}
          </p>
        </SummaryTableLi>
      )}
      {/* 정중선(Midline) */}
      {summaryStepMap.has("SI08") && (
        <SummaryTableLi title={t(IK.midline)} handleClickForUpdate={handleClickForUpdate} dataId="SI08">
          <div className="frm_column">
            <p>
              {getSummaryLabel(studyInfo, "SI08", t)} {studyInfo.SI08_3_02 ? `: ${getSummaryLabel(studyInfo, "SI08_3_02", t)}` : ""}
            </p>
            {studyInfo["SI08"] === "3" && studyInfo["SI08_3_02"] === "3" && (
              <>
                <p> {studyInfo["SI08_3_01"].split(",").includes("1") && `- ${t(IK.maxilla)} ${t(IK.move_right)}(${studyInfo["SI08_3_01_1_01"]}mm)`}</p>
                <p>{studyInfo["SI08_3_01"].split(",").includes("2") && `- ${t(IK.maxilla)} ${t(IK.move_left)}(${studyInfo["SI08_3_01_2_01"]}mm)`}</p>
                <p> {studyInfo["SI08_3_01"].split(",").includes("3") && `- ${t(IK.mandible)} ${t(IK.move_right)}(${studyInfo["SI08_3_01_3_01"]}mm)`}</p>
                <p>{studyInfo["SI08_3_01"].split(",").includes("4") && `- ${t(IK.mandible)} ${t(IK.move_left)}(${studyInfo["SI08_3_01_4_01"]}mm)`}</p>
              </>
            )}
          </div>
        </SummaryTableLi>
      )}
      {/* Spacing */}
      {summaryStepMap.has("SI09_01") && (
        <SummaryTableLi title="Spacing" handleClickForUpdate={handleClickForUpdate} dataId="SI09_01">
          <p>{getSummaryLabel(studyInfo, "SI09_01", t)}</p>
        </SummaryTableLi>
      )}
      {/* Crowding */}
      {summaryStepMap.has("SI09_02") && (
        <SummaryTableLi title="Crowding" handleClickForUpdate={handleClickForUpdate} dataId="SI09_02">
          <div className="card_flex">
            {(studyInfo?.SI01 === "1" || studyInfo?.SI01 === "2") && (
              <div className="col_wd_pre frm_column">
                <FormControlsBox title={`${t(IK.maxilla)} ${t(IK.crowding_item1_title)}`}>
                  <div className="chk_area">
                    <div className="frm_div">{getSummaryLabel(studyInfo, "SI09_02_01_01", t)}</div>
                  </div>
                </FormControlsBox>
                <FormControlsBox title={`${t(IK.maxilla)} IPR ${t(IK.crowding_item2_title)}`}>
                  <div className="chk_area">
                    <div className="frm_div">{getSummaryLabel(studyInfo, "SI09_02_01_02", t)}</div>
                  </div>
                </FormControlsBox>
                <FormControlsBox title={`${t(IK.maxilla)} IPR ${t(IK.crowding_item3_title)}`}>
                  <div className="chk_area">
                    <div className="frm_div">{getSummaryLabel(studyInfo, "SI09_02_01_03", t)}</div>
                  </div>
                </FormControlsBox>
                <FormControlsBox title={`${t(IK.maxilla)} IPR ${t(IK.crowding_item4_title)}`}>
                  <div className="chk_area">
                    <div className="frm_div">{getSummaryLabel(studyInfo, "SI09_02_01_04", t)}</div>
                  </div>
                </FormControlsBox>
              </div>
            )}
            {(studyInfo?.SI01 === "1" || studyInfo?.SI01 === "3") && (
              <div className="col_wd_pre frm_column">
                <FormControlsBox title={`${t(IK.mandible)} ${t(IK.crowding_item5_title)}`}>
                  <div className="chk_area">
                    <div className="frm_div">{getSummaryLabel(studyInfo, "SI09_02_02_01", t)}</div>
                  </div>
                </FormControlsBox>
                <FormControlsBox title={`${t(IK.mandible)} IPR ${t(IK.crowding_item6_title)}`}>
                  <div className="chk_area">
                    <div className="frm_div">{getSummaryLabel(studyInfo, "SI09_02_02_02", t)}</div>
                  </div>
                </FormControlsBox>
                <FormControlsBox title={`${t(IK.mandible)} IPR ${t(IK.crowding_item7_title)}`}>
                  <div className="chk_area">
                    <div className="frm_div">{getSummaryLabel(studyInfo, "SI09_02_02_03", t)}</div>
                  </div>
                </FormControlsBox>
                <FormControlsBox title={`${t(IK.mandible)} IPR ${t(IK.crowding_item8_title)}`}>
                  <div className="chk_area">
                    <div className="frm_div">{getSummaryLabel(studyInfo, "SI09_02_02_04", t)}</div>
                  </div>
                </FormControlsBox>
              </div>
            )}
          </div>
        </SummaryTableLi>
      )}
      {/* 구치부 반대교합(Crossbite) */}
      {summaryStepMap.has("SI11") && (
        <SummaryTableLi title={t(IK.posterior_crossbite)} handleClickForUpdate={handleClickForUpdate} dataId="SI11">
          <p>{getSummaryLabel(studyInfo, "SI11", t)}</p>
        </SummaryTableLi>
      )}
      {/* 영구치예측 */}
      {summaryStepMap.has("SI14") && (
        <SummaryTableLi title={t(IK.prmanent_tooth)} handleClickForUpdate={handleClickForUpdate} dataId="SI14">
          {studyInfo["SI14_01"] === "2" ? (
            <div className="frm_column">
              <TeethSummaryBox label="permanent_predict" studyInfo={studyInfo} config={PERMANENT_TOOTH_ARRAY} code="SI14_01" />
              <p>
                {t(IK.prmanent_tooth_item2_title)}
                {!!studyInfo["SI14_03"] ? `: ${studyInfo["SI14_03"] + t(IK.level)}` : `: ${t(IK.not_applicable)}`}
              </p>
            </div>
          ) : (
            <p>{t(IK.not_applicable)}</p>
          )}
        </SummaryTableLi>
      )}
      {/* 특별지시사항 */}
      {summaryStepMap.has("SI13") && (
        <SummaryTableLi title={t(IK.special_instructions)} handleClickForUpdate={handleClickForUpdate} dataId="SI13">
          <S3.TextDiv $textpreline>{studyInfo.SI13}</S3.TextDiv>
        </SummaryTableLi>
      )}

      {/* 장치배송의 기본 단위 */}
      {summaryStepMap.has("SI17") && (
        <SummaryTableLi title={t(IK.device_delivery_unit)} handleClickForUpdate={handleClickForUpdate} dataId="SI17">
          <p>{getSummaryLabel(studyInfo, "SI17", t)}</p>
        </SummaryTableLi>
      )}

      {/* Every10 (치아이동촉진기) */}
      {summaryStepMap.has("SI15") && (
        <SummaryTableLi title={t(IK.every10)} handleClickForUpdate={handleClickForUpdate} dataId="SI15">
          <p>{getSummaryLabel(studyInfo, "SI15", t)}</p>
        </SummaryTableLi>
      )}
    </S.SummaryList>
  );
};
