import Confirm from "../pages/Patients/Study/Sections/Confirm/Confirm";
import Extraoral from "../pages/Patients/Study/Sections/Extraoral/Extraoral";
import Intraoral from "../pages/Patients/Study/Sections/Intraoral/Intraoral";
import AP from "../pages/Patients/Study/Sections/Item/AP";
import ApAttachment from "../pages/Patients/Study/Sections/Item/ApAttachment";
import Attachment from "../pages/Patients/Study/Sections/Item/Attachment";
import BiteRamp from "../pages/Patients/Study/Sections/Item/BiteRamp";
import Crossbite from "../pages/Patients/Study/Sections/Item/Crossbite";
import Crowding from "../pages/Patients/Study/Sections/Item/Crowding";
import Deepbite from "../pages/Patients/Study/Sections/Item/Deepbite";
import DentalArchExtension from "../pages/Patients/Study/Sections/Item/DentalArchExtension";
import DentalArchTreated from "../pages/Patients/Study/Sections/Item/DentalArchTreated";
import DeviceDeliveryUnit from "../pages/Patients/Study/Sections/Item/DeviceDeliveryUnit";
import Every10 from "../pages/Patients/Study/Sections/Item/Every10";
import Extraction from "../pages/Patients/Study/Sections/Item/Extraction";
import Instructions from "../pages/Patients/Study/Sections/Item/Instructions";
import Midline from "../pages/Patients/Study/Sections/Item/Midline";
import Overjet from "../pages/Patients/Study/Sections/Item/Overjet";
import PermanentTooth from "../pages/Patients/Study/Sections/Item/PermanentTooth";
import Spacing from "../pages/Patients/Study/Sections/Item/Spacing";
import TeethLimitedMobility from "../pages/Patients/Study/Sections/Item/TeethLimitedMobility";
import SelectAge from "../pages/Patients/Study/Sections/Option/SelectAge";
import SelectPackage from "../pages/Patients/Study/Sections/Option/SelectPackage";
import Radiation from "../pages/Patients/Study/Sections/Radiation/Radiation";
import Scan from "../pages/Patients/Study/Sections/Scan/Scan";
import Summary from "../pages/Patients/Study/Sections/Summary/Summary";
import teethLabelJson from "./TeethLabel.json";
import { IK } from "./i18n/keyStore";

/**처방전 스텝 라벨 */
export const STUDY_STEP_LABEL = {
  SELECT_AGE: <SelectAge />,
  SELECT_PACKAGE: <SelectPackage />,
  SI01: <DentalArchTreated />, //악궁선택
  SI12: <Extraction />, //발치할 치아
  SI02: <TeethLimitedMobility />, //이동에 제한있는 치아
  SI03: <Attachment />, //어태치먼트
  SI16: <ApAttachment />, //AP 어태치먼트
  SI04: <DentalArchExtension />, //악궁확장
  SI05: <AP />, //AP
  SI06: <Overjet />, //수평피개
  SI07: <Deepbite />, //수직피개
  SI08: <Midline />, //정중선
  SI09_01: <Spacing />,
  SI09_02: <Crowding />,
  SI10: <BiteRamp />, //교합이개장치
  SI11: <Crossbite />, //반대교합(구치부)
  SI14: <PermanentTooth />, //영구치아
  SI13: <Instructions />, //특별지시사항
  SI17: <DeviceDeliveryUnit />, //장치배송의 기본 단위
  SI15: <Every10 />,
  EXTRAORAL: <Extraoral />,
  INTRAORAL: <Intraoral />,
  RADIOGRAPH: <Radiation />,
  SCAN_FILE: <Scan />,
  SUMMARY: <Summary />,
  CONFIRM: <Confirm />,
};

/**처방전 항목 설정
 * 패키지별로 커스텀이 필요한 경우 추가
 */
export const STUDY_FORM_CONFIG = {
  R: {
    SI01: {
      SI01_1: true,
      SI01_2: true,
      SI01_3: true,
      SI01_2_1: true,
      SI01_2_2: true,
      SI01_3_1: true,
      SI01_3_2: true,
    },
    EXTRAORAL: {
      extraoral_front: false,
      front_rest: false,
      front_smile: false,
      side45: false,
      side90: false,
      side90_smile: false,
    },
    INTRAORAL: {
      occlusal_upper: false,
      intraoral_front: false,
      occlusal_lower: false,
      buccal_right: false,
      overjet: false,
      buccal_left: false,
    },
    RADIOGRAPH: {
      panorama: false,
      cephalo: false,
    },
  },
  RP: {
    SI01: {
      SI01_1: true,
      SI01_2: true,
      SI01_3: true,
      SI01_2_1: false,
      SI01_2_2: false,
      SI01_3_1: false,
      SI01_3_2: false,
    },
    EXTRAORAL: {
      extraoral_front: false,
      front_rest: false,
      front_smile: false,
      side45: false,
      side90: false,
      side90_smile: false,
    },
    INTRAORAL: {
      occlusal_upper: false,
      intraoral_front: false,
      occlusal_lower: false,
      buccal_right: false,
      overjet: false,
      buccal_left: false,
    },
    RADIOGRAPH: {
      panorama: false,
      cephalo: false,
    },
  },
  S10: {
    SI01: {
      SI01_1: true,
      SI01_2: true,
      SI01_3: true,
      SI01_2_1: true,
      SI01_2_2: true,
      SI01_3_1: true,
      SI01_3_2: true,
    },
    EXTRAORAL: {
      extraoral_front: false,
      front_rest: false,
      front_smile: false,
      side45: false,
      side90: false,
      side90_smile: false,
    },
    INTRAORAL: {
      occlusal_upper: true,
      intraoral_front: false,
      occlusal_lower: true,
      buccal_right: true,
      overjet: true,
      buccal_left: true,
    },
    RADIOGRAPH: {
      panorama: true,
      cephalo: false,
    },
  },
  S20: {
    SI01: {
      SI01_1: true,
      SI01_2: true,
      SI01_3: true,
      SI01_2_1: true,
      SI01_2_2: true,
      SI01_3_1: true,
      SI01_3_2: true,
    },
    EXTRAORAL: {
      extraoral_front: false,
      front_rest: false,
      front_smile: false,
      side45: false,
      side90: false,
      side90_smile: false,
    },
    INTRAORAL: {
      occlusal_upper: true,
      intraoral_front: false,
      occlusal_lower: true,
      buccal_right: true,
      overjet: true,
      buccal_left: true,
    },
    RADIOGRAPH: {
      panorama: true,
      cephalo: false,
    },
  },
  SAP: {
    SI01: {
      SI01_1: true,
      SI01_2: false,
      SI01_3: false,
      SI01_2_1: false,
      SI01_2_2: false,
      SI01_3_1: false,
      SI01_3_2: false,
    },
    EXTRAORAL: {
      extraoral_front: true,
      front_rest: false,
      front_smile: true,
      side45: false,
      side90: true,
      side90_smile: false,
    },
    INTRAORAL: {
      occlusal_upper: true,
      intraoral_front: false,
      occlusal_lower: true,
      buccal_right: true,
      overjet: true,
      buccal_left: true,
    },
    RADIOGRAPH: {
      panorama: true,
      cephalo: false,
    },
  },
  Si: {
    SI01: {
      SI01_1: true,
      SI01_2: true,
      SI01_3: true,
      SI01_2_1: true,
      SI01_2_2: true,
      SI01_3_1: true,
      SI01_3_2: true,
    },
    EXTRAORAL: {
      extraoral_front: false,
      front_rest: false,
      front_smile: false,
      side45: false,
      side90: false,
      side90_smile: false,
    },
    INTRAORAL: {
      occlusal_upper: true,
      intraoral_front: false,
      occlusal_lower: true,
      buccal_right: true,
      overjet: true,
      buccal_left: true,
    },
    RADIOGRAPH: {
      panorama: true,
      cephalo: false,
    },
  },
  SiAP: {
    SI01: {
      SI01_1: true,
      SI01_2: false,
      SI01_3: false,
      SI01_2_1: false,
      SI01_2_2: false,
      SI01_3_1: false,
      SI01_3_2: false,
    },
    EXTRAORAL: {
      extraoral_front: false,
      front_rest: false,
      front_smile: false,
      side45: false,
      side90: false,
      side90_smile: false,
    },
    INTRAORAL: {
      occlusal_upper: true,
      intraoral_front: false,
      occlusal_lower: true,
      buccal_right: true,
      overjet: true,
      buccal_left: true,
    },
    RADIOGRAPH: {
      panorama: true,
      cephalo: false,
    },
  },
  SR: {
    SI01: {
      SI01_1: true,
      SI01_2: false,
      SI01_3: false,
      SI01_2_1: false,
      SI01_2_2: false,
      SI01_3_1: false,
      SI01_3_2: false,
    },
    EXTRAORAL: {
      extraoral_front: true,
      front_rest: false,
      front_smile: true,
      side45: false,
      side90: true,
      side90_smile: false,
    },
    INTRAORAL: {
      occlusal_upper: true,
      intraoral_front: false,
      occlusal_lower: true,
      buccal_right: true,
      overjet: true,
      buccal_left: true,
    },
    RADIOGRAPH: {
      panorama: true,
      cephalo: false,
    },
  },
};

/**세라핀 옵션명 통일 */
export const SERAPIN_OPTION = {
  R: "R-active",
  RP: "R-passive",
  S10: "10",
  S20: "20",
  SR: "Regular",
  SAP: "Regular AP",
  Si: "i",
  SiAP: "iAP",
};

/**처방전 초기 스텝 */
export const SETTING_STUDY_STEP = ["SELECT_AGE", "SELECT_PACKAGE"];

/**처방전 패키지 스텝 */
export const STUDY_PACKAGES_STEP = {
  R: ["SI01", "SI02", "SI03", "SI10", "SI08", "SI09_01", "SI09_02", "SI13"],
  RP: ["SI01", "SI10", "SI13"],
  S10: ["SI01", "SI12", "SI02", "SI03", "SI04", "SI10", "SI08", "SI09_01", "SI09_02", "SI14", "SI13", "SI15"],
  S20: ["SI01", "SI12", "SI02", "SI03", "SI04", "SI05", "SI06", "SI07", "SI10", "SI08", "SI09_01", "SI09_02", "SI14", "SI13", "SI15"],
  SR: ["SI01", "SI12", "SI02", "SI03", "SI04", "SI05", "SI06", "SI07", "SI10", "SI08", "SI09_01", "SI09_02", "SI11", "SI14", "SI13", "SI17", "SI15"],
  SAP: ["SI01", "SI12", "SI02", "SI03", "SI04", "SI05", "SI06", "SI07", "SI10", "SI08", "SI09_01", "SI09_02", "SI11", "SI14", "SI13", "SI17", "SI15"],
  Si: ["SI01", "SI12", "SI02", "SI03", "SI04", "SI13"],
  SiAP: ["SI01", "SI12", "SI02", "SI03", "SI16", "SI04", "SI13"],
};

/**처방전 파일 스텝 + 요약, 확인 */
export const STUDY_FILE_STEP = ["EXTRAORAL", "INTRAORAL", "RADIOGRAPH", "SCAN_FILE", "SUMMARY", "CONFIRM"];

/**처방전 패키지 기준으로 스텝을 선택
 * @param {string} packages - 패키지 코드
 * @param {string} countryCode - 국가 코드
 * @returns {array} 선택된 스텝
 */
export const selectPackageStep = (packages, countryCode, SI01) => {
  const packageStep = STUDY_PACKAGES_STEP[packages];
  const step = [...SETTING_STUDY_STEP, ...packageStep, ...STUDY_FILE_STEP];

  const skipCountryItems = ["SI15", "SI17"];

  if (countryCode !== "01") {
    //해외 처방전은 애브리텐(SI15), 장치배송의 기본 단위(SI17) 제거
    return step.filter((e) => !skipCountryItems.includes(e));
  }

  if (SI01) {
    //악궁값 받은경우 교합이개 필터
    return removeBiteRamp(step, SI01);
  }

  return step;
};

/**처방전 SI01에서 하악선택시 BiteRamp(SI10) 제거 */
export const removeBiteRamp = (step, SI01) => {
  if (SI01 === "3") {
    return step.filter((e) => e !== "SI10");
  }
  return step;
};

/**치아 선택 배열
 * 1부터 32까지 치아 번호
 */
export const TEETH_SELECT_ARRAY = {
  1: true,
  2: true,
  3: true,
  4: true,
  5: true,
  6: true,
  7: true,
  8: true,
  9: true,
  10: true,
  11: true,
  12: true,
  13: true,
  14: true,
  15: true,
  16: true,
  17: true,
  18: true,
  19: true,
  20: true,
  21: true,
  22: true,
  23: true,
  24: true,
  25: true,
  26: true,
  27: true,
  28: true,
  29: true,
  30: true,
  31: true,
  32: true,
};

/**영구치 치아박스 */
export const PERMANENT_TOOTH_ARRAY = {
  4: true,
  5: true,
  6: true,
  11: true,
  12: true,
  13: true,
  29: true,
  28: true,
  27: true,
  22: true,
  21: true,
  20: true,
};

/**처방전 요약 라벨 */
export function getSummaryLabel(studyInfo, key, t) {
  const valueLabel = {
    SI01: {
      1: t(IK.bimaxillay),
      2: t(IK.maxilla),
      3: t(IK.mandible),
    },
    SI01_2_01: {
      1: t(IK.dental_arch_treated_item2_1),
      2: t(IK.dental_arch_treated_item2_2),
      3: t(IK.dental_arch_treated_item2_3),
    },
    SI01_3_01: {
      1: t(IK.dental_arch_treated_item3_1),
      2: t(IK.dental_arch_treated_item3_2),
      3: t(IK.dental_arch_treated_item3_3),
    },
    SI02: {
      1: t(IK.teeth_limited_mobility_item1),
      2: t(IK.teeth_limited_mobility_item2),
    },
    SI03: {
      1: t(IK.attachment_item1),
      2: t(IK.attachment_item2),
    },
    SI04_01: {
      1: t(IK.riority_implementation),
      2: t(IK.implementation_necessary),
      3: t(IK.not_necessary),
    },
    SI04_02: {
      1: t(IK.riority_implementation),
      2: t(IK.implementation_necessary),
      3: t(IK.not_necessary),
    },
    SI05_01: {
      1: t(IK.ap_item1_1),
      2: t(IK.ap_item1_2),
      3: t(IK.ap_item1_3),
    },
    SI05_02: {
      1: `${t(IK.ap_right)} : ${t(IK.ap_item3_title)}`,
      2: `${t(IK.ap_right)} : ${t(IK.ap_item4_title)}`,
    },
    SI05_03: {
      1: `${t(IK.ap_left)} : ${t(IK.ap_item3_title)}`,
      2: `${t(IK.ap_left)} : ${t(IK.ap_item4_title)}`,
    },
    SI05_04: {
      1: t(IK.ap_item5_1),
      2: t(IK.ap_item5_2),
      3: t(IK.ap_item5_3),
      4: t(IK.ap_item5_4),
      5: t(IK.ap_item5_5),
    },
    SI06: {
      1: t(IK.overjet_item1),
      2: t(IK.deepbite_item2_2),
      3: t(IK.overjet_item3),
    },
    SI07_01: {
      1: t(IK.deepbite_item1_1),
      2: t(IK.deepbite_item1_2),
      3: t(IK.deepbite_item1_3),
    },
    SI07_02: {
      1: t(IK.deepbite_item2_1),
      2: t(IK.deepbite_item2_2),
      3: t(IK.deepbite_item2_3),
    },
    SI07_03: {
      1: t(IK.deepbite_item3_1),
      2: t(IK.deepbite_item3_2),
      3: t(IK.deepbite_item3_3),
      4: t(IK.deepbite_item3_4),
      5: t(IK.other),
      6: "",
    },
    SI07_04: {
      1: t(IK.deepbite_item4_1),
      2: t(IK.deepbite_item4_2),
      3: t(IK.deepbite_item4_3),
      4: t(IK.deepbite_item4_4),
      5: t(IK.other),
      6: "",
    },
    SI08: {
      1: t(IK.midline_item1),
      2: t(IK.midline_item2),
      3: t(IK.midline_item3),
      4: t(IK.midline_item4),
    },
    SI08_3_01: {
      1: t(IK.midline_item1),
      2: t(IK.midline_item2),
      3: t(IK.midline_item3),
      4: t(IK.midline_item4),
    },
    SI08_3_02: {
      1: t(IK.midline_item3_1),
      2: t(IK.midline_item3_2),
      3: t(IK.midline_item3_3),
    },
    SI09_01: {
      1: t(IK.spacing_item1),
      2: t(IK.spacing_item2),
    },
    SI09_02_01_01: {
      1: t(IK.increase_incline),
      2: t(IK.maintain_incline),
      3: t(IK.decrease_incline),
    },
    SI09_02_01_02: {
      1: t(IK.riority_implementation),
      2: t(IK.implementation_necessary),
      3: t(IK.not_necessary),
    },
    SI09_02_01_03: {
      1: t(IK.riority_implementation),
      2: t(IK.implementation_necessary),
      3: t(IK.not_necessary),
    },
    SI09_02_01_04: {
      1: t(IK.riority_implementation),
      2: t(IK.implementation_necessary),
      3: t(IK.not_necessary),
    },
    SI09_02_02_01: {
      1: t(IK.increase_incline),
      2: t(IK.maintain_incline),
      3: t(IK.decrease_incline),
    },
    SI09_02_02_02: {
      1: t(IK.riority_implementation),
      2: t(IK.implementation_necessary),
      3: t(IK.not_necessary),
    },
    SI09_02_02_03: {
      1: t(IK.riority_implementation),
      2: t(IK.implementation_necessary),
      3: t(IK.not_necessary),
    },
    SI09_02_02_04: {
      1: t(IK.riority_implementation),
      2: t(IK.implementation_necessary),
      3: t(IK.not_necessary),
    },
    SI10: {
      1: t(IK.grant_if_necessary),
      2: t(IK.biteramp_item2),
      3: t(IK.not_used),
    },
    SI10_2_01: {
      1: t(IK.incisor),
      2: t(IK.canine_teeth),
    },
    SI10_2_01_1_01: {
      1: t(IK.central_incisor),
      2: t(IK.lateral_incisor),
    },
    SI11: {
      1: t(IK.crossbite_item1),
      2: t(IK.crossbite_item2),
    },
    SI12: {
      1: t(IK.extraction_item1),
      2: t(IK.have_teeth_to_be_extracted),
    },
    SI14: {
      1: t(IK.not_applicable),
    },
    SI14_01: {
      1: t(IK.not_used),
      2: t(IK.prmanent_tooth_item1_2),
    },
    SI14_02: {
      1: t(IK.not_used),
      2: t(IK.prmanent_tooth_item1_2),
    },
    SI15: {
      1: t(IK.yes),
      2: t(IK.no),
    },
    SI16: {
      1: t(IK.ap_attachment_text_1),
      2: t(IK.ap_attachment_text_2),
      3: t(IK.ap_attachment_text_3),
    },
    SI17: {
      1: t(IK.device_delivery_unit_item1_1),
      2: t(IK.device_delivery_unit_item1_2),
    },
    SIPlasterModel: {
      "Direct shipping": t(IK.address_delivery_check_title2),
      "Delivery pickup": t(IK.address_delivery_check_title1),
    },
  };

  const value = studyInfo[key] || [];
  if (value.includes(",")) {
    // 쉼표가 포함된 경우 여러 개의 레이블을 가져와 합쳐서 반환
    const splitArr = value.split(",");
    return splitArr.map((item) => valueLabel[key][item]).join(", ");
  }
  return valueLabel[key][value] || "";
}

/**선호치료 치아 라벨 */
export function getTeethLabel(data) {
  let splitArr = data ? data.split(",") : "";
  const result = [];
  for (let i = 0; i < splitArr.length; i++) {
    result.push(teethLabelJson[splitArr[i]]);
  }

  //오름차순 정렬
  return result.sort((a, b) => a - b).join(", ");
}

/**치아 키값 찾아내기 */
export function getTeethKeyFromLabel(label) {
  const keysOfTeeth = Object.keys(teethLabelJson);
  return keysOfTeeth.find((key) => teethLabelJson[key] === label);
}

/**악궁 표기 */
export const getArchLabel = (SI01, t) => {
  const ArchList = {
    1: t(IK.bimaxillay),
    2: t(IK.maxilla),
    3: t(IK.mandible),
  };

  return ArchList[SI01];
};

/**추가 처방전 석고모델 보내기 상세 표기 */
/**
 * plasterModelSend 함수는 주어진 텍스트와 키에 따라 모델 텍스트를 반환합니다.
 * @param {function} t - 다국어 번역 함수
 * @param {string} key - 석고모델 하위 옵션 키
 * @param {string} scanEmpty - 스캔이 비어있는지 여부를 나타내는 값(추가 처방전만 사용, AI40 === "X")
 * @returns {string} - 주어진 키에 해당하는 모델 텍스트
 */
export const plasterModelSend = (t, key) => {
  if (key === "None") {
    return t(IK.empty);
  }

  const modelText = {
    "Direct shipping": t(IK.address_delivery_check_title2),
    "Delivery pickup": t(IK.address_delivery_check_title1),
  };

  return `${t(IK.send_model)}${modelText[key] ? `: ${modelText[key]}` : ""}`;
};
