import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useSaveStep } from "../../../../../apis/studyApi";
import { CardTail, CardTitle } from "../../../../../components/Layout/CardLayout/CardLayout";
import * as S3 from "../../../../../components/Layout/CardLayout/style/CardLayout.style";
import Modal from "../../../../../components/Modal/Modal";
import * as S2 from "../../../../../components/Modal/style/Modal.style";
import * as S from "../../../../../components/element/Button/style/ButtonLayout.style";
import { FieldMessageErrorClick } from "../../../../../components/element/FieldMessage/FieldMessage";
import { FormControlsBox, FormControlsStudyBox } from "../../../../../components/element/Form/FormLayout";
import { IK } from "../../../../../utils/i18n/keyStore";
import { useStudyContext } from "../../../../Study/StudyProvider";

function Deepbite() {
  const { t } = useTranslation(["translation"]);

  const { studyInfo, setStudyInfo, next, prev, setCurrentStepIndex, isFinish, studyStep } = useStudyContext();
  const { mutate: saveStepMutation, isLoading } = useSaveStep();

  /**요약인지 다음인지 구분 */
  const [skip, setSkip] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const schema = {
    //overbite 교정 검사 필드 (하위항목, bite 교정항목)
    bite_3options: Yup.string().required(t(IK.deepbite_required2)),
    SI07_03: Yup.string().when("bite_3options", {
      is: (val) => val === "2" && studyInfo.SI01 === "1",
      then: (val) => Yup.string().required(t(IK.sub_option_required)),
    }),
    SI07_04: Yup.string().when("bite_3options", {
      is: (val) => val === "3" && studyInfo.SI01 === "1",
      then: (val) => Yup.string().required(t(IK.sub_option_required)),
    }),
    SI07_01_2_01: Yup.string().when("SI07_01", {
      is: "2",
      then: (val) => Yup.number().moreThan(0, t(IK.enter_figure)).required(t(IK.enter_figure)),
    }),
    SI07_01_3_01: Yup.string().when("SI07_01", {
      is: "3",
      then: (val) => Yup.number().moreThan(0, t(IK.enter_figure)).required(t(IK.enter_figure)),
    }),

    SI07_02_3_01: Yup.string().when(["bite_3options"], {
      is: (val, val2) => val === "6",
      then: (val, val2) => Yup.number().moreThan(0, t(IK.enter_figure)).required(t(IK.enter_figure)),
    }),

    SI07_03_1_01: Yup.array().when(["bite_3options", "SI07_03"], {
      is: (val, val2) => val === "2" && val2 === "1",
      then: (val, val2) => Yup.array().required(t(IK.sub_option_required)).min(1, t(IK.sub_option_required)),
    }),
    SI07_03_2_01: Yup.array().when(["bite_3options", "SI07_03"], {
      is: (val, val2) => val === "2" && val2 === "2",
      then: (val, val2) => Yup.array().required(t(IK.sub_option_required)).min(1, t(IK.sub_option_required)),
    }),
    SI07_03_3_01: Yup.array().when(["bite_3options", "SI07_03"], {
      is: (val, val2) => val === "2" && val2 === "3",
      then: (val, val2) => Yup.array().required(t(IK.sub_option_required)).min(1, t(IK.sub_option_required)),
    }),
    SI07_03_4_01: Yup.array().when(["bite_3options", "SI07_03"], {
      is: (val, val2) => val === "2" && val2 === "4",
      then: (val, val2) => Yup.array().required(t(IK.sub_option_required)).min(1, t(IK.sub_option_required)),
    }),
    SI07_03_5_01: Yup.string().when(["bite_3options", "SI07_03"], {
      is: (val, val2) => val === "2" && val2 === "5",
      then: (val, val2) => Yup.string().required(t(IK.enter_description)),
    }),
    SI07_04_1_01: Yup.array().when(["bite_3options", "SI07_04"], {
      is: (val, val2) => val === "3" && val2 === "1",
      then: (val, val2) => Yup.array().required(t(IK.sub_option_required)).min(1, t(IK.sub_option_required)),
    }),
    SI07_04_2_01: Yup.array().when(["bite_3options", "SI07_04"], {
      is: (val, val2) => val === "3" && val2 === "2",
      then: (val, val2) => Yup.array().required(t(IK.sub_option_required)).min(1, t(IK.sub_option_required)),
    }),
    SI07_04_3_01: Yup.array().when(["bite_3options", "SI07_04"], {
      is: (val, val2) => val === "3" && val2 === "3",
      then: (val, val2) => Yup.array().required(t(IK.sub_option_required)).min(1, t(IK.sub_option_required)),
    }),
    SI07_04_4_01: Yup.array().when(["bite_3options", "SI07_04"], {
      is: (val, val2) => val === "3" && val2 === "4",
      then: (val, val2) => Yup.array().required(t(IK.sub_option_required)).min(1, t(IK.sub_option_required)),
    }),
    SI07_04_5_01: Yup.string().when(["bite_3options", "SI07_04"], {
      is: (val, val2) => val === "3" && val2 === "5",
      then: (val, val2) => Yup.string().required(t(IK.enter_description)),
    }),
  };

  //상악이나 양악 선택한 경우 옵션 추가
  if (studyInfo.SI01 === "1" || studyInfo.SI01 === "2") {
    schema.SI07_01 = Yup.string().required(t(IK.deepbite_required1));
  }

  const validationSchema = Yup.object().shape(schema);

  const handleClick = (e, setFieldValue) => {
    const label = e.target.name;
    const value = e.target.value;

    setFieldValue(label, value);

    const clearFieldValues = (baseLabel) => {
      setFieldValue(baseLabel, "");
      for (let i = 1; i <= 5; i++) {
        setFieldValue(`${baseLabel}_${i}_01`, "");
      }
    };

    label === "SI07_03" ? clearFieldValues("SI07_04") : clearFieldValues("SI07_03");
  };

  const handleOnChangeSI07 = (e, setFieldValue) => {
    const index = e.currentTarget.value;
    if (index !== 2) {
      setFieldValue("SI07_01_2_01", "");
    }
    if (index !== 3) {
      setFieldValue("SI07_01_3_01", "");
    }
  };

  const handleOnChangeBiteOptions = (e, setFieldValue) => {
    const index = e.currentTarget.value;
    if (index !== 6) {
      setFieldValue("SI07_02_3_01", "");
    }
  };

  // bite_3options 1,2,3 => SI07_02 4,5,6
  const changeOverBite = {
    4: "1",
    5: "2",
    6: "3",
  };

  const handleSubmit = (values) => {
    const data = {
      SI07_01: values.SI07_01,

      //SI07_02 : 1,2,3 => 순서대로 bite_3options 4,5,6 다시 변경
      SI07_02: changeOverBite[values.bite_3options] || "",
      SI07_02_3_01: values.bite_3options === "6" ? values.SI07_02_3_01 : "",

      //양악 => 상위옵션 선택, 편악 => 하위옵션 선택 없음
      SI07_03: values.bite_3options === "2" ? (studyInfo.SI01 !== "1" ? "6" : values.SI07_03) : "",
      SI07_04: values.bite_3options === "3" ? (studyInfo.SI01 !== "1" ? "6" : values.SI07_04) : "",

      SI07_01_2_01: values.SI07_01 === "2" ? values.SI07_01_2_01 : "",
      SI07_01_3_01: values.SI07_01 === "3" ? values.SI07_01_3_01 : "",

      SI07_03_5_01: values.bite_3options === "2" && values.SI07_03 === "5" ? values.SI07_03_5_01 : "",
      SI07_04_5_01: values.bite_3options === "3" && values.SI07_04 === "5" ? values.SI07_04_5_01 : "",
    };

    const si07_03_fields = ["SI07_03_1_01", "SI07_03_2_01", "SI07_03_3_01", "SI07_03_4_01", "SI07_03_5_01"];
    const si07_04_fields = ["SI07_04_1_01", "SI07_04_2_01", "SI07_04_3_01", "SI07_04_4_01", "SI07_04_5_01"];

    for (let i = 1; i <= si07_03_fields.length; i++) {
      if (values.bite_3options === "2" && values.SI07_03 === `${i}`) {
        if (i === 5) {
          data[si07_03_fields[i - 1]] = values[si07_03_fields[i - 1]];
        } else {
          data[si07_03_fields[i - 1]] = values[si07_03_fields[i - 1]].join(",");
        }
      }
    }

    for (let i = 1; i <= si07_04_fields.length; i++) {
      if (values.bite_3options === "3" && values.SI07_04 === `${i}`) {
        if (i === 5) {
          data[si07_04_fields[i - 1]] = values[si07_04_fields[i - 1]];
        } else {
          data[si07_04_fields[i - 1]] = values[si07_04_fields[i - 1]].join(",");
        }
      }
    }

    saveStepMutation(
      {
        step: "SI07",
        data,
        params: {
          studyId: studyInfo.studyId,
          patientId: studyInfo.patientId,
          isSaveStep: !isFinish,
        },
      },
      {
        onSuccess: () => {
          setStudyInfo((prev) => ({ ...prev, ...data }));
          isFinish && skip ? setCurrentStepIndex(studyStep.length - 2) : next();
        },
      }
    );
  };

  return (
    <>
      <CardTitle title={studyInfo.patientName} subTitle={`${t(IK[studyInfo.packages])}`} required />

      <Formik
        initialValues={{
          SI07_01: studyInfo.SI07_01 || "",
          SI07_01_2_01: studyInfo.SI07_01 === "2" && studyInfo.SI07_01_2_01 ? studyInfo.SI07_01_2_01 : "",
          SI07_01_3_01: studyInfo.SI07_01 === "3" && studyInfo.SI07_01_3_01 ? studyInfo.SI07_01_3_01 : "",
          // SI07_02: studyInfo.SI07_02 || "",
          SI07_02_3_01: studyInfo.SI07_02_3_01 ? studyInfo.SI07_02_3_01 : "",
          SI07_03: studyInfo.SI07_03 || "",
          SI07_03_1_01: studyInfo.SI07_03_1_01 ? studyInfo.SI07_03_1_01.split(",") : [],
          SI07_03_2_01: studyInfo.SI07_03_2_01 ? studyInfo.SI07_03_2_01.split(",") : [],
          SI07_03_3_01: studyInfo.SI07_03_3_01 ? studyInfo.SI07_03_3_01.split(",") : [],
          SI07_03_4_01: studyInfo.SI07_03_4_01 ? studyInfo.SI07_03_4_01.split(",") : [],
          SI07_03_5_01: studyInfo.SI07_03_5_01 || "",
          SI07_04: studyInfo.SI07_04 || "",
          SI07_04_1_01: studyInfo.SI07_04_1_01 ? studyInfo.SI07_04_1_01.split(",") : [],
          SI07_04_2_01: studyInfo.SI07_04_2_01 ? studyInfo.SI07_04_2_01.split(",") : [],
          SI07_04_3_01: studyInfo.SI07_04_3_01 ? studyInfo.SI07_04_3_01.split(",") : [],
          SI07_04_4_01: studyInfo.SI07_04_4_01 ? studyInfo.SI07_04_4_01.split(",") : [],
          SI07_04_5_01: studyInfo.SI07_04_5_01 || "",
          bite_3options:
            (studyInfo.SI07_03 && "2") || (studyInfo.SI07_04 && "3") || (studyInfo?.SI07_02 === "1" && "4") || (studyInfo?.SI07_02 === "2" && "5") || (studyInfo?.SI07_02 === "3" && "6") || "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnMount={true}
      >
        {({ setFieldValue, values, errors, touched }) => (
          <Form>
            <FormControlsStudyBox title={t(IK.deepbite_title)} required openModal={openModal}>
              <div className="frm_column">
                {(studyInfo.SI01 === "1" || studyInfo.SI01 === "2") && (
                  <FormControlsBox medium title={t(IK.deepbite_item1)}>
                    <div className="chk_area">
                      <div className="radio">
                        <label className="label_radio">
                          <Field
                            type="radio"
                            name="SI07_01"
                            className="input_radio"
                            value="1"
                            onChange={(e) => {
                              handleOnChangeSI07(e, setFieldValue);
                              setFieldValue("SI07_01", e.currentTarget.value);
                            }}
                          />
                          <span>{t(IK.deepbite_item1_1)}</span>
                        </label>
                      </div>

                      <div className="frm_div">
                        <div className="frm_flex">
                          <div className="radio">
                            <label className="label_radio">
                              <Field
                                type="radio"
                                name="SI07_01"
                                className="input_radio"
                                value="2"
                                onChange={(e) => {
                                  handleOnChangeSI07(e, setFieldValue);
                                  setFieldValue("SI07_01", e.currentTarget.value);
                                }}
                              />
                              <span>{t(IK.deepbite_item1_2)}</span>
                            </label>
                          </div>
                          <div className={values.SI07_01 === "2" ? "frm_line_field" : "frm_line_field hide"}>
                            <Field name="SI07_01_2_01" type="number" />
                            mm
                          </div>
                        </div>
                        <FieldMessageErrorClick name="SI07_01_2_01" />
                      </div>

                      <div className="frm_div">
                        <div className="frm_flex">
                          <div className="radio">
                            <label className="label_radio">
                              <Field
                                type="radio"
                                name="SI07_01"
                                className="input_radio"
                                value="3"
                                onChange={(e) => {
                                  handleOnChangeSI07(e, setFieldValue);
                                  setFieldValue("SI07_01", e.currentTarget.value);
                                }}
                              />
                              <span>{t(IK.deepbite_item1_3)}</span>
                            </label>
                          </div>
                          <div className={values.SI07_01 === "3" ? "frm_line_field" : "frm_line_field hide"}>
                            <Field name="SI07_01_3_01" type="number" />
                            mm
                          </div>
                        </div>
                      </div>
                    </div>
                    <FieldMessageErrorClick name="SI07_01" />
                  </FormControlsBox>
                )}
                <FormControlsBox medium title={t(IK.overbite_item)}>
                  <div className="frm_column">
                    <div className="radio">
                      <label className="label_radio">
                        <Field
                          type="radio"
                          name="bite_3options"
                          className="input_radio"
                          value="4"
                          onChange={(e) => {
                            handleOnChangeBiteOptions(e, setFieldValue);
                            setFieldValue("bite_3options", e.currentTarget.value);
                          }}
                        />
                        <span>{t(IK.deepbite_item2_1)}</span>
                      </label>
                    </div>

                    <div className="radio">
                      <label className="label_radio">
                        <Field
                          type="radio"
                          name="bite_3options"
                          className="input_radio"
                          value="5"
                          onChange={(e) => {
                            handleOnChangeBiteOptions(e, setFieldValue);
                            setFieldValue("bite_3options", e.currentTarget.value);
                          }}
                        />
                        <span>{t(IK.deepbite_item2_2)}</span>
                      </label>
                    </div>
                    <div className="frm_div">
                      <div className="frm_flex">
                        <div className="radio">
                          <label className="label_radio">
                            <Field
                              type="radio"
                              name="bite_3options"
                              className="input_radio"
                              value="6"
                              onChange={(e) => {
                                handleOnChangeBiteOptions(e, setFieldValue);
                                setFieldValue("bite_3options", e.currentTarget.value);
                              }}
                            />
                            <span>{t(IK.deepbite_item2_3)}</span>
                          </label>
                        </div>
                        <div className={values.bite_3options === "6" ? "frm_line_field" : "frm_line_field hide"}>
                          <Field name="SI07_02_3_01" type="number" />
                          mm ({t(IK.necessary)} IPR {t(IK.implementation)})
                        </div>
                      </div>
                      <FieldMessageErrorClick name="SI07_02_3_01" />
                    </div>
                    <div className="radio">
                      <label className="label_radio">
                        <Field
                          type="radio"
                          className="input_radio"
                          value="2"
                          name="bite_3options"
                          onChange={(e) => {
                            handleOnChangeBiteOptions(e, setFieldValue);
                            setFieldValue("bite_3options", e.currentTarget.value);
                          }}
                        />
                        <span>{t(IK.deepbite_item3)}</span>
                      </label>
                    </div>
                    <div className={values.bite_3options === "2" && studyInfo.SI01 === "1" ? "frm_radio_indent" : "frm_radio_indent hide"}>
                      <div className="chk_area">
                        <div className="frm_column">
                          <div className="radio">
                            <label className="label_radio">
                              <Field
                                type="radio"
                                name="SI07_03"
                                onClick={(e) => {
                                  handleClick(e, setFieldValue);
                                }}
                                className="input_radio"
                                value="1"
                              />
                              <span>{t(IK.deepbite_item3_1)}</span>
                            </label>
                          </div>
                          <div id="sub_3_1" className={values.SI07_03 === "1" ? "frm_radio_indent" : "frm_radio_indent hide"}>
                            <div className="chk_area">
                              <div className="checkbox">
                                <label className="label_checkbox">
                                  <Field type="checkbox" name="SI07_03_1_01" className="input_checkbox" value="1" checked={values.SI07_03_1_01.includes("1")} />
                                  <span>{t(IK.maxilla)}</span>
                                </label>
                              </div>
                              <div className="checkbox">
                                <label className="label_checkbox">
                                  <Field type="checkbox" name="SI07_03_1_01" className="input_checkbox" value="2" checked={values.SI07_03_1_01.includes("2")} />
                                  <span>{t(IK.mandible)}</span>
                                </label>
                              </div>
                            </div>
                            <FieldMessageErrorClick name="SI07_03_1_01" />
                          </div>
                        </div>

                        <div className="frm_column">
                          <div className="radio">
                            <label className="label_radio">
                              <Field
                                type="radio"
                                name="SI07_03"
                                onClick={(e) => {
                                  handleClick(e, setFieldValue);
                                }}
                                className="input_radio"
                                value="2"
                              />
                              <span>{t(IK.deepbite_item3_2)}</span>
                            </label>
                          </div>
                          <div className={values.SI07_03 === "2" ? "frm_radio_indent" : "frm_radio_indent hide"}>
                            <div className="chk_area">
                              <div className="checkbox">
                                <label className="label_checkbox">
                                  <Field type="checkbox" name="SI07_03_2_01" className="input_checkbox" value="1" checked={values.SI07_03_2_01.includes("1")} />
                                  <span>{t(IK.maxilla)}</span>
                                </label>
                              </div>
                              <div className="checkbox">
                                <label className="label_checkbox">
                                  <Field type="checkbox" name="SI07_03_2_01" className="input_checkbox" value="2" checked={values.SI07_03_2_01.includes("2")} />
                                  <span>{t(IK.mandible)}</span>
                                </label>
                              </div>
                            </div>
                            <FieldMessageErrorClick name="SI07_03_2_01" />
                          </div>
                        </div>

                        <div className="frm_column">
                          <div className="radio">
                            <label className="label_radio">
                              <Field
                                type="radio"
                                name="SI07_03"
                                onClick={(e) => {
                                  handleClick(e, setFieldValue);
                                }}
                                className="input_radio"
                                value="3"
                              />
                              <span>{t(IK.deepbite_item3_3)}</span>
                            </label>
                          </div>
                          <div id="sub_3_3" className={values.SI07_03 === "3" ? "frm_radio_indent" : "frm_radio_indent hide"}>
                            <div className="chk_area">
                              <div className="checkbox">
                                <label className="label_checkbox">
                                  <Field type="checkbox" name="SI07_03_3_01" className="input_checkbox" value="1" checked={values.SI07_03_3_01.includes("1")} />
                                  <span>{t(IK.maxilla)}</span>
                                </label>
                              </div>
                              <div className="checkbox">
                                <label className="label_checkbox">
                                  <Field type="checkbox" name="SI07_03_3_01" className="input_checkbox" value="2" checked={values.SI07_03_3_01.includes("2")} />
                                  <span>{t(IK.mandible)}</span>
                                </label>
                              </div>
                            </div>
                            <FieldMessageErrorClick name="SI07_03_3_01" />
                          </div>
                        </div>

                        <div className="frm_column">
                          <div className="radio">
                            <label className="label_radio">
                              <Field
                                type="radio"
                                name="SI07_03"
                                onClick={(e) => {
                                  handleClick(e, setFieldValue);
                                }}
                                className="input_radio"
                                value="4"
                              />
                              <span>{t(IK.deepbite_item3_4)}</span>
                            </label>
                          </div>
                          <div className={values.SI07_03 === "4" ? "frm_radio_indent" : "frm_radio_indent hide"}>
                            <div className="chk_area">
                              <div className="checkbox">
                                <label className="label_checkbox">
                                  <Field type="checkbox" name="SI07_03_4_01" className="input_checkbox" value="1" checked={values.SI07_03_4_01.includes("1")} />
                                  <span>{t(IK.maxilla)}</span>
                                </label>
                              </div>
                              <div className="checkbox">
                                <label className="label_checkbox">
                                  <Field type="checkbox" name="SI07_03_4_01" className="input_checkbox" value="2" checked={values.SI07_03_4_01.includes("2")} />
                                  <span>{t(IK.mandible)}</span>
                                </label>
                              </div>
                            </div>
                            <FieldMessageErrorClick name="SI07_03_4_01" />
                          </div>
                        </div>

                        <div className="frm_div">
                          <div className="frm_flex">
                            <div className="radio">
                              <label className="label_radio">
                                <Field
                                  type="radio"
                                  name="SI07_03"
                                  onClick={(e) => {
                                    handleClick(e, setFieldValue);
                                  }}
                                  className="input_radio"
                                  value="5"
                                />
                                <span>{t(IK.other)}</span>
                              </label>
                            </div>
                            <div className="frm_line_field">
                              <Field id="sub_3_etc" name="SI07_03_5_01" type="text" className={values.SI07_03 === "5" ? "" : "hide"} style={{ width: "120px" }} />
                            </div>
                          </div>
                          <FieldMessageErrorClick name="SI07_03_5_01" />
                        </div>
                      </div>
                      <FieldMessageErrorClick name="SI07_03" />
                    </div>

                    <div className="radio">
                      <label className="label_radio">
                        <Field
                          type="radio"
                          className="input_radio"
                          value="3"
                          name="bite_3options"
                          onChange={(e) => {
                            handleOnChangeBiteOptions(e, setFieldValue);
                            setFieldValue("bite_3options", e.currentTarget.value);
                          }}
                        />
                        <span>{t(IK.deepbite_item4)}</span>
                      </label>
                    </div>

                    <div className={values.bite_3options === "3" && studyInfo.SI01 === "1" ? "frm_radio_indent" : "frm_radio_indent hide"}>
                      <div className="chk_area">
                        <div className="frm_column">
                          <div className="radio">
                            <label className="label_radio">
                              <Field
                                type="radio"
                                name="SI07_04"
                                onClick={(e) => {
                                  handleClick(e, setFieldValue);
                                }}
                                className="input_radio"
                                value="1"
                              />
                              <span>{t(IK.deepbite_item4_1)}</span>
                            </label>
                          </div>
                          <div id="sub_4_1" className={values.SI07_04 === "1" ? "frm_radio_indent" : "frm_radio_indent hide"}>
                            <div className="chk_area">
                              <div className="checkbox">
                                <label className="label_checkbox">
                                  <Field type="checkbox" name="SI07_04_1_01" className="input_checkbox" value="1" checked={values.SI07_04_1_01.includes("1")} />
                                  <span>{t(IK.maxilla)}</span>
                                </label>
                              </div>
                              <div className="checkbox">
                                <label className="label_checkbox">
                                  <Field type="checkbox" name="SI07_04_1_01" className="input_checkbox" value="2" checked={values.SI07_04_1_01.includes("2")} />
                                  <span>{t(IK.mandible)}</span>
                                </label>
                              </div>
                            </div>
                            <FieldMessageErrorClick name="SI07_04_1_01" />
                          </div>
                        </div>

                        <div className="frm_column">
                          <div className="radio">
                            <label className="label_radio">
                              <Field
                                type="radio"
                                name="SI07_04"
                                onClick={(e) => {
                                  handleClick(e, setFieldValue);
                                }}
                                className="input_radio"
                                value="2"
                              />
                              <span>{t(IK.deepbite_item4_2)}</span>
                            </label>
                          </div>
                          <div id="sub_4_2" className={values.SI07_04 === "2" ? "frm_radio_indent" : "frm_radio_indent hide"}>
                            <div className="chk_area">
                              <div className="checkbox">
                                <label className="label_checkbox">
                                  <Field type="checkbox" name="SI07_04_2_01" className="input_checkbox" value="1" checked={values.SI07_04_2_01.includes("1")} />
                                  <span>{t(IK.maxilla)}</span>
                                </label>
                              </div>
                              <div className="checkbox">
                                <label className="label_checkbox">
                                  <Field type="checkbox" name="SI07_04_2_01" className="input_checkbox" value="2" checked={values.SI07_04_2_01.includes("2")} />
                                  <span>{t(IK.mandible)}</span>
                                </label>
                              </div>
                            </div>
                            <FieldMessageErrorClick name="SI07_04_2_01" />
                          </div>
                        </div>

                        <div className="frm_column">
                          <div className="radio">
                            <label className="label_radio">
                              <Field
                                type="radio"
                                name="SI07_04"
                                onClick={(e) => {
                                  handleClick(e, setFieldValue);
                                }}
                                className="input_radio"
                                value="3"
                              />
                              <span>{t(IK.deepbite_item4_3)}</span>
                            </label>
                          </div>
                          <div id="sub_4_3" className={values.SI07_04 === "3" ? "frm_radio_indent" : "frm_radio_indent hide"}>
                            <div className="chk_area">
                              <div className="checkbox">
                                <label className="label_checkbox">
                                  <Field type="checkbox" name="SI07_04_3_01" className="input_checkbox" value="1" checked={values.SI07_04_3_01.includes("1")} />
                                  <span>{t(IK.maxilla)}</span>
                                </label>
                              </div>
                              <div className="checkbox">
                                <label className="label_checkbox">
                                  <Field type="checkbox" name="SI07_04_3_01" className="input_checkbox" value="2" checked={values.SI07_04_3_01.includes("2")} />
                                  <span>{t(IK.mandible)}</span>
                                </label>
                              </div>
                            </div>
                            <FieldMessageErrorClick name="SI07_04_3_01" />
                          </div>
                        </div>

                        <div className="frm_column">
                          <div className="radio">
                            <label className="label_radio">
                              <Field
                                type="radio"
                                name="SI07_04"
                                onClick={(e) => {
                                  handleClick(e, setFieldValue);
                                }}
                                className="input_radio"
                                value="4"
                              />
                              <span>{t(IK.deepbite_item4_4)}</span>
                            </label>
                          </div>
                          <div id="sub_4_4" className={values.SI07_04 === "4" ? "frm_radio_indent" : "frm_radio_indent hide"}>
                            <div className="chk_area">
                              <div className="checkbox">
                                <label className="label_checkbox">
                                  <Field type="checkbox" name="SI07_04_4_01" className="input_checkbox" value="1" checked={values.SI07_04_4_01.includes("1")} />
                                  <span>{t(IK.maxilla)}</span>
                                </label>
                              </div>
                              <div className="checkbox">
                                <label className="label_checkbox">
                                  <Field type="checkbox" name="SI07_04_4_01" className="input_checkbox" value="2" checked={values.SI07_04_4_01.includes("2")} />
                                  <span>{t(IK.mandible)}</span>
                                </label>
                              </div>
                            </div>
                            <FieldMessageErrorClick name="SI07_04_4_01" />
                          </div>
                        </div>

                        <div className="frm_div">
                          <div className="frm_flex">
                            <div className="radio">
                              <label className="label_radio">
                                <Field
                                  type="radio"
                                  name="SI07_04"
                                  onClick={(e) => {
                                    handleClick(e, setFieldValue);
                                  }}
                                  className="input_radio"
                                  value="5"
                                />
                                <span>{t(IK.other)}</span>
                              </label>
                            </div>
                            <div className="frm_line_field">
                              <Field id="sub_4_etc" name="SI07_04_5_01" type="text" className={values.SI07_04 === "5" ? "" : "hide"} style={{ width: "120px" }} />
                            </div>
                          </div>
                          <FieldMessageErrorClick name="SI07_04_5_01" />
                        </div>
                      </div>
                      <FieldMessageErrorClick name="SI07_04" />
                    </div>
                  </div>
                  <FieldMessageErrorClick name="bite_3options" />
                </FormControlsBox>
              </div>
            </FormControlsStudyBox>

            <CardTail line study>
              <S3.CardFlex>
                <S.StyledButton $secondary as="button" type="button" onClick={() => setCurrentStepIndex(1)}>
                  {t(IK.select_treatment_options)}
                </S.StyledButton>
                <S.ButtonLtBox>
                  <S.StyledButton as="button" type="button" onClick={prev}>
                    {t(IK.prev)}
                  </S.StyledButton>
                  <S.StyledButton as="button" type="submit" $primary disabled={isLoading}>
                    {t(IK.next)}
                  </S.StyledButton>
                  {isFinish && (
                    <S.StyledButton as="button" type="submit" onClick={() => setSkip(true)} disabled={isLoading} $primary>
                      {t(IK.Skip_to_prescription_summary)}
                    </S.StyledButton>
                  )}
                </S.ButtonLtBox>
              </S3.CardFlex>
            </CardTail>
          </Form>
        )}
      </Formik>
      <Modal open={modalOpen} close={closeModal} headernone>
        <S2.ModalBoxBullet>
          <li>
            {t(IK.deepbite_pop_message1)} {t(IK.deepbite_pop_message2)}
          </li>
          <li>{t(IK.deepbite_pop_message3)}</li>
        </S2.ModalBoxBullet>
      </Modal>
    </>
  );
}

export default Deepbite;
