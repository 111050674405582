import { Field, Form, Formik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useSaveAdditionStep } from "../../../../../apis/additionalStudyApi";
import * as S from "../../../../../components/element/Button/style/ButtonLayout.style";
import { FieldMessageErrorClick } from "../../../../../components/element/FieldMessage/FieldMessage";
import { FormControlsStudyBox } from "../../../../../components/element/Form/FormLayout";
import { CardTail, CardTitle } from "../../../../../components/Layout/CardLayout/CardLayout";
import { IK } from "../../../../../utils/i18n/keyStore";
import { useAdditionalStudyContext } from "../../../../AdditionalStudy/AdditionalStudyProvider";

const AdditionalDeviceDeliveryUnit = () => {
  const { t } = useTranslation(["translation"]);

  const { additionalInfo, setAdditionalInfo, next, prev, setCurrentStepIndex, additionalStudyStep, isFinish, additionalId, patientId, studyId } = useAdditionalStudyContext();
  const { mutate: additionalStudyMutation, isLoading } = useSaveAdditionStep();

  /**요약인지 다음인지 구분 */
  const [skip, setSkip] = useState(false);

  const validationSchema = Yup.object().shape({
    AI08: Yup.string().required(t(IK.device_delivery_unit_required)),
  });

  const handleSubmit = (data) => {
    additionalStudyMutation(
      {
        step: "AI08",
        data,
        params: {
          additionalId,
          patientId,
          studyId,
          isSaveStep: !isFinish,
        },
      },
      {
        onSuccess: () => {
          setAdditionalInfo((prev) => ({ ...prev, ...data }));
          isFinish && skip ? setCurrentStepIndex(additionalStudyStep.length - 1) : next();
        },
      }
    );
  };

  return (
    <>
      <CardTitle title={additionalInfo.patientName} subTitle={`${t(IK[additionalInfo.packages])}`} required />
      <Formik
        initialValues={{
          AI08: additionalInfo.AI08 || "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnMount={true}
      >
        <Form>
          <FormControlsStudyBox title={t(IK.device_delivery_unit)} required>
            <div className="frm_column">
              <div className="frm_div">
                <div className="radio">
                  <label className="label_radio">
                    <Field type="radio" name="AI08" className="input_radio" value="1" />
                    <span>{t(IK.device_delivery_unit_item1_1)}</span>
                  </label>
                </div>
              </div>

              <div className="frm_div">
                <div className="radio">
                  <label className="label_radio">
                    <Field type="radio" name="AI08" className="input_radio" value="2" />
                    <span>{t(IK.device_delivery_unit_item1_2)}</span>
                  </label>
                </div>
              </div>
            </div>
            <FieldMessageErrorClick name="AI08" />
          </FormControlsStudyBox>
          <CardTail line>
            <S.ButtonLtBox>
              <S.StyledButton as="button" type="button" onClick={prev}>
                {t(IK.prev)}
              </S.StyledButton>
              <S.StyledButton as="button" type="submit" disabled={isLoading} $primary>
                {t(IK.next)}
              </S.StyledButton>
              {isFinish && (
                <S.StyledButton as="button" type="submit" disabled={isLoading} onClick={() => setSkip(true)} $primary>
                  {t(IK.Skip_to_prescription_summary)}
                </S.StyledButton>
              )}
            </S.ButtonLtBox>
          </CardTail>
        </Form>
      </Formik>
    </>
  );
};

export default AdditionalDeviceDeliveryUnit;
