import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSaveAdditionStep } from "../../../../../apis/additionalStudyApi";
import { CardTail, CardTitle } from "../../../../../components/Layout/CardLayout/CardLayout";
import Modal from "../../../../../components/Modal/Modal";
import * as S2 from "../../../../../components/Modal/style/Modal.style";
import * as S from "../../../../../components/element/Button/style/ButtonLayout.style";
import { FormControlsStudyBox } from "../../../../../components/element/Form/FormLayout";
import * as S3 from "../../../../../components/element/Form/style/Form.style";
import { IK } from "../../../../../utils/i18n/keyStore";
import { useAdditionalStudyContext } from "../../../../AdditionalStudy/AdditionalStudyProvider";

function Instructions() {
  const { t } = useTranslation(["translation"]);

  const { additionalInfo, setAdditionalInfo, next, prev, setCurrentStepIndex, additionalStudyStep, isFinish, additionalId, patientId, studyId } = useAdditionalStudyContext();
  const { mutate: additionalStudyMutation, isLoading } = useSaveAdditionStep();

  /**요약으로 이동  */
  const [skip, setSkip] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const handleSubmit = (data) => {
    additionalStudyMutation(
      {
        step: "AI06",
        data,
        params: {
          additionalId,
          patientId,
          studyId,
          isSaveStep: !isFinish,
        },
      },
      {
        onSuccess: () => {
          setAdditionalInfo((prev) => ({ ...prev, ...data }));
          isFinish && skip ? setCurrentStepIndex(additionalStudyStep.length - 1) : next();
        },
      }
    );
  };

  return (
    <>
      <CardTitle title={additionalInfo.patientName} />

      <Formik
        initialValues={{
          AI06: additionalInfo.AI06 || "",
        }}
        onSubmit={handleSubmit}
      >
        <Form>
          <FormControlsStudyBox title={t(IK.special_instructions)} openModal={openModal}>
            <Field name="AI06">{({ field }) => <S3.FormControls as="textarea" $textarea $full {...field} />}</Field>
          </FormControlsStudyBox>

          <CardTail
            error={
              <>
                {t(IK.special_instructions_noti1)}
                <br />
                {t(IK.special_instructions_noti2)}
              </>
            }
            line
          >
            <S.ButtonLtBox>
              <S.StyledButton as="button" type="button" onClick={prev}>
                {t(IK.prev)}
              </S.StyledButton>
              <S.StyledButton as="button" type="submit" disabled={isLoading} $primary>
                {t(IK.next)}
              </S.StyledButton>
              {isFinish && (
                <S.StyledButton as="button" type="submit" disabled={isLoading} onClick={() => setSkip(true)} $primary>
                  {t(IK.Skip_to_prescription_summary)}
                </S.StyledButton>
              )}
            </S.ButtonLtBox>
          </CardTail>
        </Form>
      </Formik>

      <Modal open={modalOpen} close={closeModal} headernone>
        <S2.ModalBoxBullet>
          <li>{t(IK.special_instructions_pop_message)}</li>
        </S2.ModalBoxBullet>
      </Modal>
    </>
  );
}

export default Instructions;
