import { Form, Formik } from "formik";
import Notiflix from "notiflix";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useSaveFileStep } from "../../../../../apis/studyApi";
import { CardTail, CardTitle } from "../../../../../components/Layout/CardLayout/CardLayout";
import * as S3 from "../../../../../components/Layout/CardLayout/style/CardLayout.style";
import * as S2 from "../../../../../components/element/Button/style/ButtonLayout.style";
import * as S from "../../../../../components/element/Drop/style/Drop.style";
import { FieldMessageErrorClick } from "../../../../../components/element/FieldMessage/FieldMessage";
import { IK } from "../../../../../utils/i18n/keyStore";
import { deleteEmptyKey } from "../../../../../utils/objectUtils";
import { STUDY_FORM_CONFIG } from "../../../../../utils/studyUtils";
import { useStudyContext } from "../../../../Study/StudyProvider";
import { Previews } from "../Preview/Preview";

function Intraoral() {
  const { t } = useTranslation(["translation"]);

  const { studyInfo, setStudyInfo, next, prev, setCurrentStepIndex, currentStepIndex, studyStep, isFinish } = useStudyContext();
  const { mutate: saveFileMutation, isLoading } = useSaveFileStep();

  //구내사진 조건
  const INTRAORAL_CONFIG = STUDY_FORM_CONFIG[studyInfo.packages][studyStep[currentStepIndex]];

  /**삭제할 아이디 저장 */
  const [deleteStudyFileIds, setDeleteStudyFileIds] = useState([]);

  /**요약인지 다음인지 구분 */
  const [skip, setSkip] = useState(false);

  const validationSchema = Yup.object().shape(
    Object.entries(INTRAORAL_CONFIG).reduce((acc, [key, isRequired]) => {
      if (isRequired) {
        acc[key] = Yup.mixed().required(t(IK.image_required));
      } else {
        acc[key] = Yup.mixed().nullable();
      }
      return acc;
    }, {})
  );

  const handleSubmit = (values) => {
    // 빈 값 제거
    const cleanData = deleteEmptyKey(values);

    const formData = new FormData();

    let formDataLength = 0;
    Object.entries(cleanData).forEach(([key, value]) => {
      if (!value.studyFileId) {
        formData.append(key, value);
        formDataLength++;
      }
    });

    if (formDataLength > 0 || deleteStudyFileIds.length > 0) {
      //삭제할 아이디 추가
      if (deleteStudyFileIds.length > 0) {
        formData.append("deleteStudyFileId", deleteStudyFileIds.join(","));
      }
      //패키지 코드 추가
      formData.append("studyPackagesCode", studyInfo.packages);

      //서버 전송시 로딩
      Notiflix.Loading.standard("");
      saveFileMutation(
        {
          step: "intraoral",
          formData,
          params: {
            studyId: studyInfo.studyId,
            patientId: studyInfo.patientId,
            isSaveStep: !isFinish,
          },
        },
        {
          onSuccess: (data) => {
            const originalData = data.data.data;
            const result = Object.fromEntries(originalData.map((item) => [item.position, item]));

            setDeleteStudyFileIds([]);
            setStudyInfo((prev) => ({ ...prev, ...values, ...result })); //기본 값 넣고, 업데이트 값 넣기
            Notiflix.Loading.remove();
            navigateNext();
          },
          onError: () => {
            Notiflix.Notify.failure(t(IK.again_message));
            Notiflix.Loading.remove();
          },
        }
      );
    } else {
      navigateNext();
    }
  };

  /**다음단계로 이동 */
  const navigateNext = () => {
    if (isFinish && skip) {
      setCurrentStepIndex(studyStep.length - 2);
    } else {
      next();
    }
  };

  return (
    <>
      <CardTitle title={studyInfo.patientName} subTitle={`${t(IK[studyInfo.packages])}`} message={t(IK.image_required_message_1)} icon={<i className="ri-fullscreen-line"></i>} />

      <Formik
        initialValues={{
          occlusal_upper: studyInfo.occlusal_upper || undefined,
          intraoral_front: studyInfo.intraoral_front || undefined,
          occlusal_lower: studyInfo.occlusal_lower || undefined,
          buccal_right: studyInfo.buccal_right || undefined,
          overjet: studyInfo.overjet || undefined,
          buccal_left: studyInfo.buccal_left || undefined,
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnMount={true}
      >
        {({ setFieldValue, values }) => (
          <Form>
            <S.DropListWrap>
              <S.DropList className={INTRAORAL_CONFIG.occlusal_upper ? "required_img occlusal_upper" : "occlusal_upper"}>
                <Previews setFieldValue={setFieldValue} files={values.occlusal_upper} name="occlusal_upper" setDeleteStudyFileIds={setDeleteStudyFileIds} deleteStudyFileIds={deleteStudyFileIds} />
                <FieldMessageErrorClick name="occlusal_upper" />
              </S.DropList>
              <S.DropList className={INTRAORAL_CONFIG.intraoral_front ? "required_img intraoral_front" : "intraoral_front"}>
                <Previews setFieldValue={setFieldValue} files={values.intraoral_front} name="intraoral_front" setDeleteStudyFileIds={setDeleteStudyFileIds} deleteStudyFileIds={deleteStudyFileIds} />
              </S.DropList>
              <S.DropList className={INTRAORAL_CONFIG.occlusal_lower ? "required_img occlusal_lower" : "occlusal_lower"}>
                <Previews setFieldValue={setFieldValue} files={values.occlusal_lower} name="occlusal_lower" setDeleteStudyFileIds={setDeleteStudyFileIds} deleteStudyFileIds={deleteStudyFileIds} />
                <FieldMessageErrorClick name="occlusal_lower" />
              </S.DropList>
              <S.DropList className={INTRAORAL_CONFIG.buccal_right ? "required_img buccal_right" : "buccal_right"}>
                <Previews setFieldValue={setFieldValue} files={values.buccal_right} name="buccal_right" setDeleteStudyFileIds={setDeleteStudyFileIds} deleteStudyFileIds={deleteStudyFileIds} />
                <FieldMessageErrorClick name="buccal_right" />
              </S.DropList>
              <S.DropList className={INTRAORAL_CONFIG.overjet ? "required_img overjet" : "overjet"}>
                <Previews setFieldValue={setFieldValue} files={values.overjet} name="overjet" setDeleteStudyFileIds={setDeleteStudyFileIds} deleteStudyFileIds={deleteStudyFileIds} />
                <FieldMessageErrorClick name="overjet" />
              </S.DropList>
              <S.DropList className={INTRAORAL_CONFIG.buccal_left ? "required_img buccal_left" : "buccal_left"}>
                <Previews setFieldValue={setFieldValue} files={values.buccal_left} name="buccal_left" setDeleteStudyFileIds={setDeleteStudyFileIds} deleteStudyFileIds={deleteStudyFileIds} />
                <FieldMessageErrorClick name="buccal_left" />
              </S.DropList>
            </S.DropListWrap>

            <div className="noti_box">
              <i className="ri-upload-cloud-fill"></i>
              {t(IK.file_drag_message)}
            </div>

            <CardTail error={t(IK.image_required_message_2)} line study>
              <S3.CardFlex>
                <S2.StyledButton $secondary as="button" type="button" onClick={() => setCurrentStepIndex(1)}>
                  {t(IK.select_treatment_options)}
                </S2.StyledButton>
                <S2.ButtonLtBox>
                  <S2.StyledButton as="button" type="button" onClick={prev}>
                    {t(IK.prev)}
                  </S2.StyledButton>
                  <S2.StyledButton as="button" $primary type="submit" disabled={isLoading}>
                    {t(IK.next)}
                  </S2.StyledButton>
                  {isFinish && (
                    <S2.StyledButton as="button" $primary type="submit" disabled={isLoading} onClick={() => setSkip(true)}>
                      {t(IK.Skip_to_prescription_summary)}
                    </S2.StyledButton>
                  )}
                </S2.ButtonLtBox>
              </S3.CardFlex>
            </CardTail>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default Intraoral;
