import { Form, Formik } from "formik";
import Notiflix from "notiflix";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSaveAdditionalFileStep } from "../../../../../apis/additionalStudyApi";
import { CardTail, CardTitle } from "../../../../../components/Layout/CardLayout/CardLayout";
import * as S2 from "../../../../../components/element/Button/style/ButtonLayout.style";
import * as S from "../../../../../components/element/Drop/style/Drop.style";
import { FieldMessageErrorClick } from "../../../../../components/element/FieldMessage/FieldMessage";
import { IK } from "../../../../../utils/i18n/keyStore";
import { deleteEmptyKey } from "../../../../../utils/objectUtils";
import { useAdditionalStudyContext } from "../../../../AdditionalStudy/AdditionalStudyProvider";
import { Previews } from "../Preview/Preview";

function Radiation() {
  const { t } = useTranslation(["translation"]);

  const { additionalInfo, setAdditionalInfo, next, prev, setCurrentStepIndex, additionalStudyStep, isFinish, additionalId, patientId, studyId } = useAdditionalStudyContext();
  const { mutate: additionalStudyFileMutation, isLoading } = useSaveAdditionalFileStep();

  /**요약으로 이동  */
  const [skip, setSkip] = useState(false);

  const [deleteStudyFileIds, setDeleteStudyFileIds] = useState([]);

  /**방사선 사진 제출 */
  const handleSubmit = (values) => {
    const data = deleteEmptyKey(values);

    const formData = new FormData();
    let formDataLength = 0;

    Object.entries(data).forEach(([key, value]) => {
      if (!value.additionalFileId) {
        formData.append(key, value);
        formDataLength++;
      }
    });

    if (formDataLength > 0 || deleteStudyFileIds.length > 0) {
      //서버 전송시 로딩
      Notiflix.Loading.standard("");

      if (deleteStudyFileIds.length > 0) {
        formData.append("deleteAdditionalFileIds", deleteStudyFileIds.join(","));
      }
      //패키지 코드 추가
      formData.append("studyPackagesCode", additionalInfo.packages);

      additionalStudyFileMutation(
        {
          step: "radiograph",
          formData,
          params: {
            additionalId,
            patientId,
            studyId,
            isSaveStep: !isFinish,
          },
        },
        {
          onSuccess: (data) => {
            const originalData = data.data;
            const result = Object.fromEntries(originalData.map((item) => [item.position, item]));

            setDeleteStudyFileIds([]);
            setAdditionalInfo((prev) => ({ ...prev, ...values, ...result })); //기본 값 넣고, 업데이트 값 넣기

            Notiflix.Loading.remove();
            navigateNext();
          },
          onError: (error) => {
            Notiflix.Loading.remove();
            Notiflix.Notify.failure(t(IK.again_message));
          },
        }
      );
    } else {
      navigateNext();
    }
  };

  /**다음단계로 이동 */
  const navigateNext = () => {
    if (isFinish && skip) {
      setCurrentStepIndex(additionalStudyStep.length - 1);
    } else {
      next();
    }
  };

  return (
    <>
      <CardTitle title={additionalInfo.patientName} required />
      <Formik
        initialValues={{
          panorama: additionalInfo.panorama || "",
          cephalo: additionalInfo.cephalo || "",
        }}
        onSubmit={handleSubmit}
        validateOnMount={true}
      >
        {({ setFieldValue, values }) => (
          <Form>
            <S.DropListWrap $radiation>
              <S.DropList className="panorama">
                <Previews setFieldValue={setFieldValue} files={values.panorama} name="panorama" setDeleteStudyFileIds={setDeleteStudyFileIds} deleteStudyFileIds={deleteStudyFileIds} radiation />
                <FieldMessageErrorClick name="panorama" />
              </S.DropList>
              <S.DropList className="cephalo">
                <Previews setFieldValue={setFieldValue} files={values.cephalo} name="cephalo" setDeleteStudyFileIds={setDeleteStudyFileIds} deleteStudyFileIds={deleteStudyFileIds} radiation />
              </S.DropList>
            </S.DropListWrap>

            <div className="noti_box">
              <i className="ri-upload-cloud-fill"></i>
              {t(IK.file_drag_message)}
            </div>

            <CardTail error={t(IK.image_required_message_2)} line>
              <S2.ButtonLtBox>
                <S2.StyledButton as="button" type="button" onClick={() => prev()}>
                  {t(IK.prev)}
                </S2.StyledButton>
                <S2.StyledButton as="button" $primary type="submit" disabled={isLoading}>
                  {t(IK.next)}
                </S2.StyledButton>
                {isFinish && (
                  <S2.StyledButton as="button" $primary type="submit" disabled={isLoading} onClick={() => setSkip(true)}>
                    {t(IK.Skip_to_prescription_summary)}
                  </S2.StyledButton>
                )}
              </S2.ButtonLtBox>
            </CardTail>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default Radiation;
