import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useSaveStep } from "../../../../../apis/studyApi";
import { CardTail, CardTitle } from "../../../../../components/Layout/CardLayout/CardLayout";
import * as S3 from "../../../../../components/Layout/CardLayout/style/CardLayout.style";
import Modal from "../../../../../components/Modal/Modal";
import * as S2 from "../../../../../components/Modal/style/Modal.style";
import * as S from "../../../../../components/element/Button/style/ButtonLayout.style";
import { FieldMessageErrorClick } from "../../../../../components/element/FieldMessage/FieldMessage";
import { FormControlsStudyBox } from "../../../../../components/element/Form/FormLayout";
import TeethCheck from "../../../../../components/element/TeethTable/TeethCheck";
import { IK } from "../../../../../utils/i18n/keyStore";
import { useStudyContext } from "../../../../Study/StudyProvider";

function Attachment() {
  const { t } = useTranslation(["translation"]);

  const { studyInfo, setStudyInfo, next, prev, setCurrentStepIndex, isFinish, studyStep } = useStudyContext();
  const { mutate: saveStepMutation, isLoading } = useSaveStep();

  const [modalOpen, setModalOpen] = useState(false);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  /**요약인지 다음인지 구분 */
  const [skip, setSkip] = useState(false);

  const validationSchema = Yup.object().shape({
    SI03: Yup.string().required(t(IK.attachment_required)),
    SI03_2_01: Yup.array().when("SI03", {
      is: (val) => val === "2",
      then: (val) =>
        Yup.array().test({
          test: function (value) {
            const { SI03_2_02 } = this.parent;
            return value.length > 0 || SI03_2_02.length > 0;
          },
          message: t(IK.sub_option_required),
        }),
    }),
    SI03_2_02: Yup.array().when("SI03", {
      is: (val) => val === "2",
      then: (val) =>
        Yup.array().test({
          test: function (value) {
            const { SI03_2_01 } = this.parent;
            return value.length > 0 || SI03_2_01.length > 0;
          },
          message: t(IK.sub_option_required),
        }),
    }),
  });

  const handleSubmit = (values) => {
    const data = {
      SI03: values.SI03,
      SI03_2_01: values.SI03 === "2" ? values.SI03_2_01.join(",") : "",
      SI03_2_02: values.SI03 === "2" ? values.SI03_2_02.join(",") : "",
    };

    saveStepMutation(
      {
        step: "SI03",
        data,
        params: {
          studyId: studyInfo.studyId,
          patientId: studyInfo.patientId,
          isSaveStep: !isFinish,
        },
      },
      {
        onSuccess: () => {
          setStudyInfo((prev) => ({ ...prev, ...data }));
          isFinish && skip ? setCurrentStepIndex(studyStep.length - 2) : next();
        },
      }
    );
  };

  return (
    <>
      <CardTitle title={studyInfo.patientName} subTitle={`${t(IK[studyInfo.packages])}`} required />

      <Formik
        initialValues={{
          SI03: studyInfo.SI03 || "",
          SI03_2_01: studyInfo.SI03_2_01 ? studyInfo.SI03_2_01.split(",") : [],
          SI03_2_02: studyInfo.SI03_2_02 ? studyInfo.SI03_2_02.split(",") : [],
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnMount={true}
      >
        {({ values, handleChange, setFieldValue }) => (
          <Form>
            <FormControlsStudyBox title={t(IK.attachment)} required openModal={openModal}>
              <div className="frm_column">
                <div className="radio">
                  <label className="label_radio">
                    <Field type="radio" name="SI03" className="input_radio" value="1" />
                    <span>{t(IK.attachment_item1)}</span>
                  </label>
                </div>

                <div className="radio">
                  <label className="label_radio">
                    <Field type="radio" name="SI03" className="input_radio" value="2" />
                    <span>{t(IK.attachment_item2)}</span>
                  </label>
                </div>

                <div className="frm_radio_indent">
                  <TeethCheck code="SI03" label="permanent" studyInfo={studyInfo} handleChange={handleChange} values={values} />
                  <FieldMessageErrorClick name="SI03_2_01" />
                </div>
              </div>
              <FieldMessageErrorClick name="SI03" />
            </FormControlsStudyBox>

            <CardTail line study>
              <S3.CardFlex>
                <S.StyledButton $secondary as="button" type="button" onClick={() => setCurrentStepIndex(1)}>
                  {t(IK.select_treatment_options)}
                </S.StyledButton>
                <S.ButtonLtBox>
                  <S.StyledButton as="button" type="button" onClick={prev}>
                    {t(IK.prev)}
                  </S.StyledButton>
                  <S.StyledButton as="button" type="submit" $primary disabled={isLoading}>
                    {t(IK.next)}
                  </S.StyledButton>
                  {isFinish && (
                    <S.StyledButton as="button" type="submit" onClick={() => setSkip(true)} disabled={isLoading} $primary>
                      {t(IK.Skip_to_prescription_summary)}
                    </S.StyledButton>
                  )}
                </S.ButtonLtBox>
              </S3.CardFlex>
            </CardTail>
          </Form>
        )}
      </Formik>

      <Modal open={modalOpen} close={closeModal} headernone>
        <S2.ModalBoxBullet>
          <li>{t(IK.attachment_pop_message)}</li>
        </S2.ModalBoxBullet>
      </Modal>
    </>
  );
}

export default Attachment;
