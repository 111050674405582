import Notiflix from "notiflix";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSubmitStudy } from "../../../../../apis/studyApi";
import { CardTail, CardTitle } from "../../../../../components/Layout/CardLayout/CardLayout";
import * as S from "../../../../../components/element/Button/style/ButtonLayout.style";
import { FormConfirm } from "../../../../../components/element/Form/FormLayout";
import { IK } from "../../../../../utils/i18n/keyStore";
import { useStudyContext } from "../../../../Study/StudyProvider";

function Confirm() {
  const { t } = useTranslation(["translation"]);
  const navigate = useNavigate();

  const { studyInfo, prev } = useStudyContext();

  const { mutate: submitStudy, isLoading } = useSubmitStudy();

  /**처방전 제출 */
  const hanldeSubmit = () => {
    submitStudy(
      { patientId: studyInfo.patientId, studyId: studyInfo.studyId },
      {
        onSuccess: () => {
          navigate(`/patient/${studyInfo.patientId}/profile`);
        },
        onError: () => {
          Notiflix.Notify.failure(t(IK.again_message));
        },
      }
    );
  };

  return (
    <>
      <CardTitle
        title={
          <>
            {studyInfo.patientName}
            <span>({`${t(IK[studyInfo.packages])}`})</span>
          </>
        }
      />
      <FormConfirm title={t(IK.checklist_message1)}>
        <p>
          {t(IK.checklist_message2)}
          <br />
          {t(IK.checklist_message3)}
        </p>
      </FormConfirm>

      <CardTail line>
        <S.ButtonLtBox>
          <S.StyledButton as="button" type="button" onClick={prev}>
            {t(IK.prev)}
          </S.StyledButton>
          <S.StyledButton as="button" $primary type="submit" disabled={isLoading} onClick={hanldeSubmit}>
            {t(IK.submitted)}
          </S.StyledButton>
        </S.ButtonLtBox>
      </CardTail>
    </>
  );
}

export default Confirm;
