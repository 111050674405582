import axios from "axios";
import { Field, Formik } from "formik";
import Notiflix from "notiflix";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import * as Yup from "yup";
import { useGetPopup } from "../../apis/popupApi";
import { userInfo, userRole, userTimezone } from "../../atoms/userAtom";
import MainModalWrapper from "../../components/Modal/MainModal";
import Modal from "../../components/Modal/Modal";
import Privacy from "../../components/Privacy/Privacy";
import Terms from "../../components/Terms/Terms";
import { FieldMessageErrorClick } from "../../components/element/FieldMessage/FieldMessage";
import { LanguageButton } from "../../components/element/Language/Language";
import { LogoType1 } from "../../components/element/Logo/Logo";
import * as S3 from "../../components/element/SelectionControls/style/SelectionControls.style";
import { setAllToken } from "../../utils/handleToken";
import { IK } from "../../utils/i18n/keyStore";
import * as S from "./style/Login.style";

function Login() {
  const { t, i18n } = useTranslation(["translation"]);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  /**아이디 기억하기 */
  const remberId = localStorage.getItem("rememberMe") || "";

  /**사용자 정보 가져오기 */
  const setUserInfo = useSetRecoilState(userInfo);
  const setUserRole = useSetRecoilState(userRole);

  /**타임존 선택 */
  const setSelectedTimezone = useSetRecoilState(userTimezone);

  /**언어 설정을 변경한 유저의 값 가져오기 (리코일은 로그아웃시 지워버림)*/
  const commonLang = localStorage.getItem("langInfo") || "ko";

  /**사용자 언어 가져오기 */
  const [langInfo, setLangInfo] = useState(commonLang);

  useEffect(() => {
    setLangInfo(commonLang);
  }, [setLangInfo, commonLang]);

  useEffect(() => {
    i18n.changeLanguage(langInfo);
  }, [langInfo, i18n]);

  /**팝업 리스트 조회 */
  const { data: popupData } = useGetPopup();

  /**공백 방지 */
  const handleKeyDown = (event, setFieldValue) => {
    if (event.key === " ") {
      event.preventDefault();
      setFieldValue(event.currentTarget.name, event.currentTarget.value.replace(/\s/g, ""));
    }
  };

  /**공백입력시 제거 */
  const handleTrim = (event, setFieldValue) => {
    const trimValue = event.target.value.replace(/\s/g, "");
    setFieldValue(event.currentTarget.name, trimValue);
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string().required(t(IK.id_required)),
    password: Yup.string().required(t(IK.password_required)),
  });

  /**로그인 요청 */
  const onLoginSubmit = (values, { setSubmitting }) => {
    axios
      .post(`/api/auth/login`, { ...values, accountType: "DOCTOR" })
      .then((response) => {
        if (response.status === 200) {
          const Logindata = response.data.data;
          setAllToken(Logindata.accessToken);
          //타임존 저장
          setSelectedTimezone(Logindata?.accountTimeZone?.split(":")[0]);
          //유저 정보 저장
          setUserInfo(Logindata.roleInfo?.DOCTOR || Logindata.roleInfo?.STAFF);
          //유저 권한 저장
          setUserRole(Logindata.roles);
          if (values.rememberMe) {
            window.localStorage.setItem("rememberMe", values.username);
          } else {
            localStorage.removeItem("rememberMe");
          }
          navigate("/patient/list");
        }
      })
      .catch((err) => {
        if (err?.response?.status === 423) Notiflix.Report.failure("", t(IK.id_lock), t(IK.confirm));
        if (err?.response?.status === 422) Notiflix.Report.failure(t(IK.id_admin), t(IK.id_auth), t(IK.confirm));
        Notiflix.Report.failure(t(IK.fail_credential), t(IK.recheck_message), t(IK.confirm));
      });
    setSubmitting(false);
  };

  const [privacyOpen, setPrivacyOpen] = useState(false);
  const openPrivacy = () => {
    setPrivacyOpen(true);
  };
  const closePrivacy = () => {
    setPrivacyOpen(false);
  };

  const [termsOpen, setTermsOpen] = useState(false);
  const openTerms = () => {
    setTermsOpen(true);
  };
  const closeTerms = () => {
    setTermsOpen(false);
  };

  useEffect(() => {
    //로그인시 쿼리 초기화
    queryClient.clear();
  }, [queryClient]);

  return (
    <>
      <Formik
        initialValues={{
          username: remberId,
          password: "",
          rememberMe: !!remberId,
        }}
        validationSchema={validationSchema}
        onSubmit={onLoginSubmit}
      >
        {({ setFieldValue, handleSubmit, isSubmitting, errors }) => (
          <S.LoginContainer>
            <S.LoginBox onSubmit={handleSubmit} action={"/api/login"}>
              <S.LoginLogo>
                <LogoType1 />
              </S.LoginLogo>

              <S.LoginFormBox>
                <S.LoginForm>
                  <Field type="text" id="username" name="username" onKeyDown={(event) => handleKeyDown(event, setFieldValue)} onChange={(event) => handleTrim(event, setFieldValue)} />
                  <S.LoginFormFocus />
                </S.LoginForm>
                <FieldMessageErrorClick name="username" />
              </S.LoginFormBox>

              <S.LoginFormBox>
                <S.LoginForm>
                  <Field type="password" id="password" name="password" onKeyDown={(event) => handleKeyDown(event, setFieldValue)} onChange={(event) => handleTrim(event, setFieldValue)} />
                  <S.LoginFormFocus />
                </S.LoginForm>
                <FieldMessageErrorClick name="password" />
              </S.LoginFormBox>

              <S.LoginContact>
                <S3.SelectionItem $Small>
                  <S3.SelectionItemLabel>
                    <Field as={S3.SelectionItemInput} $checkbox type="checkbox" name="rememberMe" />
                    <S3.SelectionItemSpan>{t("rememberme")}</S3.SelectionItemSpan>
                  </S3.SelectionItemLabel>
                </S3.SelectionItem>
                <div>
                  <S.LoginAccount to="/join">{t("create_account")}</S.LoginAccount>
                  <Link to="/find-id-form">{t("find_id_pw")}</Link>
                </div>
              </S.LoginContact>

              <S.LoginButton type="submit" disabled={isSubmitting}>
                {t("login")}
              </S.LoginButton>

              <S.LoginBottom>
                {langInfo === "ko" && (
                  <div>
                    <button type="button" onClick={openPrivacy}>
                      {t("privacy")}
                    </button>
                    <button type="button" onClick={openTerms}>
                      {t("terms_of_use")}
                    </button>
                  </div>
                )}
                <p>{t(IK.tns_copyright)}</p>
              </S.LoginBottom>
              {/* 언어선택 */}
              <LanguageButton langInfo={langInfo} setLangInfo={setLangInfo} />
            </S.LoginBox>
          </S.LoginContainer>
        )}
      </Formik>
      {/* 개인정보처리방침 */}
      <Modal open={privacyOpen} close={closePrivacy} header={t(IK.privacy)}>
        <Privacy />
      </Modal>

      {/* 이용약관 */}
      <Modal open={termsOpen} close={closeTerms} header={t(IK.terms_of_use)} headerSub={"(2023.07.05 " + t(IK.partial_revision) + ")"}>
        <Terms />
      </Modal>

      {/*팝업 디스플레이 */}
      <MainModalWrapper modals={popupData} />
    </>
  );
}

export default Login;
