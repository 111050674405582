import { useTranslation } from "react-i18next";
import { downloadFile } from "../../../apis/boardApi";
import * as S4 from "../../../components/element/Button/style/ButtonLayout.style";
import * as S2 from "../../../components/element/Drop/style/Drop.style";
import * as S3 from "../../../styles/Common";
import { IK } from "../../../utils/i18n/keyStore";
import { plasterModelSend } from "../../../utils/studyUtils";
import DragImageBox from "../Drop/style/DragImageBox";
import TeethSummaryBox from "../TeethTable/TeethSummaryBox";
import * as S from "./style/Summary.style";
import { SummaryTableLi } from "./summaryTableLi";

export const AdditionalSummaryTable = ({ additionalInfo, additionalSummaryStudyStep, handleClickForUpdate }) => {
  const { t } = useTranslation(["translation"]);

  return (
    <S.SummaryList>
      {/* 의사명 */}
      <SummaryTableLi title={t(IK.doctor_name)}>
        {additionalInfo.doctor && (
          <p>
            {additionalInfo.doctor.name} {additionalInfo.doctor?.koreaName && `(${additionalInfo.doctor.koreaName})`}
          </p>
        )}
      </SummaryTableLi>

      {/* 인쇄시 환자명, 치료옵션 보이도록 */}
      <SummaryTableLi printshow title={t(IK.patient_name)}>
        {additionalInfo?.patient && (
          <p>{`${additionalInfo.patient?.firstName} ${additionalInfo.patient?.lastName}${additionalInfo.patient?.korName ? ` (${additionalInfo.patient?.korName})` : ""}`}</p>
        )}
      </SummaryTableLi>
      <SummaryTableLi printshow title={t(IK.treatment_option)}>
        {additionalInfo.study && <p>{`${t(IK[additionalInfo.study?.packages])}`}</p>}
      </SummaryTableLi>

      {/* 배송지 */}
      <SummaryTableLi title={t(IK.shipping_address)}>{additionalInfo.patient && <p>{additionalInfo.patient.shipAddressName}</p>}</SummaryTableLi>

      {/* 청구지 */}
      <SummaryTableLi title={t(IK.billing_address)}>{additionalInfo.patient && <p>{additionalInfo.patient.billAddressName}</p>}</SummaryTableLi>

      {/* 구외 사진 */}
      {additionalSummaryStudyStep.has("EXTRAORAL") && (
        <SummaryTableLi title={t(IK.extraoral)} handleClickForUpdate={handleClickForUpdate} dataId="EXTRAORAL">
          <S2.DropListWrap>
            <DragImageBox file={additionalInfo.extraoral_front} preview="extraoral_front" />
            <DragImageBox file={additionalInfo.front_rest} preview="front_rest" />
            <DragImageBox file={additionalInfo.front_smile} preview="front_smile" />
            <DragImageBox file={additionalInfo.side45} preview="side45" />
            <DragImageBox file={additionalInfo.side90} preview="side90" />
            <DragImageBox file={additionalInfo.side90_smile} preview="side90_smile" />
          </S2.DropListWrap>
        </SummaryTableLi>
      )}

      {/* 구내 사진 */}
      {additionalSummaryStudyStep.has("INTRAORAL") && (
        <SummaryTableLi title={t(IK.intraoral)} handleClickForUpdate={handleClickForUpdate} dataId="INTRAORAL">
          <S2.DropListWrap>
            <DragImageBox file={additionalInfo.occlusal_upper} preview="occlusal_upper" />
            <DragImageBox file={additionalInfo.intraoral_front} preview="intraoral_front" />
            <DragImageBox file={additionalInfo.occlusal_lower} preview="occlusal_lower" />
            <DragImageBox file={additionalInfo.buccal_right} preview="buccal_right" />
            <DragImageBox file={additionalInfo.overjet} preview="overjet" />
            <DragImageBox file={additionalInfo.buccal_left} preview="buccal_left" />
          </S2.DropListWrap>
        </SummaryTableLi>
      )}

      {/* 방사선 사진 */}
      {additionalSummaryStudyStep.has("RADIOGRAPH") && (
        <SummaryTableLi title={t(IK.radiograph)} handleClickForUpdate={handleClickForUpdate} dataId="RADIOGRAPH">
          <S2.DropListWrap $radiation>
            <DragImageBox file={additionalInfo.panorama} preview="panorama" isRadiation />
            <DragImageBox file={additionalInfo.cephalo} preview="cephalo" isRadiation />
          </S2.DropListWrap>
        </SummaryTableLi>
      )}

      {/* 스캔파일 */}
      {additionalSummaryStudyStep.has("SCAN_FILE") && (
        <SummaryTableLi title={t(IK.scan_file_registration)} handleClickForUpdate={handleClickForUpdate} dataId="SCAN_FILE">
          {additionalInfo.scan?.length > 0 ? (
            <S4.ButtonFlex>
              {additionalInfo.scan?.map((item, index) => {
                return (
                  <S4.StyledSmallButton as="button" key={index} onClick={() => downloadFile(item.fileId, item.orgFileName)}>
                    {item.orgFileName}
                  </S4.StyledSmallButton>
                );
              })}
            </S4.ButtonFlex>
          ) : (
            <p>
              {plasterModelSend(t, additionalInfo?.AIPlasterModel)} {additionalInfo?.AIPhoneNumber && `(${additionalInfo?.AIPhoneNumber})`}
            </p>
          )}
        </SummaryTableLi>
      )}

      {/* 현재 환자가 착용하고 있는 장치 번호는? */}
      {additionalSummaryStudyStep.has("AI02") && (
        <SummaryTableLi title={t(IK.current_device_number_title)} handleClickForUpdate={handleClickForUpdate} dataId="AI02">
          <div className="frm_column">
            {additionalInfo.AI02_01 && (
              <p>
                {t(IK.maxillary_aligner_number)} : {additionalInfo.AI02_01}
              </p>
            )}
            {additionalInfo.AI02_02 && (
              <p>
                {t(IK.mandibular_aligner_number)} : {additionalInfo.AI02_02}
              </p>
            )}
          </div>
        </SummaryTableLi>
      )}

      {/* 어태치먼트 유지 및 제거 여부 */}
      {additionalSummaryStudyStep.has("AI04") && (
        <SummaryTableLi title={t(IK.attachment_select)} handleClickForUpdate={handleClickForUpdate} dataId="AI04">
          <p>{t(IK[`additional_attachment_item${additionalInfo.AI04}`])}</p>
        </SummaryTableLi>
      )}

      {/* 어태치먼트 AP*/}
      {additionalSummaryStudyStep.has("AI07") && (
        <SummaryTableLi title={t(IK.ap_attachment)} handleClickForUpdate={handleClickForUpdate} dataId="AI07">
          {additionalInfo.AI07 === "1" && <p>{t(IK.ap_attachment_text_1)}</p>}
          {additionalInfo.AI07 === "2" && <p>{t(IK.ap_attachment_text_2)}</p>}
          {additionalInfo.AI07 === "3" && <p>{t(IK.ap_attachment_text_3)}</p>}
        </SummaryTableLi>
      )}

      {/* 이동에 제한이 있는 치아 */}
      {additionalSummaryStudyStep.has("AI05") && (
        <SummaryTableLi title={t(IK.teeth_limited_mobility)} handleClickForUpdate={handleClickForUpdate} dataId="AI05">
          {additionalInfo.AI05 === "1" && <p>{t(IK.teeth_limited_mobility_item1)}</p>}
          {additionalInfo.AI05 === "2" && <TeethSummaryBox label="permanent" studyInfo={additionalInfo} code="AI05" />}
        </SummaryTableLi>
      )}

      {/* 특별지시사항 */}
      <SummaryTableLi title={t(IK.special_instructions)} handleClickForUpdate={handleClickForUpdate} dataId="AI06">
        <S3.TextDiv $textpreline>{additionalInfo.AI06}</S3.TextDiv>
      </SummaryTableLi>

      {/* 장치배송의 기본 단위 */}
      {additionalSummaryStudyStep.has("AI08") && (
        <SummaryTableLi title={t(IK.device_delivery_unit)} handleClickForUpdate={handleClickForUpdate} dataId="AI08">
          <p>{additionalInfo.AI08 === "1" && t(IK.device_delivery_unit_item1_1)}</p>
          <p>{additionalInfo.AI08 === "2" && t(IK.device_delivery_unit_item1_2)}</p>
        </SummaryTableLi>
      )}
    </S.SummaryList>
  );
};
