import { Form, Formik } from "formik";
import Notiflix from "notiflix";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useGetPatientScan, useUpdatePatientScan } from "../../../../apis/patientApi";
import { CardInnerLayout, CardTail, CardTitle } from "../../../../components/Layout/CardLayout/CardLayout";
import * as S from "../../../../components/element/Button/style/ButtonLayout.style";
import { FieldMessageError } from "../../../../components/element/FieldMessage/FieldMessage";
import { ScanPreview } from "../../../../components/element/ScanPreview/ScanPreview";
import LoadingIndicator from "../../../../components/loading/LoadingIndicator/LoadingIndicator";
import { IK } from "../../../../utils/i18n/keyStore";

const ScanEdit = () => {
  const { patientId } = useParams();
  const navigate = useNavigate();

  const { t } = useTranslation(["translation"]);
  const [deleteStudyFileIds, setDeleteStudyFileIds] = useState([]);

  /**스캔 파일 가져오기 */
  const { data: ScanData, isLoading } = useGetPatientScan(patientId);
  const scanInfo = ScanData?.data;

  /**메딧파일 가져오기 */
  const [meditScan, setMeditScan] = useState([]);

  const scanMutation = useUpdatePatientScan();

  const validationSchema = Yup.object().shape({
    scan: Yup.array().when([], {
      is: () => meditScan.length === 0,
      then: () => Yup.array().min(1, t(IK.scan_file_message)),
      otherwise: () => Yup.array(),
    }),
  });

  const handleSubmit = (values, { resetForm }) => {
    const formData = new FormData();

    let formDataLength = 0;
    values.scan.forEach((value) => {
      if (!value.patientFileId) {
        formData.append("scan", value);
        formDataLength++;
      }
    });

    if (meditScan.length > 0 || formDataLength > 0 || deleteStudyFileIds.length > 0) {
      Notiflix.Loading.standard("");

      if (deleteStudyFileIds.length > 0) {
        formData.append("deletePatientFileIds", deleteStudyFileIds.join(","));
      }
      if (meditScan.length > 0) {
        formData.append("meditFiles", JSON.stringify(meditScan));
      }

      scanMutation.mutate(
        { patientId, formData },
        {
          onSuccess: () => {
            setDeleteStudyFileIds([]);
            setMeditScan([]);
            resetForm();
            Notiflix.Loading.remove();
            Notiflix.Notify.success(t(IK.update_success));
          },
          onError: (res) => {
            Notiflix.Loading.remove();
            Notiflix.Report.failure(t(IK.file_faile), t(IK.file_faile_check), t(IK.confirm));
          },
        }
      );
    }
  };

  return (
    <>
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <Formik
          initialValues={{
            scan: scanInfo || [],
          }}
          enableReinitialize={true}
          validateOnMount={true}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, values, errors }) => (
            <Form>
              <CardInnerLayout>
                <CardTitle title={t(IK.scan_file_registration)} />

                <ScanPreview setMeditScan={setMeditScan} setFieldValue={setFieldValue} setDeleteStudyFileIds={setDeleteStudyFileIds} files={values.scan} meditScan={meditScan} />
                {errors.scan && meditScan.length === 0 && <FieldMessageError text={errors.scan} />}

                <CardTail line>
                  <S.ButtonLtBox>
                    <S.StyledButton as="button" type="button" onClick={() => navigate(`/patient/${patientId}/profile`)}>
                      {t(IK.list)}
                    </S.StyledButton>
                    <S.StyledButton as="button" $primary type="submit">
                      {t(IK.save)}
                    </S.StyledButton>
                  </S.ButtonLtBox>
                </CardTail>
              </CardInnerLayout>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default ScanEdit;
