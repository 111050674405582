import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useSaveAdditionStep } from "../../../../../apis/additionalStudyApi";
import { CardTail, CardTitle } from "../../../../../components/Layout/CardLayout/CardLayout";
import * as S from "../../../../../components/element/Button/style/ButtonLayout.style";
import { FieldMessageErrorClick } from "../../../../../components/element/FieldMessage/FieldMessage";
import { FormControlsStudyBox } from "../../../../../components/element/Form/FormLayout";
import { IK } from "../../../../../utils/i18n/keyStore";
import { useAdditionalStudyContext } from "../../../../AdditionalStudy/AdditionalStudyProvider";

function CurrentDeviceNumber() {
  const { t } = useTranslation(["translation"]);

  const { additionalInfo, setAdditionalInfo, next, prev, setCurrentStepIndex, additionalStudyStep, isFinish, additionalId, patientId, studyId } = useAdditionalStudyContext();
  const { mutate: additionalStudyMutation, isLoading } = useSaveAdditionStep();

  /**요약으로 이동  */
  const [skip, setSkip] = useState(false);

  const validationSchema = Yup.object().shape({
    AI02_01: additionalInfo.SI01 === "1" || additionalInfo.SI01 === "2" ? Yup.number().moreThan(0, t(IK.device_number_required)).required(t(IK.device_number_required)) : Yup.number(),
    AI02_02: additionalInfo.SI01 === "1" || additionalInfo.SI01 === "3" ? Yup.number().moreThan(0, t(IK.device_number_required)).required(t(IK.device_number_required)) : Yup.number(),
  });

  const handleSubmit = (data) => {
    //2번 선택시 AI02_02값 삭제
    if (additionalInfo.SI01 === "2") {
      delete data.AI02_02;
    }
    //3번 선택시 AI02_01값 삭제
    if (additionalInfo.SI01 === "3") {
      delete data.AI02_01;
    }
    additionalStudyMutation(
      {
        step: "AI02",
        data,
        params: {
          additionalId,
          patientId,
          studyId,
          isSaveStep: !isFinish,
        },
      },
      {
        onSuccess: () => {
          setAdditionalInfo((prev) => ({ ...prev, ...data }));
          isFinish && skip ? setCurrentStepIndex(additionalStudyStep.length - 1) : next();
        },
      }
    );
  };
  return (
    <>
      <CardTitle title={additionalInfo.patientName} required />

      <Formik
        initialValues={{
          AI02_01: additionalInfo.AI02_01 || "",
          AI02_02: additionalInfo.AI02_02 || "",
        }}
        onSubmit={handleSubmit}
        validateOnMount={true}
        validationSchema={validationSchema}
      >
        {({ values, handleChange, setFieldValue }) => (
          <Form>
            <FormControlsStudyBox title={t(IK.current_device_number)} required>
              <div className="frm_column">
                {(additionalInfo.SI01 === "1" || additionalInfo.SI01 === "2") && (
                  <div className="frm_div">
                    <div className="frm_line_field">
                      {t(IK.maxillary_aligner_number)}&nbsp;&nbsp;&nbsp;
                      <Field name="AI02_01" type="number" />
                    </div>
                    <FieldMessageErrorClick name="AI02_01" />
                  </div>
                )}
                {(additionalInfo.SI01 === "1" || additionalInfo.SI01 === "3") && (
                  <div className="frm_div">
                    <div className="frm_line_field">
                      {t(IK.mandibular_aligner_number)}&nbsp;&nbsp;&nbsp;
                      <Field name="AI02_02" type="number" />
                    </div>
                    <FieldMessageErrorClick name="AI02_02" />
                  </div>
                )}
              </div>
            </FormControlsStudyBox>
            <CardTail line>
              <S.ButtonLtBox>
                <S.StyledButton as="button" type="button" onClick={prev}>
                  {t(IK.prev)}
                </S.StyledButton>
                <S.StyledButton as="button" $primary type="submit" disabled={isLoading}>
                  {t(IK.next)}
                </S.StyledButton>
                {isFinish && (
                  <S.StyledButton as="button" $primary type="submit" disabled={isLoading} onClick={() => setSkip(true)}>
                    {t(IK.Skip_to_prescription_summary)}
                  </S.StyledButton>
                )}
              </S.ButtonLtBox>
            </CardTail>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default CurrentDeviceNumber;
