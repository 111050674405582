import { createContext, useContext } from "react";

const AdditionalStudyContext = createContext(null);

export const AdditionalStudyProvider = ({ children, value }) => {
  return <AdditionalStudyContext.Provider value={value}>{children}</AdditionalStudyContext.Provider>;
};

export const useAdditionalStudyContext = () => {
  const context = useContext(AdditionalStudyContext);
  if (!context) {
    throw new Error("provider 컴포넌트 내부에서 사용해야 합니다.");
  }
  return context;
};
