import { useTranslation } from "react-i18next";
import Modal from "../../../../../components/Modal/Modal";
import * as S2 from "../../../../../components/element/Table/style/table.style";
import { IK } from "../../../../../utils/i18n/keyStore";
import * as S from "./style/Option.style";

export const OptionModal = ({ modalOpen, closeModal, age }) => {
  const { t } = useTranslation(["translation"]);

  return (
    <Modal open={modalOpen} close={closeModal} header={t(IK.serafin_device_option)}>
      <S2.TableType1>
        <colgroup>
          <col width="150px" />
          <col width="250px" />
          <col width="130px" />
          <col width="260px" />
          <col width="100px" />
        </colgroup>
        <thead>
          <tr>
            <th>{t(IK.serafin_options)}</th>
            <th>{t(IK.device_option_title1)}</th>
            <th>{t(IK.device_option_title2)}</th>
            <th>{t(IK.device_option_title3)}</th>
            <th>{t(IK.end_date)}</th>
          </tr>
        </thead>
        <tbody>
          {age === "adult" && (
            <>
              <tr>
                <td>
                  <S.Hexagon $HexagonGreen>
                    <S.HexagonTit>R</S.HexagonTit>
                    <S.HexagonSpan>Passive</S.HexagonSpan>
                  </S.Hexagon>
                </td>
                <td>
                  <strong>{t(IK.device_option_r_passive_txt1)}</strong>
                </td>
                <td>
                  <strong>{t(IK.device_option_r_passive_txt2)}</strong>
                </td>
                <td>{t(IK.device_option_r_passive_txt4)}</td>
                <td>8{t(IK.months)}</td>
              </tr>
              <tr>
                <td>
                  <S.Hexagon $HexagonGreen>
                    <S.HexagonTit>R</S.HexagonTit>
                    <S.HexagonSpan>Active</S.HexagonSpan>
                  </S.Hexagon>
                </td>
                <td>
                  <strong>{t(IK.device_option_r_active_txt1)}</strong>
                </td>
                <td>
                  <strong>{t(IK.device_option_r_passive_txt5)}</strong>
                </td>
                <td>{t(IK.device_option_r_active_txt2)}</td>
                <td>8{t(IK.months)}</td>
              </tr>
              <tr>
                <td>
                  <S.Hexagon $HexagonBlue>
                    <S.HexagonTit>10</S.HexagonTit>
                  </S.Hexagon>
                </td>
                <td>
                  <strong>{t(IK.device_option_10_txt1)}</strong>
                </td>
                <td>
                  <strong>{t(IK.device_option_10_txt3)}</strong>
                </td>
                <td>{t(IK.device_option_10_txt2)}</td>
                <td>1{t(IK.year)}</td>
              </tr>
              <tr>
                <td>
                  <S.Hexagon $HexagonBlue>
                    <S.HexagonTit>20</S.HexagonTit>
                  </S.Hexagon>
                </td>
                <td>
                  <strong>{t(IK.device_option_20_txt1)}</strong>
                </td>
                <td>
                  <strong>{t(IK.device_option_20_txt3)}</strong>
                </td>
                <td>{t(IK.device_option_20_txt2)}</td>
                <td>2{t(IK.years)}</td>
              </tr>
              <tr>
                <td>
                  <S.Hexagon $HexagonBlue>
                    <S.HexagonTit fontSize="15px">{t(IK.regular)}</S.HexagonTit>
                  </S.Hexagon>
                </td>
                <td>
                  <strong>
                    {t(IK.device_option_regular_txt1)}, {t(IK.device_option_regular_txt2)}
                  </strong>
                </td>
                <td>
                  <strong>{t(IK.varies_per_treatment)}</strong>
                </td>
                <td>{t(IK.varies_per_treatment)}</td>
                <td>5{t(IK.years)}</td>
              </tr>
              <tr>
                <td>
                  <S.Hexagon $HexagonBlue>
                    <S.HexagonTit fontSize="15px">{t(IK.regular)}</S.HexagonTit>
                    <S.HexagonSpan>AP</S.HexagonSpan>
                  </S.Hexagon>
                </td>
                <td>
                  <strong>{t(IK.device_option_regular_ap_txt1)}</strong>
                </td>
                <td>
                  <strong>{t(IK.varies_per_treatment)}</strong>
                </td>
                <td>{t(IK.varies_per_treatment)}</td>
                <td>5{t(IK.years)}</td>
              </tr>
            </>
          )}
          {age === "child" && (
            <>
              <tr>
                <td>
                  <S.Hexagon $HexagonSkyblue>
                    <S.HexagonTit>i</S.HexagonTit>
                  </S.Hexagon>
                </td>
                <td>
                  <strong>{t(IK.device_option_i_txt1)}</strong>
                  <br />
                  {t(IK.device_option_i_txt2)}
                  <br />
                  {t(IK.device_option_i_txt3)}
                </td>
                <td>
                  <strong>{t(IK.varies_per_treatment)}</strong>
                </td>
                <td>{t(IK.varies_per_treatment)}</td>
                <td>1{t(IK.year)}</td>
              </tr>
              <tr>
                <td>
                  <S.Hexagon $HexagonSkyblue>
                    <S.HexagonTit>iAP</S.HexagonTit>
                  </S.Hexagon>
                </td>
                <td>
                  <strong>{t(IK.device_option_i_txt1)}</strong>
                  <br />
                  {t(IK.device_option_iap_txt1)}
                  <br />
                  {t(IK.device_option_iap_txt2)}
                </td>
                <td>
                  <strong>{t(IK.varies_per_treatment)}</strong>
                </td>
                <td>{t(IK.varies_per_treatment)}</td>
                <td>18{t(IK.months)}</td>
              </tr>
            </>
          )}
        </tbody>
      </S2.TableType1>
    </Modal>
  );
};
