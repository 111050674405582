import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { useAuthAdminDoctor } from "../../apis/doctorApi";
import { useGetPatientInfo } from "../../apis/patientApi";
import { useGetStudyStep, useGetStudySummary } from "../../apis/studyApi";
import { userInfo } from "../../atoms/userAtom";
import { CardInnerLayout } from "../../components/Layout/CardLayout/CardLayout";
import * as S from "../../components/Layout/CardLayout/style/CardLayout.style";
import LoadingIndicator from "../../components/loading/LoadingIndicator/LoadingIndicator";
import { useSessionStorageState } from "../../Hooks/useSessionStorageState";
import { selectPackageStep, SETTING_STUDY_STEP, STUDY_STEP_LABEL } from "../../utils/studyUtils";
import StudyFilter from "./StudyFilter";
import { StudyProvider } from "./StudyProvider";
import StudyStepLabel from "./StudyStepLabel";

/**본처방전 작성 화면 */
const StudyPrescription = () => {
  const { patientId, studyId } = useParams();
  const navigate = useNavigate();

  /**어드민 환자 권한 확인 */
  useAuthAdminDoctor(patientId);

  /**국가 코드 */
  const { countryCode } = useRecoilValue(userInfo);

  /**처방전 정보값 저장 */
  const [studyInfo, setStudyInfo] = useState({
    patientName: "",
    age: "",
    packages: "",
  });

  /**처방전 스텝 배열 */
  const [studyStep, setStudyStep] = useState([...SETTING_STUDY_STEP, "SI01"]);

  /**현재 스텝 순서 */
  const [currentStepIndex, setCurrentStepIndex] = useSessionStorageState("stepIndex", 0);

  /**요약화면으로 이동 가능한지 검사 */
  const [isFinish, setIsFinish] = useState(false);

  /**전체 로딩 관리 */
  const [isLoading, setIsLoading] = useState(true);

  /**환자 정보 가져오기
   * 첫 작성시에만 조회
   */
  const { data: patientData, isLoading: isPatientLoading } = useGetPatientInfo(patientId, studyId);
  const patientInfo = patientData?.data;

  /**처음 처방전 작성시 환자 이름 저장, 스터디 아이디 없을때만 조회 */
  useEffect(() => {
    //첫 작성시에만 탐색
    if (!studyId && !isPatientLoading) {
      const patientName = `${patientInfo?.firstName} ${patientInfo?.lastName}${patientInfo?.koreaName ? ` (${patientInfo.koreaName})` : ""}`;
      setStudyInfo((prev) => ({ ...prev, patientName: patientName, patientId }));
      setCurrentStepIndex(0);
      setIsLoading(false);
    }
  }, [isPatientLoading, patientInfo, studyId, patientId, setCurrentStepIndex]);

  /**처방전 정보 가져오기
   * 이어서 작성하기인 경우 조회
   */
  const { data: studyData, isLoading: isStudyLoading } = useGetStudySummary(patientId, studyId);
  const studyPatientData = studyData?.data;

  /**처방전 현 스텝 조회 */
  const { data: studyStepData, isLoading: isStudyStepLoading } = useGetStudyStep(studyId);

  useEffect(() => {
    //이어서 작성하기로 들어온 경우
    if (studyId && !isStudyLoading && !isStudyStepLoading) {
      const { firstName, lastName, korName } = studyPatientData?.patient;
      const { studyId, age, packages } = studyPatientData?.study;

      const isSummaryStep = studyPatientData.detail === "12" || studyPatientData.detail === "11";

      const nowStudyStep = selectPackageStep(packages, countryCode, studyPatientData.SI01);
      const stepIndex = nowStudyStep.findIndex((step) => step === studyStepData.currentStudyStep);

      if (isSummaryStep) {
        setIsFinish(true); //요약도달, 반려 둘다 요약화면으로 이동 가능
      }

      setStudyInfo({
        ...studyPatientData,
        patientId,
        studyId,
        age,
        packages,
        patientName: `${firstName} ${lastName}${!!korName ? ` (${korName})` : ""}`,
      });

      setStudyStep(nowStudyStep); //스텝 선택

      setCurrentStepIndex((prev) => {
        if (isSummaryStep) return nowStudyStep.length - 2; //요약도달시 매번 요약화면으로 이동
        if ((prev ?? -1) > stepIndex) return stepIndex + 1; //stepIndex를 초과하면 보정
        return prev !== 0 ? prev : stepIndex + 1; // prev가 0이 아니면 prev 반환
      });

      setIsLoading(false);
    }
  }, [countryCode, isStudyLoading, isStudyStepLoading, studyId, studyPatientData, studyStepData, patientId, setCurrentStepIndex]);

  // 다음 단계로 이동
  const next = () => {
    if (currentStepIndex < studyStep.length - 1) {
      setCurrentStepIndex(currentStepIndex + 1);
    }
  };

  // 이전 단계로 이동
  const prev = () => {
    if (currentStepIndex > 0) {
      setCurrentStepIndex(currentStepIndex - 1);
    } else {
      navigate(`/patient/${patientId}/profile`);
    }
  };

  return (
    <>
      {isPatientLoading || isStudyLoading || isStudyStepLoading || isLoading ? (
        <LoadingIndicator />
      ) : (
        <StudyProvider value={{ studyStep, setStudyStep, studyInfo, setStudyInfo, next, prev, currentStepIndex, setCurrentStepIndex, countryCode, isFinish, setIsFinish }}>
          <StudyStepLabel studyStep={studyStep} currentStepIndex={currentStepIndex} />
          <S.CardFlex>
            <S.CardColWd $full>
              <CardInnerLayout>{STUDY_STEP_LABEL[studyStep[currentStepIndex]]}</CardInnerLayout>
            </S.CardColWd>
            <StudyFilter isStudy studyInfo={studyInfo} setCurrentStepIndex={setCurrentStepIndex} currentStepIndex={currentStepIndex} studyStep={studyStep} />
          </S.CardFlex>
        </StudyProvider>
      )}
    </>
  );
};

export default StudyPrescription;
