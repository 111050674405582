import axios from "axios";
import Notiflix from "notiflix";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { IK } from "../utils/i18n/keyStore";

/** 영상 시청 여부 판단 */
export const getDoctorFirstAccessInfo = async () => {
  const response = await axios.get(`/api/doctor/access`);
  return response;
};

/** 의사정보 조회 */
export const useGetDoctorDTO = (user) => {
  return useQuery(["DoctorDTO", user], async () => {
    const { data } = await axios.get(`/api/doctor/user-info`);
    return data;
  });
};

/**의사 정보 업데이트 팝업*/
export const useUpdateDoctorDTO = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (doctorDTO) => {
      const response = await axios.put(`/api/doctor`, doctorDTO);
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("DoctorDTO");
      },
    }
  );
};

/** 업데이트용 이메일 중복 확인  */
export const checkEmailUpdate = async (email) => {
  const response = await axios.post(`/api/doctor/check-email`, email);
  return response;
};

/** 업데이트용 면허 중복 확인  */
export const checkLicenseNumberUpdate = async (license) => {
  const response = await axios.post(`/api/doctor/check-license`, license);
  return response;
};

/** 치과 정보 조회 */
export const useGetClinicInfo = () => {
  const { t } = useTranslation();
  return useQuery(
    ["ClinicInfo"],
    async () => {
      const { data } = await axios.get(`/api/doctor/info/clinic`);
      return data;
    },
    {
      onError: (error) => {
        Notiflix.Report.warning(t(IK.server_error2), t(IK.server_error1), t(IK.confirm), () => {});
      },
    }
  );
};

/** 치과 정보 업데이트 */
export const useUpdateClinicInfo = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (clinicInfo) => {
      const response = await axios.put(`/api/doctor/info/clinic`, clinicInfo, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    },
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries("ClinicInfo");
      },
    }
  );
};

/** 의사 정보 조회 */
export const useGetDoctorInfo = () => {
  return useQuery(["DoctorInfo"], async () => {
    const { data } = await axios.get(`/api/doctor/info`);
    return data;
  });
};

/** 의사 정보 업데이트  */
export const useUpdateDoctorInfo = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (doctorInfo) => {
      await axios.put(`/api/doctor/info`, doctorInfo);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("DoctorInfo");
      },
    }
  );
};

/** 기타 정보 조회 */
export const useGeEtcInfo = () => {
  return useQuery(["EtcInfo"], async () => {
    const { data } = await axios.get(`/api/doctor/info/etc`);
    return data;
  });
};

/** 기타 정보 업데이트  */
export const useUpdateEtcInfo = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (etcInfo) => {
      const response = await axios.put(`/api/doctor/info/etc`, etcInfo);
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("EtcInfo");
      },
    }
  );
};

/** 담당 환자 권한 조회  */
export const getDoctorPatientAuth = async (patientId) => {
  return await axios.get(`/api/patient/auth?patientId=${patientId}`);
};

/**어드민 접속시 조회는 가능하나, 작성은 못하도록
 * 본인 환자만 작성가능/ 나머지는 403 처리
 */
export const useAuthAdminDoctor = (patientId) => {
  return useQuery(["AdminDoctor", patientId], async () => {
    const { data } = await axios.get(`/api/doctor/my-patient?patientId=${patientId}`);
    return data;
  });
};

/////////////////////////////////코인 관련 api /////////////////////////////////////

/**로그인한 의사의 코인 갯수 조회 */
export const useGetDoctorCoin = (isOpen) => {
  return useQuery(
    ["DoctorCoin"],
    async () => {
      const { data } = await axios.get(`/api/coins/login-amount`);
      return data;
    },
    {
      enabled: isOpen,
    }
  );
};

/**코인 사용내역 리스트 조회 */
export const useGetCoinHistory = (isOpen, searchCondition) => {
  return useQuery(
    ["CoinHistory", searchCondition],
    async () => {
      const { data } = await axios.get(`/api/coins/login-amount/history`, {
        params: searchCondition,
      });
      return data;
    },
    {
      enabled: isOpen,
    }
  );
};
