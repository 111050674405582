import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ImageViewer from "react-simple-image-viewer";
import { useSetRecoilState } from "recoil";
import ExtraoralFrontImage from "../../assets/images/patients/register/extraoral/extraoral_front.jpg";
import FrontRestImage from "../../assets/images/patients/register/extraoral/front_rest.jpg";
import FrontSmileImage from "../../assets/images/patients/register/extraoral/front_smile.jpg";
import Side45Image from "../../assets/images/patients/register/extraoral/side45.jpg";
import Side90Image from "../../assets/images/patients/register/extraoral/side90.jpg";
import Side90SmileImage from "../../assets/images/patients/register/extraoral/side90_smile.jpg";
import BuccalLeftImage from "../../assets/images/patients/register/intraoral/buccal_left.jpg";
import BuccalRightImage from "../../assets/images/patients/register/intraoral/buccal_right.jpg";
import OcclusalLowerImage from "../../assets/images/patients/register/intraoral/occlusal_lower.jpg";
import OcclusalUpper from "../../assets/images/patients/register/intraoral/occlusal_upper.jpg";
import OverjetImage from "../../assets/images/patients/register/intraoral/overjet_front.jpg";
import IntraoralFront from "../../assets/images/patients/register/intraoral/overjet_front2.jpg";
import CephaloImage from "../../assets/images/patients/register/radiation/cephalo.jpg";
import PanoramaImage from "../../assets/images/patients/register/radiation/panorama.jpg";
import { userIsPreferedPlan } from "../../atoms/userAtom";
import ClinicalConfigurationsPopup from "../../components/ClinicalConfigurationsPopup/ClinicalConfigurationsPopup";
import { CardInnerLayout } from "../../components/Layout/CardLayout/CardLayout";
import * as S3 from "../../components/Layout/CardLayout/style/CardLayout.style";
import * as S from "../../components/element/Button/style/ButtonLayout.style";
import { TitleV3 } from "../../components/element/Title/TitleText";
import * as S2 from "../../styles/Common";
import { IK } from "../../utils/i18n/keyStore";

/**처방전 우측 필터 */
const StudyFilter = ({ studyInfo, currentStepIndex, studyStep, isStudy, setCurrentStepIndex }) => {
  const { t } = useTranslation(["translation"]);

  /**필터 열기/닫기 */
  const [isFilterInfoVisible, setIsFilterInfoVisible] = useState(true);

  const handleToggleFilterInfo = () => {
    setIsFilterInfoVisible((prevVisible) => !prevVisible);
  };

  const [extraOral, setExtraOral] = useState({
    isOpen: false,
    currentImage: 0,
    defaultImagesList: [ExtraoralFrontImage, FrontRestImage, FrontSmileImage, Side45Image, Side90Image, Side90SmileImage],
    imagesList: [],
  });

  const [intraOral, setIntraOral] = useState({
    isOpen: false,
    currentImage: 0,
    defaultImagesList: [OcclusalUpper, IntraoralFront, OcclusalLowerImage, BuccalRightImage, OverjetImage, BuccalLeftImage],
    imagesList: [],
  });

  const [radiograph, setRadiograph] = useState({
    isOpen: false,
    currentImage: 0,
    defaultImagesList: [PanoramaImage, CephaloImage],
    imagesList: [],
  });

  useEffect(() => {
    setExtraOral((prev) => ({
      ...prev,
      imagesList: [studyInfo.extraoral_front, studyInfo.front_rest, studyInfo.front_smile, studyInfo.side45, studyInfo.side90, studyInfo.side90_smile],
    }));
    setIntraOral((prev) => ({
      ...prev,
      imagesList: [studyInfo.occlusal_upper, studyInfo.intraoral_front, studyInfo.occlusal_lower, studyInfo.buccal_right, studyInfo.overjet, studyInfo.buccal_left],
    }));
    setRadiograph((prev) => ({
      ...prev,
      imagesList: [studyInfo.panorama, studyInfo.cephalo],
    }));
  }, [studyInfo]);

  const extraoralOpenImageViewer = useCallback((index) => {
    setExtraOral((prev) => ({ ...prev, isOpen: true, currentImage: index }));
  }, []);

  const intraoralOpenImageViewer = useCallback((index) => {
    setIntraOral((prev) => ({ ...prev, isOpen: true, currentImage: index }));
  }, []);

  const radiographOpenImageViewer = useCallback((index) => {
    setRadiograph((prev) => ({ ...prev, isOpen: true, currentImage: index }));
  }, []);

  const closeImageViewer = () => {
    setExtraOral((prev) => ({ ...prev, isOpen: false }));
    setIntraOral((prev) => ({ ...prev, isOpen: false }));
    setRadiograph((prev) => ({ ...prev, isOpen: false }));
  };

  /**임상환경 설정 모달 */
  const [modalOpen, setModalOpen] = useState(false);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  //나이선택, 옵션 선택 이후(2)부터 표출. 컨펌과, 요약에서 제거
  const studyStatus = currentStepIndex < 2 ? false : currentStepIndex < studyStep.length - 2;
  //처방전 치료는 필터x 그외에는 요약 빼고 전부 표출
  const additionalStudytudyStatus = currentStepIndex === 0 ? studyStep[currentStepIndex] !== "UpdateStudy" : currentStepIndex < studyStep.length - 1;
  /**처방전별 필터추가 */
  const filterStatus = isStudy ? studyStatus : additionalStudytudyStatus;

  /**데이터 있으면 갈아 끼우기 */
  const changeImage = (defaultImage, origin) => {
    return defaultImage.map((_, i) => origin[i]?.distFileName || defaultImage[i]);
  };

  /**선호 치료계획 리스트 창 열기 */
  const handleOpenPreferredTreatment = (studyId) => {
    window.open(`/study-plan/${studyId}`, "_blank", "width = 630, height = 560");
  };

  const setIsPlan = useSetRecoilState(userIsPreferedPlan);

  //윈도우 객체에 함수 추가
  /**해당 인덱스로 이동 */
  window.skipPlan = (studyPlanId) => {
    const stepIndex = studyStep.findIndex((step) => step === "SI13");

    setIsPlan(studyPlanId);
    if (stepIndex !== -1) {
      //해당 스텝이 있으면 이동
      setCurrentStepIndex(stepIndex);
    }
  };

  return (
    <>
      {filterStatus && (
        <>
          {isFilterInfoVisible && (
            <>
              <S3.CardColWd $wd300>
                <CardInnerLayout>
                  <S2.ContentScroll $large>
                    {isStudy && (
                      <>
                        {/**본처방전 한정 선호치료계획 사용 */}
                        <TitleV3 title={t(IK.pick_study_title)} />
                        <S.StyledButton as="button" $primary $full type="button" onClick={() => handleOpenPreferredTreatment(studyInfo.studyId)}>
                          {t(IK.pick_study_choice)}
                        </S.StyledButton>
                      </>
                    )}
                    <S2.ContentBox $regular>
                      <TitleV3 title={t(IK.extraoral)} />
                      <S2.FilterList>
                        {changeImage(extraOral.defaultImagesList, extraOral.imagesList).map((src, index) => (
                          <li key={index}>
                            <img src={src} onClick={() => extraoralOpenImageViewer(index)} alt="" />
                          </li>
                        ))}
                      </S2.FilterList>
                      {extraOral.isOpen && (
                        <S2.ImageViewerBox>
                          <ImageViewer
                            src={changeImage(extraOral.defaultImagesList, extraOral.imagesList)}
                            currentIndex={extraOral.currentImage}
                            onClose={closeImageViewer}
                            disableScroll={false}
                            backgroundStyle={{
                              backgroundColor: "rgba(0,0,0,0.6)",
                            }}
                            closeOnClickOutside={true}
                          />
                        </S2.ImageViewerBox>
                      )}
                    </S2.ContentBox>

                    <S2.ContentBox $regular>
                      <TitleV3 title={t(IK.intraoral)} />
                      <S2.FilterList>
                        {changeImage(intraOral.defaultImagesList, intraOral.imagesList).map((src, index) => (
                          <li key={index}>
                            <img src={src} onClick={() => intraoralOpenImageViewer(index)} alt="" />
                          </li>
                        ))}
                      </S2.FilterList>

                      {intraOral.isOpen && (
                        <S2.ImageViewerBox>
                          <ImageViewer
                            src={changeImage(intraOral.defaultImagesList, intraOral.imagesList)}
                            currentIndex={intraOral.currentImage}
                            onClose={closeImageViewer}
                            disableScroll={false}
                            backgroundStyle={{
                              backgroundColor: "rgba(0,0,0,0.6)",
                            }}
                            closeOnClickOutside={true}
                          />
                        </S2.ImageViewerBox>
                      )}
                    </S2.ContentBox>

                    <S2.ContentBox $regular>
                      <TitleV3 title={t(IK.radiograph)} />
                      <S2.FilterList>
                        {changeImage(radiograph.defaultImagesList, radiograph.imagesList).map((src, index) => (
                          <li key={index}>
                            <img src={src} onClick={() => radiographOpenImageViewer(index)} alt="" />
                          </li>
                        ))}
                      </S2.FilterList>
                      {radiograph.isOpen && (
                        <S2.ImageViewerBox>
                          <ImageViewer
                            src={changeImage(radiograph.defaultImagesList, radiograph.imagesList)}
                            currentIndex={radiograph.currentImage}
                            onClose={closeImageViewer}
                            disableScroll={false}
                            backgroundStyle={{
                              backgroundColor: "rgba(0,0,0,0.6)",
                            }}
                            closeOnClickOutside={true}
                          />
                        </S2.ImageViewerBox>
                      )}
                    </S2.ContentBox>

                    <S2.ContentBox $regular>
                      <TitleV3 title={t(IK.clinical_configurations)} />
                      <S.StyledButton as="button" $primary $full type="button" onClick={openModal}>
                        <i className="ri-pencil-line"></i> {t(IK.clinical_configurations)} {t(IK.modify)}
                      </S.StyledButton>
                    </S2.ContentBox>
                  </S2.ContentScroll>
                </CardInnerLayout>
              </S3.CardColWd>
            </>
          )}

          <S2.FilterToggleBtn type="button" onClick={handleToggleFilterInfo}>
            {t(IK.filter_information)}
            {isFilterInfoVisible ? <i className="ri-arrow-right-s-fill"></i> : <i className="ri-arrow-left-s-fill"></i>}
          </S2.FilterToggleBtn>
        </>
      )}

      <ClinicalConfigurationsPopup open={modalOpen} close={closeModal} showCloseBtn header={t(IK.clinical_configurations)} patientId={studyInfo?.patient?.patientId} />
    </>
  );
};

export default StudyFilter;
