import { Field, Form, Formik } from "formik";
import Notiflix from "notiflix";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { resetAdditionalStudy, useUpdateAdditionalStudy } from "../../../../../apis/additionalStudyApi";
import { CardTail, CardTitle } from "../../../../../components/Layout/CardLayout/CardLayout";
import * as S from "../../../../../components/element/Button/style/ButtonLayout.style";
import { FormControlsStudyBox } from "../../../../../components/element/Form/FormLayout";
import { selectAdditionalPackageStep } from "../../../../../utils/additionalStudyUtils";
import { IK } from "../../../../../utils/i18n/keyStore";
import { useAdditionalStudyContext } from "../../../../AdditionalStudy/AdditionalStudyProvider";

function UpdateStudy() {
  const { t } = useTranslation(["translation"]);

  const { additionalInfo, setAdditionalInfo, next, prev, setAdditionalStudyStep, setIsFinish, isFinish, additionalId, patientId, studyId } = useAdditionalStudyContext();
  const { mutate: additionalMutation } = useUpdateAdditionalStudy();

  /**처방전 스텝 설정 로딩 */
  const [submitting, setSubmitting] = useState(false);

  /**데이터 저장 후 다음 스텝 */
  const saveAndContinue = (process, type) => {
    additionalMutation(
      { studyId, patientId, additionalId, process, isSaveStep: !isFinish },
      {
        onSuccess: () => {
          if (type === "update") {
            //프로세스 변경한 경우, 처방전 스텝 다시 설정
            const filteredStep = selectAdditionalPackageStep(additionalInfo.packages, process, additionalInfo.doctor.countryCode);

            setAdditionalStudyStep(filteredStep);

            //처방전 신규 업데이트 (기존 내역 전부 초기화)
            resetAdditionalStudy(additionalId, patientId, studyId).then((res) => {
              const additionalNewData = res?.data;
              setAdditionalInfo((prev) => ({
                ...prev,
                ...additionalNewData,
                process,
              }));
            });
          }
          setSubmitting(false); //처방전 보내기 완료
          next();
        },
        onError: (error) => {
          setSubmitting(false); //처방전 보내기 실패
          Notiflix.Notify.failure(t(IK.again_message));
        },
      }
    );
  };

  /**수정여부 확인 팝업 */
  const showConfirm = (title, message, onOk) => {
    Notiflix.Confirm.show(t(title), t(message), t(IK.confirm), t(IK.cancel), onOk, () => setSubmitting(false));
  };

  /**처방전 업데이트 폼 전송 */
  const handleSubmit = ({ process }) => {
    if (submitting) return; //처방전 보내기 중복 방지
    setSubmitting(true); //처방전 보내기 시작

    //기존 처방전과 다른 값 선택(default: EDIT)
    if (additionalInfo.process !== process) {
      /* NONE 선택시 팝업후 저장 */
      process === "NONE"
        ? showConfirm(IK.none_update_study_1, IK.none_update_study_2, () => saveAndContinue(process, "update"))
        : showConfirm(IK.is_edit, IK.edit_update_seraview_study, () => saveAndContinue(process, "update"));
    } else {
      //처음과 동일하게 선택
      process === "NONE" ? showConfirm(IK.none_update_study_1, IK.none_update_study_2, () => saveAndContinue(process)) : saveAndContinue(process);
    }
  };

  useEffect(() => {
    setIsFinish(false);
  }, [setIsFinish]);

  return (
    <>
      <CardTitle title={additionalInfo.patientName} required />
      <Formik
        initialValues={{
          process: additionalInfo?.process || "EDIT",
        }}
        onSubmit={handleSubmit}
        enableReinitialize={true}
        validateOnMount={true}
      >
        {({ values, handleChange, setFieldValue }) => (
          <Form>
            <FormControlsStudyBox title={`${t(IK.update_seraview_study)}`} required>
              <div className="frm_column">
                <div className="radio">
                  <label className="label_radio">
                    <Field type="radio" name="process" className="input_radio" value="EDIT" />
                    <span>{t(IK.yes)}</span>
                  </label>
                </div>
                <div className="radio">
                  <label className="label_radio">
                    <Field type="radio" name="process" className="input_radio" value="NONE" />
                    <span>{t(IK.no)}</span>
                  </label>
                </div>
              </div>
            </FormControlsStudyBox>
            <CardTail line>
              <S.ButtonLtBox>
                <S.StyledButton as="button" type="button" onClick={prev}>
                  {t(IK.prev)}
                </S.StyledButton>
                <S.StyledButton as="button" $primary type="submit" disabled={submitting}>
                  {t(IK.next)}
                </S.StyledButton>
              </S.ButtonLtBox>
            </CardTail>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default UpdateStudy;
