import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useSaveStep } from "../../../../../apis/studyApi";
import elasticGuide1 from "../../../../../assets/images/elastic/elastic_guide1.jpg";
import elasticGuide2 from "../../../../../assets/images/elastic/elastic_guide2.jpg";
import { CardTail, CardTitle } from "../../../../../components/Layout/CardLayout/CardLayout";
import * as S3 from "../../../../../components/Layout/CardLayout/style/CardLayout.style";
import Modal from "../../../../../components/Modal/Modal";
import * as S2 from "../../../../../components/Modal/style/Modal.style";
import * as S from "../../../../../components/element/Button/style/ButtonLayout.style";
import { FieldMessageErrorClick } from "../../../../../components/element/FieldMessage/FieldMessage";
import { FormControlsBox, FormControlsStudyBox } from "../../../../../components/element/Form/FormLayout";
import * as S4 from "../../../../../components/element/Form/style/Form.style";
import * as S5 from "../../../../../components/element/SelectionControls/style/SelectionControls.style";
import { TitleV4 } from "../../../../../components/element/Title/TitleText";
import * as S6 from "../../../../../styles/Common";
import { IK } from "../../../../../utils/i18n/keyStore";
import ElasticCutInterfaceModal from "../../../../Account/ClinicalConfigurations/ElasticCutInterfaceModal";
import { useStudyContext } from "../../../../Study/StudyProvider";

function AP() {
  const { t } = useTranslation(["translation"]);

  const { studyInfo, setStudyInfo, next, prev, setCurrentStepIndex, isFinish, studyStep } = useStudyContext();
  const { mutate: saveStepMutation, isLoading } = useSaveStep();

  /**요약인지 다음인지 구분 */
  const [skip, setSkip] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  /**프리시젼 컷 모달 추가 */
  const [precisionCutModalOpen, setPrecisionCutModalOpen] = useState(false);
  const openPrecisionCutModal = () => setPrecisionCutModalOpen(true);
  const closePrecisionCutModal = () => setPrecisionCutModalOpen(false);

  /**프리시젼 컷 안내 모달 추가 */
  const [precisionCutGuideModalOpen, setPrecisionCutGuideModalOpen] = useState(false);
  const openPrecisionCutGuideModal = () => setPrecisionCutGuideModalOpen(true);
  const closePrecisionCutGuideModal = () => setPrecisionCutGuideModalOpen(false);

  const validationSchema = Yup.object().shape({
    SI05_02: Yup.string().when(["SI05_05", "SI05_03"], {
      is: (SI05_05, SI05_03) => !SI05_05 && !SI05_03,
      then: (val) => Yup.string().required(t(IK.ap_relation_item)),
    }),
    SI05_03: Yup.string(),
    SI05_04: Yup.array().when(["SI05_02", "SI05_03"], {
      // 두 옵션 중 하나라도 선택되었는지 확인
      is: (SI05_02, SI05_03) => !!(SI05_02 || SI05_03),
      then: (val) => Yup.array().min(1, t(IK.sub_option_required)),
    }),
    SI05_05: Yup.string(),
    SI05_01: studyInfo.SI01 === "3" ? Yup.string() : Yup.string().required(t(IK.ap_required)),
    SI05_01_2_01: Yup.number().when(["SI05_01"], {
      is: (val) => val === "2",
      then: (val) => Yup.number().moreThan(0, t(IK.enter_figure)).required(t(IK.enter_figure)),
    }),
    SI05_01_3_01: Yup.number().when(["SI05_01"], {
      is: (val) => val === "3",
      then: (val) => Yup.number().moreThan(0, t(IK.enter_figure)).required(t(IK.enter_figure)),
    }),
  });

  const handleOnChangeSI0501 = (e, setFieldValue) => {
    const index = e.currentTarget.value;
    if (index !== 2) {
      setFieldValue("SI05_01_2_01", "");
    }
    if (index !== 3) {
      setFieldValue("SI05_01_3_01", "");
    }
  };

  const handlePersistChange = (e, setFieldValue) => {
    if (e.currentTarget.checked) {
      setFieldValue("SI05_02", "");
      setFieldValue("SI05_03", "");
      setFieldValue("SI05_04", "");
    }
  };

  const handleOtherChange = (e, setFieldValue) => {
    if (e.currentTarget.checked) {
      setFieldValue("SI05_05", "");
    }
  };

  const handleSubmit = (values) => {
    const data = {
      SI05_01: values.SI05_01,
      SI05_01_2_01: values.SI05_01 === "2" ? values.SI05_01_2_01 : "",
      SI05_01_3_01: values.SI05_01 === "3" ? values.SI05_01_3_01 : "",
      SI05_02: values.SI05_02,
      SI05_03: values.SI05_03,
      SI05_04: values.SI05_04.length > 0 ? values.SI05_04.join(",") : "",
      SI05_05: values.SI05_05,
    };

    saveStepMutation(
      {
        step: "SI05",
        data,
        params: {
          studyId: studyInfo.studyId,
          patientId: studyInfo.patientId,
          isSaveStep: !isFinish,
        },
      },
      {
        onSuccess: () => {
          setStudyInfo((prev) => ({ ...prev, ...data }));
          isFinish && skip ? setCurrentStepIndex(studyStep.length - 2) : next();
        },
      }
    );
  };

  return (
    <>
      <CardTitle title={studyInfo.patientName} subTitle={`${t(IK[studyInfo.packages])}`} required />

      <Formik
        initialValues={{
          SI05_01: studyInfo.SI05_01 || "",
          SI05_02: studyInfo.SI05_02 || "",
          SI05_03: studyInfo.SI05_03 || "",
          SI05_04: studyInfo.SI05_04 ? studyInfo.SI05_04.split(",") : [],
          SI05_05: studyInfo.SI05_05 || "",
          SI05_01_2_01: studyInfo.SI05_01_2_01 || "",
          SI05_01_3_01: studyInfo.SI05_01_3_01 || "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnMount={true}
      >
        {({ setFieldValue, values }) => (
          <Form>
            <FormControlsStudyBox title={t(IK.ap_relation)} required openModal={openModal}>
              <S4.FormControlsColumn $large>
                {/* ST : 상악 전치 위치 */}
                {(studyInfo.SI01 === "1" || studyInfo.SI01 === "2") && (
                  <FormControlsBox title={t(IK.ap_item1_title)} large>
                    <S5.SelectionItemList>
                      {/* 현재 위치 유지 */}
                      <S5.SelectionItem $radio>
                        <S5.SelectionItemLabel>
                          <Field
                            as={S5.SelectionItemInput}
                            $radio
                            type="radio"
                            name="SI05_01"
                            value="1"
                            onChange={(e) => {
                              handleOnChangeSI0501(e, setFieldValue);
                              setFieldValue("SI05_01", e.currentTarget.value);
                            }}
                          />
                          <S5.SelectionItemSpan>{t(IK.ap_item1_1)}</S5.SelectionItemSpan>
                        </S5.SelectionItemLabel>
                      </S5.SelectionItem>

                      {/* 전치 순측이동 */}

                      <div>
                        <S4.FormControlsFlex>
                          <S5.SelectionItem $radio>
                            <S5.SelectionItemLabel>
                              <Field
                                as={S5.SelectionItemInput}
                                $radio
                                type="radio"
                                name="SI05_01"
                                value="2"
                                onChange={(e) => {
                                  handleOnChangeSI0501(e, setFieldValue);
                                  setFieldValue("SI05_01", e.currentTarget.value);
                                }}
                              />
                              <S5.SelectionItemSpan>{t(IK.ap_item1_2)}</S5.SelectionItemSpan>
                            </S5.SelectionItemLabel>
                          </S5.SelectionItem>
                          {values.SI05_01 === "2" && (
                            <>
                              <Field as={S4.FormLineControls} name="SI05_01_2_01" type="number" />
                              mm
                            </>
                          )}
                        </S4.FormControlsFlex>
                        <FieldMessageErrorClick name="SI05_01_2_01" />
                      </div>

                      {/* 전치 설측이동 */}

                      <div>
                        <S4.FormControlsFlex>
                          <S5.SelectionItem $radio>
                            <S5.SelectionItemLabel>
                              <Field
                                as={S5.SelectionItemInput}
                                $radio
                                type="radio"
                                name="SI05_01"
                                value="3"
                                onChange={(e) => {
                                  handleOnChangeSI0501(e, setFieldValue);
                                  setFieldValue("SI05_01", e.currentTarget.value);
                                }}
                              />
                              <S5.SelectionItemSpan>{t(IK.ap_item1_3)}</S5.SelectionItemSpan>
                            </S5.SelectionItemLabel>
                          </S5.SelectionItem>
                          {values.SI05_01 === "3" && (
                            <>
                              <Field
                                as={S4.FormLineControls}
                                name="SI05_01_3_01"
                                type="number"
                                maxLength={1}
                                onInput={(e) => {
                                  if (e.target.value.length > e.target.maxLength) e.target.value = e.target.value.slice(0, e.target.maxLength);
                                }}
                              />
                              mm
                            </>
                          )}
                        </S4.FormControlsFlex>
                        <FieldMessageErrorClick name="SI05_01_3_01" />
                      </div>
                    </S5.SelectionItemList>
                    <FieldMessageErrorClick name="SI05_01" />
                  </FormControlsBox>
                )}
                {/* EN : 상악 전치 위치 */}

                {/* ST : 현재 교합 관계 유지 */}

                <FormControlsBox title={t(IK.ap_item2_title)} large>
                  {/* 유지 */}

                  <S5.SelectionItem $radio>
                    <S5.SelectionItemLabel>
                      <Field
                        as={S5.SelectionItemInput}
                        $radio
                        type="radio"
                        name="SI05_05"
                        onChange={(e) => {
                          if (e.currentTarget.checked) {
                            handlePersistChange(e, setFieldValue);
                            setFieldValue("SI05_05", "1");
                          } else {
                            setFieldValue("SI05_05", "");
                          }
                        }}
                        value="1"
                        checked={values.SI05_05 === "1" ? true : false}
                      />
                      <S5.SelectionItemSpan>{t(IK.maintain)}</S5.SelectionItemSpan>
                    </S5.SelectionItemLabel>
                  </S5.SelectionItem>
                </FormControlsBox>

                {/* EN : 현재 교합 관계 유지 */}

                {/* ST : 견치 관계만 1급으로 개선 */}
                <FormControlsBox title={t(IK.ap_item3_title)} large>
                  <S5.SelectionItemList>
                    {/* 오른쪽 */}
                    <S5.SelectionItem $radio>
                      <S5.SelectionItemLabel>
                        <Field
                          as={S5.SelectionItemInput}
                          $radio
                          type="radio"
                          name="SI05_02"
                          onClick={(e) => {
                            handleOtherChange(e, setFieldValue);
                            setFieldValue("SI05_02", e.currentTarget.value);
                          }}
                          value="1"
                        />
                        <S5.SelectionItemSpan>{t(IK.right)}</S5.SelectionItemSpan>
                      </S5.SelectionItemLabel>
                    </S5.SelectionItem>

                    {/* 왼쪽 */}
                    <S5.SelectionItem $radio>
                      <S5.SelectionItemLabel>
                        <Field
                          as={S5.SelectionItemInput}
                          $radio
                          type="radio"
                          name="SI05_03"
                          onClick={(e) => {
                            handleOtherChange(e, setFieldValue);
                            setFieldValue("SI05_03", e.currentTarget.value);
                          }}
                          value="1"
                        />
                        <S5.SelectionItemSpan>{t(IK.left)}</S5.SelectionItemSpan>
                      </S5.SelectionItemLabel>
                    </S5.SelectionItem>
                  </S5.SelectionItemList>
                </FormControlsBox>
                {/* EN : 견치 관계만 1급으로 개선 */}

                {/* ST : 견치 및 제1대구치 관계를 1급으로 개선 */}
                <FormControlsBox title={t(IK.ap_item4_title)} large>
                  <S5.SelectionItemList>
                    {/* 오른쪽 */}
                    <S5.SelectionItem $radio>
                      <S5.SelectionItemLabel>
                        <Field
                          as={S5.SelectionItemInput}
                          $radio
                          type="radio"
                          name="SI05_02"
                          onClick={(e) => {
                            handleOtherChange(e, setFieldValue);
                            setFieldValue("SI05_02", e.currentTarget.value);
                          }}
                          value="2"
                        />
                        <S5.SelectionItemSpan>{t(IK.right)}</S5.SelectionItemSpan>
                      </S5.SelectionItemLabel>
                    </S5.SelectionItem>

                    {/* 왼쪽 */}
                    <S5.SelectionItem $radio>
                      <S5.SelectionItemLabel>
                        <Field
                          as={S5.SelectionItemInput}
                          $radio
                          type="radio"
                          name="SI05_03"
                          onClick={(e) => {
                            handleOtherChange(e, setFieldValue);
                            setFieldValue("SI05_03", e.currentTarget.value);
                          }}
                          value="2"
                        />
                        <S5.SelectionItemSpan>{t(IK.left)}</S5.SelectionItemSpan>
                      </S5.SelectionItemLabel>
                    </S5.SelectionItem>
                  </S5.SelectionItemList>
                  <FieldMessageErrorClick name="SI05_02" />
                </FormControlsBox>
                {/* EN : 견치 및 제1대구치 관계를 1급으로 개선 */}

                {/* ST : 치아 이동 옵션 */}
                {values.SI05_02 || values.SI05_03 ? (
                  <div>
                    <TitleV4 title={t(IK.ap_item5_title)} />
                    <S4.FormControlsColumn>
                      {/* 구치부 IPR */}
                      <S5.SelectionItem $checkbox>
                        <S5.SelectionItemLabel>
                          <Field as={S5.SelectionItemInput} $checkbox type="checkbox" name="SI05_04" value="1" />
                          <S5.SelectionItemSpan>{t(IK.posterior)} IPR</S5.SelectionItemSpan>
                        </S5.SelectionItemLabel>
                      </S5.SelectionItem>

                      {/* Class Ⅱ / Ⅲ 교정 시뮬레이션 (Elastic 또는 TADs 사용 필수) */}
                      <S4.FormControlsFlex>
                        <S5.SelectionItem $checkbox>
                          <S5.SelectionItemLabel>
                            <Field as={S5.SelectionItemInput} $checkbox type="checkbox" name="SI05_04" value="2" />
                            <S5.SelectionItemSpan>{t(IK.ap_item5_2)}</S5.SelectionItemSpan>
                          </S5.SelectionItemLabel>
                        </S5.SelectionItem>
                        {/**프리시젼 컷 모달 */}
                        <S.StyledSmallButton type="button" onClick={openPrecisionCutModal}>
                          Elastic cut interface
                        </S.StyledSmallButton>
                        <S.StyledSmallButton type="button" onClick={openPrecisionCutGuideModal}>
                          {t(IK.elastic_guide_txt1)}
                          <i className="ri-question-fill"></i>
                        </S.StyledSmallButton>
                      </S4.FormControlsFlex>

                      {/* 순차적 원심 이동 */}
                      <S5.SelectionItem $checkbox>
                        <S5.SelectionItemLabel>
                          <Field as={S5.SelectionItemInput} $checkbox type="checkbox" name="SI05_04" value="3" />
                          <S5.SelectionItemSpan>{t(IK.ap_item5_3)}</S5.SelectionItemSpan>
                        </S5.SelectionItemLabel>
                      </S5.SelectionItem>

                      {/**4번 Total Arch Distalization은  제거 */}
                      {/* 교합 관계 시뮬레이션(악교정수술) */}
                      <S5.SelectionItem $checkbox>
                        <S5.SelectionItemLabel>
                          <Field as={S5.SelectionItemInput} $checkbox type="checkbox" name="SI05_04" value="5" />
                          <S5.SelectionItemSpan>{t(IK.ap_item5_5)}</S5.SelectionItemSpan>
                        </S5.SelectionItemLabel>
                      </S5.SelectionItem>
                    </S4.FormControlsColumn>
                    <FieldMessageErrorClick name="SI05_04" />
                  </div>
                ) : (
                  ""
                )}
                {/* EN : 치아 이동 옵션 */}
              </S4.FormControlsColumn>
            </FormControlsStudyBox>

            <CardTail error={t(IK.ap_noti)} line study>
              <S3.CardFlex>
                <S.StyledButton $secondary as="button" type="button" onClick={() => setCurrentStepIndex(1)}>
                  {t(IK.select_treatment_options)}
                </S.StyledButton>
                <S.ButtonLtBox>
                  <S.StyledButton as="button" type="button" onClick={prev}>
                    {t(IK.prev)}
                  </S.StyledButton>
                  <S.StyledButton as="button" type="submit" $primary disabled={isLoading}>
                    {t(IK.next)}
                  </S.StyledButton>
                  {isFinish && (
                    <S.StyledButton as="button" type="submit" onClick={() => setSkip(true)} disabled={isLoading} $primary>
                      {t(IK.Skip_to_prescription_summary)}
                    </S.StyledButton>
                  )}
                </S.ButtonLtBox>
              </S3.CardFlex>
            </CardTail>
          </Form>
        )}
      </Formik>

      {/* 교육자료 보기 */}
      {modalOpen && (
        <Modal open={modalOpen} close={closeModal} headernone>
          <S2.ModalBoxBullet>
            <li>{t(IK.ap_pop_message1)}</li>
            <li>{t(IK.ap_pop_message2)}</li>
            <li>{t(IK.ap_pop_message3)}</li>
            <li>{t(IK.ap_pop_message4)}</li>
          </S2.ModalBoxBullet>
        </Modal>
      )}

      {/* 프리시젼 컷 모달 */}
      {precisionCutModalOpen && <ElasticCutInterfaceModal patientId={studyInfo.patientId} open={openPrecisionCutModal} close={closePrecisionCutModal} />}

      {/* 프리시젼 컷 안내 모달 */}
      {precisionCutGuideModalOpen && (
        <Modal open={precisionCutGuideModalOpen} close={closePrecisionCutGuideModal} header={t(IK.elastic_guide_txt1)}>
          <S2.ModalBoxList>
            <S2.ModalBoxItem>
              <S2.ModalBoxBullet>
                <li>{t(IK.elastic_guide_txt2)}</li>
              </S2.ModalBoxBullet>
            </S2.ModalBoxItem>
            <S2.ModalBoxItem>
              <S3.CardFlex>
                <S6.ListGroup>
                  <dt>
                    {t(IK.button_hole)}
                    <p>{t(IK.elastic_guide_txt4)}</p>
                  </dt>
                  <dd>
                    <img src={elasticGuide2} alt="" />
                  </dd>
                </S6.ListGroup>
                <S6.ListGroup>
                  <dt>
                    {t(IK.elastic_cut)}
                    <p>{t(IK.elastic_guide_txt3)}</p>
                  </dt>
                  <dd>
                    <img src={elasticGuide1} alt="" />
                  </dd>
                </S6.ListGroup>
              </S3.CardFlex>
            </S2.ModalBoxItem>
            <S2.ModalBoxItem>
              <S2.ModalBoxBullet>
                <li>{t(IK.elastic_guide_txt5)}</li>
                <li>{t(IK.elastic_guide_txt6)}</li>
              </S2.ModalBoxBullet>
            </S2.ModalBoxItem>
          </S2.ModalBoxList>
        </Modal>
      )}
    </>
  );
}

export default AP;
