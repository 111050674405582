import { useTranslation } from "react-i18next";
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import { usePreventBack } from "../../../../Hooks/usePreventBack";
import { CardTail } from "../../../../components/Layout/CardLayout/CardLayout";
import * as S3 from "../../../../components/Layout/CardLayout/style/CardLayout.style";
import * as S2 from "../../../../components/element/Button/style/ButtonLayout.style";
import { FormConfirm } from "../../../../components/element/Form/FormLayout";
import * as S from "../../../../components/element/Step/style/Step.style";
import { IK } from "../../../../utils/i18n/keyStore";

function Confirm() {
  const { t } = useTranslation(["translation"]);
  const navigate = useNavigate();
  const { patientId } = useParams();
  const { state } = useLocation();

  /* 뒤로가기 방지 */
  usePreventBack();

  /* 비정상적인 접근 방지 */
  if (!state?.isOk) return <Navigate to="/patient" />;

  return (
    <S3.CardRow>
      <S3.CardCol>
        <S.StepType2>
          <ol>
            <li>
              <h3>{t(IK.enter_patient_information)}</h3>
            </li>
            <li>
              <h3>{t(IK.clinical_condition)}</h3>
            </li>
            <li>
              <h3>{t(IK.extraoral)}</h3>
            </li>
            <li>
              <h3>{t(IK.intraoral)}</h3>
            </li>
            <li>
              <h3>{t(IK.radiograph)}</h3>
            </li>
            <li>
              <h3>{t(IK.scan_file_registration)}</h3>
            </li>
            <li className="active">
              <h3>{t(IK.patient_registration_completed)}</h3>
            </li>
          </ol>
        </S.StepType2>

        <S3.CardInner>
          <FormConfirm title={t(IK.patient_registration_completed_message)}>
            <p>{t(IK.prescription_message)}</p>
            <S2.ButtonCtBox>
              <S2.StyledButton as="button" $primary type="button" onClick={() => navigate(`/patient/${patientId}/study`)}>
                {t(IK.prescription_write)}
              </S2.StyledButton>
            </S2.ButtonCtBox>
          </FormConfirm>

          <CardTail line>
            <S2.ButtonLtBox>
              <S2.StyledButton $primary to="/patient/list">
                {t(IK.compelete)}
              </S2.StyledButton>
            </S2.ButtonLtBox>
          </CardTail>
        </S3.CardInner>
      </S3.CardCol>
    </S3.CardRow>
  );
}

export default Confirm;
