import React from "react";
import { useTranslation } from "react-i18next";
import * as S from "../../components/element/Step/style/Step.style";
import { IK } from "../../utils/i18n/keyStore";

/**처방전 스텝 라벨 */
const StudyStepLabel = ({ studyStep, currentStepIndex }) => {
  const { t } = useTranslation(["translation"]);

  //스텝 활성화 표시
  const stepActive = studyStep[currentStepIndex];

  const activeStep = (label) => {
    return label === stepActive ? "active" : "";
  };

  return (
    <>
      {currentStepIndex > 1 && (
        //패키지 선택 이후부터 표출
        <S.StepType2>
          <ol className="progress_index">
            <li className={stepActive.startsWith("SI") ? "active" : ""}>
              <h3>{t(IK.details_treatment_plan)}</h3>
            </li>
            <li className={activeStep("EXTRAORAL")}>
              <h3>{t(IK.extraoral)}</h3>
            </li>
            <li className={activeStep("INTRAORAL")}>
              <h3>{t(IK.intraoral)}</h3>
            </li>
            <li className={activeStep("RADIOGRAPH")}>
              <h3>{t(IK.radiograph)}</h3>
            </li>
            <li className={activeStep("SCAN_FILE")}>
              <h3>{t(IK.scan_file_registration)}</h3>
            </li>
            <li className={activeStep("SUMMARY")}>
              <h3>{t(IK.prescription_summary)}</h3>
            </li>
            <li className={activeStep("CONFIRM")}>
              <h3>{t(IK.checklist)}</h3>
            </li>
          </ol>
        </S.StepType2>
      )}
    </>
  );
};

export default StudyStepLabel;
