import { useState } from "react";
import { useTranslation } from "react-i18next";
import { getStudyDefaultData } from "../../../../../apis/patientApi";
import { useCreateStudy, useUpdateStudy } from "../../../../../apis/studyApi";
import * as S2 from "../../../../../components/element/Button/style/ButtonLayout.style";
import { CardTail, CardTitle } from "../../../../../components/Layout/CardLayout/CardLayout";
import { IK } from "../../../../../utils/i18n/keyStore";
import { selectPackageStep } from "../../../../../utils/studyUtils";
import { useStudyContext } from "../../../../Study/StudyProvider";
import { OptionBtn } from "./OptionBtn";
import { OptionModal } from "./OptionModal";
import * as S from "./style/Option.style";

function SelectPackage() {
  const { t } = useTranslation(["translation"]);
  const { studyInfo, setStudyInfo, next, prev, countryCode, setStudyStep } = useStudyContext();

  const [modalOpen, setModalOpen] = useState(false);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const { mutate: createStudyMutation } = useCreateStudy();
  const { mutate: updateStudyMutation } = useUpdateStudy();

  const handleClick = (e) => {
    const packages = e.currentTarget.id;
    const newStudyInfo = {
      age: studyInfo?.age.toUpperCase(),
      packages,
      patientId: studyInfo?.patientId,
    };

    if (studyInfo.studyId) {
      handleUpdate({ ...newStudyInfo, studyId: studyInfo.studyId });
    } else {
      handleCreate(newStudyInfo);
    }
  };

  /**스터디 업데이트 */
  const handleUpdate = (data) => {
    updateStudyMutation(data, {
      onSuccess: () => {
        //처방전 업데이트
        const newStudyStep = selectPackageStep(data.packages, countryCode);
        setStudyStep(newStudyStep); //악궁 항목 중복처리
        refreshStudyInfo(data.studyId, data);
      },
    });
  };

  /**스터디 생성 */
  const handleCreate = (data) => {
    createStudyMutation(data, {
      onSuccess: (res) => {
        const { studyId } = res;
        const newStudyStep = selectPackageStep(data.packages, countryCode);
        setStudyStep(newStudyStep);
        window.history.pushState("", "", `/patient/${data.patientId}/study/${studyId}`);
        refreshStudyInfo(studyId, data);
      },
    });
  };

  /**스터디 정보 새로 조회 */
  const refreshStudyInfo = (studyId, data) => {
    getStudyDefaultData(studyId, data.patientId).then((res) => {
      setStudyInfo((prev) => ({
        ...prev, //기존 이력
        ...res.data.data, //새로운 스터디 요약 정보
        ...data, //패키지 정보
        age: prev.age, //나이
        studyId, //스터디 아이디 추가
      }));
      next();
    });
  };

  return (
    <>
      <CardTitle title={studyInfo.patientName} subTitle={`${t(IK[studyInfo.packages])}`}>
        <S2.StyledButton as="button" $primary type="button" onClick={openModal}>
          {t(IK.option_guide)}
        </S2.StyledButton>
      </CardTitle>

      <S.OptionList>
        {studyInfo.age === "adult" ? (
          <>
            <OptionBtn BtnId="RP" onHandle={handleClick} type1>
              <dt>
                <h3>R</h3>
                <span>Passive</span>
              </dt>
              <dd>
                <p>Retainer</p>
              </dd>
            </OptionBtn>
            <OptionBtn BtnId="R" onHandle={handleClick} type2>
              <dt>
                <h3>R</h3>
                <span>Active</span>
              </dt>
              <dd>
                <p>{t(IK.up_to_level4)}</p>
              </dd>
            </OptionBtn>
            <OptionBtn BtnId="S10" onHandle={handleClick} type2>
              <dt>
                <h3>10</h3>
              </dt>
              <dd>
                <p>{t(IK.up_to_level10)}</p>
              </dd>
            </OptionBtn>
            <OptionBtn BtnId="S20" onHandle={handleClick} type2>
              <dt>
                <h3>20</h3>
              </dt>
              <dd>
                <p>{t(IK.up_to_level20)}</p>
              </dd>
            </OptionBtn>
            <OptionBtn BtnId="SR" onHandle={handleClick} type2>
              <dt>
                <h3 style={{ fontSize: "24px" }}>{t(IK.regular)}</h3>
              </dt>
              <dd>
                <p>{t(IK.limit_step)}</p>
              </dd>
            </OptionBtn>
            <OptionBtn BtnId="SAP" onHandle={handleClick} type2>
              <dt>
                <h3 style={{ marginTop: "9px", fontSize: "24px" }}>{t(IK.regular)}</h3>
                <span>AP</span>
              </dt>
              <dd>
                <p>{t(IK.limit_step)}</p>
                <span>
                  <i className="ri-information-fill"></i>
                  {t(IK.serafin_option_message)}
                </span>
              </dd>
            </OptionBtn>
          </>
        ) : (
          <>
            <OptionBtn BtnId="Si" onHandle={handleClick} type3>
              <dt>
                <h3>i</h3>
              </dt>
              <dd>
                <p>{t(IK.serafin_i_message)}</p>
              </dd>
            </OptionBtn>
            <OptionBtn BtnId="SiAP" onHandle={handleClick} type3>
              <dt>
                <h3>iAP</h3>
              </dt>
              <dd>
                <p>{t(IK.serafin_iap_message1)}</p>
                <span>
                  <i className="ri-information-fill"></i>
                  {t(IK.serafin_option_message)}
                </span>
              </dd>
            </OptionBtn>
          </>
        )}
      </S.OptionList>

      <CardTail
        error={
          studyInfo?.age === "child" && (
            <>
              {t(IK.bimaxillay)} - {t(IK.device_option_children_txt1)}
              <br />
              {t(IK.maxilla2)} - {t(IK.device_option_children_txt2)}
            </>
          )
        }
        line
      >
        <S2.ButtonLtBox>
          <S2.StyledButton as="button" type="button" onClick={prev}>
            {t(IK.prev)}
          </S2.StyledButton>
        </S2.ButtonLtBox>
      </CardTail>
      <OptionModal modalOpen={modalOpen} closeModal={closeModal} age={studyInfo?.age} />
    </>
  );
}

export default SelectPackage;
