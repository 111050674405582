import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useSaveAdditionStep } from "../../../../../apis/additionalStudyApi";
import { CardTail, CardTitle } from "../../../../../components/Layout/CardLayout/CardLayout";
import Modal from "../../../../../components/Modal/Modal";
import * as S2 from "../../../../../components/Modal/style/Modal.style";
import * as S from "../../../../../components/element/Button/style/ButtonLayout.style";
import { FieldMessageErrorClick } from "../../../../../components/element/FieldMessage/FieldMessage";
import { FormControlsStudyBox } from "../../../../../components/element/Form/FormLayout";
import { IK } from "../../../../../utils/i18n/keyStore";
import { useAdditionalStudyContext } from "../../../../AdditionalStudy/AdditionalStudyProvider";

function Attachment() {
  const { t } = useTranslation(["translation"]);

  const { additionalInfo, setAdditionalInfo, next, prev, setCurrentStepIndex, additionalStudyStep, isFinish, additionalId, patientId, studyId } = useAdditionalStudyContext();
  const { mutate: additionalStudyMutation, isLoading } = useSaveAdditionStep();

  /**요약으로 이동  */
  const [skip, setSkip] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const validationSchema = Yup.object().shape({
    AI04: Yup.string().required(t(IK.attachment_required)),
  });

  const handleSubmit = (data) => {
    additionalStudyMutation(
      {
        step: "AI04",
        data,
        params: {
          additionalId,
          patientId,
          studyId,
          isSaveStep: !isFinish,
        },
      },
      {
        onSuccess: () => {
          setAdditionalInfo((prev) => ({ ...prev, ...data }));
          isFinish && skip ? setCurrentStepIndex(additionalStudyStep.length - 1) : next();
        },
      }
    );
  };
  return (
    <>
      <CardTitle title={additionalInfo.patientName} required />
      <Formik
        initialValues={{
          AI04: additionalInfo.AI04 || "3",
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnMount={true}
      >
        {({ values, handleChange, setFieldValue }) => (
          <Form>
            <FormControlsStudyBox title={t(IK.attachment_select)} required openModal={openModal}>
              <div className="frm_column">
                <div className="radio">
                  <label className="label_radio">
                    <Field type="radio" name="AI04" className="input_radio" value="1" />
                    <span>{t(IK.additional_attachment_item1)}</span>
                  </label>
                </div>
                <div className="radio">
                  <label className="label_radio">
                    <Field type="radio" name="AI04" className="input_radio" value="2" />
                    <span>{t(IK.additional_attachment_item2)}</span>
                  </label>
                </div>
                <div className="radio">
                  <label className="label_radio">
                    <Field type="radio" name="AI04" className="input_radio" value="3" />
                    <span>{t(IK.additional_attachment_item3)}</span>
                  </label>
                </div>
              </div>
              <FieldMessageErrorClick name="AI04" />
            </FormControlsStudyBox>

            <CardTail line>
              <S.ButtonLtBox>
                <S.StyledButton as="button" type="button" onClick={prev}>
                  {t(IK.prev)}
                </S.StyledButton>
                <S.StyledButton as="button" type="submit" disabled={isLoading} $primary>
                  {t(IK.next)}
                </S.StyledButton>
                {isFinish && (
                  <S.StyledButton as="button" type="submit" disabled={isLoading} onClick={() => setSkip(true)} $primary>
                    {t(IK.Skip_to_prescription_summary)}
                  </S.StyledButton>
                )}
              </S.ButtonLtBox>
            </CardTail>
          </Form>
        )}
      </Formik>
      <Modal open={modalOpen} close={closeModal} headernone>
        <S2.ModalBoxBullet>
          <li>{t(IK.attachment_select_pop_message)}</li>
        </S2.ModalBoxBullet>
      </Modal>
    </>
  );
}

export default Attachment;
