import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAdditionalSubmit, useGetAdditionalSummary } from "../../../../../apis/additionalStudyApi";
import { skipStep } from "../../../../../apis/studyApi";
import { CardTail, CardTitle } from "../../../../../components/Layout/CardLayout/CardLayout";
import * as S2 from "../../../../../components/element/Button/style/ButtonLayout.style";
import { AdditionalSummaryTable } from "../../../../../components/element/Summary/AdditionalSummaryTable";
import * as S from "../../../../../components/element/Summary/style/Summary.style";
import LoadingIndicator from "../../../../../components/loading/LoadingIndicator/LoadingIndicator";
import { IK } from "../../../../../utils/i18n/keyStore";
import { useAdditionalStudyContext } from "../../../../AdditionalStudy/AdditionalStudyProvider";

function Summary() {
  const { t } = useTranslation(["translation"]);
  const navigate = useNavigate();

  const { additionalInfo, setAdditionalInfo, prev, setCurrentStepIndex, additionalStudyStep, setIsFinish, additionalId, patientId, studyId } = useAdditionalStudyContext();
  const { mutate: additionalSubmitMuatation, isLoading: isSubmitLoading } = useAdditionalSubmit();

  /**서버에서 데이터 받아와 새로 넣어주기 */
  const { data: additionalStudyData, isLoading } = useGetAdditionalSummary(additionalId, patientId, studyId);
  const additionalStudyPatientData = additionalStudyData?.data;

  const ADDITIONAL_SUMMARY_STEP_MAP = new Set(additionalStudyStep);

  /**스캔파일만 업데이트 */
  useEffect(() => {
    if (additionalStudyPatientData?.scan?.length > 0) {
      setAdditionalInfo((prev) => ({ ...prev, scan: additionalStudyPatientData.scan }));
    }
  }, [additionalStudyPatientData, setAdditionalInfo]);

  /**처방전 요약까지 작성으로 상태 업데이트 */
  useEffect(() => {
    skipStep({ studyId, patientId }).then((res) => {
      setIsFinish(true); //성공시 스킵 버튼 활성화
    });
  }, [studyId, patientId, setIsFinish]);

  /**수정 페이지로 이동 */
  const handleClickForUpdate = (e) => {
    const AI_number = e.currentTarget.dataset.location;
    const stepIndex = additionalStudyStep.findIndex((step) => step === AI_number);

    if (stepIndex !== -1) {
      setCurrentStepIndex(stepIndex);
    }
  };

  /**처방전 제출 */
  const hanldeSubmit = function () {
    additionalSubmitMuatation(
      {
        params: {
          patientId,
          studyId,
          additionalId,
        },
      },
      {
        onSuccess: () => {
          navigate(`/patient/${patientId}/profile`);
        },
        onError: () => {},
      }
    );
  };

  return (
    <>
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          <CardTitle title={additionalInfo.patientName} />
          <S.PrintWrapper>
            <AdditionalSummaryTable additionalInfo={additionalStudyPatientData} additionalSummaryStudyStep={ADDITIONAL_SUMMARY_STEP_MAP} handleClickForUpdate={handleClickForUpdate} />
          </S.PrintWrapper>
          <CardTail line>
            <S2.ButtonLtBox>
              <S2.StyledButton as="button" type="button" onClick={prev}>
                {t(IK.prev)}
              </S2.StyledButton>
              <S2.StyledButton
                as="button"
                $primary
                type="submit"
                disabled={isSubmitLoading}
                onClick={() => {
                  hanldeSubmit();
                }}
              >
                {t(IK.submitted)}
              </S2.StyledButton>
            </S2.ButtonLtBox>
          </CardTail>
        </>
      )}
    </>
  );
}

export default Summary;
