import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useSaveAdditionStep } from "../../../../../apis/additionalStudyApi";
import { CardTail, CardTitle } from "../../../../../components/Layout/CardLayout/CardLayout";
import Modal from "../../../../../components/Modal/Modal";
import * as S2 from "../../../../../components/Modal/style/Modal.style";
import * as S from "../../../../../components/element/Button/style/ButtonLayout.style";
import { FieldMessageErrorClick } from "../../../../../components/element/FieldMessage/FieldMessage";
import { FormControlsStudyBox } from "../../../../../components/element/Form/FormLayout";
import TeethCheck from "../../../../../components/element/TeethTable/TeethCheck";
import { IK } from "../../../../../utils/i18n/keyStore";
import { useAdditionalStudyContext } from "../../../../AdditionalStudy/AdditionalStudyProvider";

function TeethLimitedMobility() {
  const { t } = useTranslation(["translation"]);
  const { additionalInfo, setAdditionalInfo, next, prev, setCurrentStepIndex, additionalStudyStep, isFinish, additionalId, patientId, studyId } = useAdditionalStudyContext();
  const { mutate: additionalStudyMutation, isLoading } = useSaveAdditionStep();
  /**요약으로 이동  */
  const [skip, setSkip] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const validationSchema = Yup.object().shape({
    AI05: Yup.string().required(t(IK.teeth_limited_mobility_required)),
    AI05_2_01: Yup.array().when("AI05", {
      is: (val) => val === "2",
      then: (val) =>
        Yup.array().test({
          test: function (value) {
            const { AI05_2_02 } = this.parent;
            return value.length > 0 || AI05_2_02.length > 0;
          },
          message: t(IK.sub_option_required),
        }),
    }),
    AI05_2_02: Yup.array().when("AI05", {
      is: (val) => val === "2",
      then: (val) =>
        Yup.array().test({
          test: function (value) {
            const { AI05_2_01 } = this.parent;
            return value.length > 0 || AI05_2_01.length > 0;
          },
          message: t(IK.sub_option_required),
        }),
    }),
  });

  const handleSubmit = (values) => {
    const data = {
      AI05: values.AI05,
      AI05_2_01: values.AI05 === "2" ? values.AI05_2_01.join(",") : "",
      AI05_2_02: values.AI05 === "2" ? values.AI05_2_02.join(",") : "",
    };

    additionalStudyMutation(
      {
        step: "AI05",
        data,
        params: {
          additionalId,
          patientId,
          studyId,
          isSaveStep: !isFinish,
        },
      },
      {
        onSuccess: () => {
          setAdditionalInfo((prev) => ({ ...prev, ...data }));
          isFinish && skip ? setCurrentStepIndex(additionalStudyStep.length - 1) : next();
        },
      }
    );
  };

  return (
    <>
      <CardTitle title={additionalInfo.patientName} required />

      <Formik
        initialValues={{
          AI05: additionalInfo?.AI05 || "",
          AI05_2_01: additionalInfo.AI05_2_01 ? additionalInfo.AI05_2_01.split(",") : [],
          AI05_2_02: additionalInfo.AI05_2_02 ? additionalInfo.AI05_2_02.split(",") : [],
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnMount={true}
      >
        {({ values, handleChange, setFieldValue }) => (
          <Form>
            <FormControlsStudyBox title={t(IK.teeth_limited_mobility)} titleSub={t(IK.teeth_limited_mobility_ex)} required openModal={openModal}>
              <div className="frm_column">
                <div className="radio">
                  <label className="label_radio">
                    <Field type="radio" name="AI05" className="input_radio" value="1" />
                    <span>{t(IK.teeth_limited_mobility_item1)}</span>
                  </label>
                </div>
                <div className="radio">
                  <label className="label_radio">
                    <Field type="radio" name="AI05" className="input_radio" value="2" />
                    <span>{t(IK.teeth_limited_mobility_item2)}</span>
                  </label>
                </div>
                <div className="frm_radio_indent">
                  <TeethCheck label="permanent" code="AI05" studyInfo={additionalInfo} handleChange={handleChange} values={values} />
                  <FieldMessageErrorClick name="AI05_2_01" />
                </div>
              </div>
              <FieldMessageErrorClick name="AI05" />
            </FormControlsStudyBox>
            <CardTail line>
              <S.ButtonLtBox>
                <S.StyledButton as="button" type="button" onClick={prev}>
                  {t(IK.prev)}
                </S.StyledButton>
                <S.StyledButton as="button" type="submit" disabled={isLoading} $primary>
                  {t(IK.next)}
                </S.StyledButton>
                {isFinish && (
                  <S.StyledButton as="button" type="submit" disabled={isLoading} onClick={() => setSkip(true)} $primary>
                    {t(IK.Skip_to_prescription_summary)}
                  </S.StyledButton>
                )}
              </S.ButtonLtBox>
            </CardTail>
          </Form>
        )}
      </Formik>

      <Modal open={modalOpen} close={closeModal} headernone>
        <S2.ModalBoxBullet>
          <li>{t(IK.teeth_limited_mobility_pop_message)}</li>
        </S2.ModalBoxBullet>
      </Modal>
    </>
  );
}

export default TeethLimitedMobility;
