import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { useGetStudySummary } from "../../apis/studyApi";
import { CardInnerLayout, CardTail, CardTitle } from "../../components/Layout/CardLayout/CardLayout";
import * as S2 from "../../components/element/Button/style/ButtonLayout.style";
import { SummaryTable } from "../../components/element/Summary/SummaryTable";
import * as S from "../../components/element/Summary/style/Summary.style";
import LoadingIndicator from "../../components/loading/LoadingIndicator/LoadingIndicator";
import { IK } from "../../utils/i18n/keyStore";
import { selectPackageStep } from "../../utils/studyUtils";

/**본처방전 요약 페이지 */
const StudySummaryPage = () => {
  const { patientId, studyId } = useParams();
  const { t } = useTranslation(["translation"]);
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [config, setConfig] = useState(null);

  /**서버에서 데이터 받아와 새로 넣어주기 */
  const { data: studyData, isLoading: isStudyLoading } = useGetStudySummary(patientId, studyId);
  const studyPatientData = studyData?.data;
  const patientData = studyData?.data.patient;

  useEffect(() => {
    if (!isStudyLoading && studyPatientData) {
      setConfig(new Set(selectPackageStep(studyPatientData?.study.packages, studyPatientData?.doctor.countryCode, studyPatientData?.SI01)));
    }
  }, [isStudyLoading, studyPatientData]);

  return (
    <>
      {isStudyLoading || !config ? (
        <LoadingIndicator />
      ) : (
        <CardInnerLayout>
          <CardTitle
            title={
              <>
                {t(IK.prescription_summary)}
                <span>{`${patientData?.firstName} ${patientData?.lastName}${patientData?.korName ? ` (${patientData?.korName})` : ""}, ${t(IK[studyPatientData?.study.packages])}`}</span>
              </>
            }
          />
          <S.PrintWrapper ref={componentRef}>
            <SummaryTable studyInfo={studyPatientData} summaryStepMap={config} />
          </S.PrintWrapper>
          <CardTail line>
            <S2.ButtonLtBox>
              <S2.StyledButton to={`/patient/${patientId}/profile`}>{t(IK.patient_profile)}</S2.StyledButton>
              <S2.StyledButton as="button" $primary type="button" onClick={handlePrint}>
                {t(IK.print)}
              </S2.StyledButton>
            </S2.ButtonLtBox>
          </CardTail>
        </CardInnerLayout>
      )}
    </>
  );
};

export default StudySummaryPage;
