import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useSaveStep } from "../../../../../apis/studyApi";
import { CardTail, CardTitle } from "../../../../../components/Layout/CardLayout/CardLayout";
import * as S3 from "../../../../../components/Layout/CardLayout/style/CardLayout.style";
import Modal from "../../../../../components/Modal/Modal";
import * as S2 from "../../../../../components/Modal/style/Modal.style";
import * as S from "../../../../../components/element/Button/style/ButtonLayout.style";
import { FieldMessageErrorClick } from "../../../../../components/element/FieldMessage/FieldMessage";
import { FormControlsStudyBox } from "../../../../../components/element/Form/FormLayout";
import TeethCheck from "../../../../../components/element/TeethTable/TeethCheck";
import { TitleV4 } from "../../../../../components/element/Title/TitleText";
import { IK } from "../../../../../utils/i18n/keyStore";
import { PERMANENT_TOOTH_ARRAY } from "../../../../../utils/studyUtils";
import { useStudyContext } from "../../../../Study/StudyProvider";

function PermanentTooth() {
  const { t } = useTranslation(["translation"]);

  const { studyInfo, setStudyInfo, next, prev, setCurrentStepIndex, isFinish, studyStep } = useStudyContext();
  const { mutate: saveStepMutation, isLoading } = useSaveStep();

  /**요약인지 다음인지 구분 */
  const [skip, setSkip] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const validationSchema = Yup.object().shape({
    SI14_01: Yup.string().required(t(IK.permanent_tooth_prediction_required)),
    SI14_01_2_01: Yup.array().when(["SI14_01", "SI14_02_2_01", "SI14_02_2_02", "SI14_02_2_03", "SI14_02_2_04"], {
      is: (val0, val1, val2, val3, val4) => val0 === "2" && !val1[0] && !val2[0] && !val3[0] && !val4[0],
      then: (val) => Yup.array().min(1, t(IK.sub_option_required)),
      otherwise: (val) => Yup.array().notRequired(),
    }),
    SI14_03: Yup.string().when(["SI14_01", "SI14_02_2_01", "SI14_02_2_02", "SI14_02_2_03", "SI14_02_2_04"], {
      is: (val0, val1, val2, val3, val4) => val0 === "2" && (!!val1[0] || !!val2[0] || !!val3[0] || !!val4[0]),
      then: (val) => Yup.number().moreThan(0, t(IK.enter_figure)).required(t(IK.enter_figure)),
      otherwise: (val) => Yup.number().notRequired(),
    }),
  });

  const handleSubmit = (values) => {
    const data = {
      SI14_01: values.SI14_01,
      SI14_01_2_01: values.SI14_01 === "2" ? values.SI14_01_2_01.join(",") : "",
      SI14_03: values.SI14_01 === "2" && (values.SI14_02_2_01[0] || values.SI14_02_2_02[0] || values.SI14_02_2_03[0] || values.SI14_02_2_04[0]) ? values.SI14_03 : "",
      SI14_02_2_01: values.SI14_01 === "2" ? values.SI14_02_2_01[0] : "",
      SI14_02_2_02: values.SI14_01 === "2" ? values.SI14_02_2_02[0] : "",
      SI14_02_2_03: values.SI14_01 === "2" ? values.SI14_02_2_03[0] : "",
      SI14_02_2_04: values.SI14_01 === "2" ? values.SI14_02_2_04[0] : "",
    };

    saveStepMutation(
      {
        step: "SI14",
        data,
        params: {
          studyId: studyInfo.studyId,
          patientId: studyInfo.patientId,
          isSaveStep: !isFinish,
        },
      },
      {
        onSuccess: () => {
          setStudyInfo((prev) => ({ ...prev, ...data }));
          isFinish && skip ? setCurrentStepIndex(studyStep.length - 2) : next();
        },
      }
    );
  };

  return (
    <>
      <CardTitle title={studyInfo.patientName} subTitle={`${t(IK[studyInfo.packages])}`} required />

      <Formik
        initialValues={{
          SI14_01: studyInfo.SI14_01 || "",
          SI14_01_2_01: studyInfo.SI14_01_2_01 ? studyInfo.SI14_01_2_01.split(",") : [],
          SI14_02_2_01: studyInfo.SI14_02_2_01 ? [studyInfo.SI14_02_2_01] : [],
          SI14_02_2_02: studyInfo.SI14_02_2_02 ? [studyInfo.SI14_02_2_02] : [],
          SI14_02_2_03: studyInfo.SI14_02_2_03 ? [studyInfo.SI14_02_2_03] : [],
          SI14_02_2_04: studyInfo.SI14_02_2_04 ? [studyInfo.SI14_02_2_04] : [],
          SI14_03: studyInfo.SI14_03 || "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnMount={true}
      >
        {({ setFieldValue, handleChange, values }) => (
          <Form>
            <FormControlsStudyBox title={t(IK.prmanent_tooth)} titleSub={t(IK.prmanent_tooth_ex)} required openModal={openModal}>
              <div className="frm_column v2">
                <div className="frm_div">
                  <TitleV4 title={t(IK.prmanent_tooth_item1_title)} />
                  <div className="frm_column">
                    <div className="radio">
                      <label className="label_radio">
                        <Field type="radio" name="SI14_01" className="input_radio" value="1" />
                        <span>{t(IK.not_applicable)}</span>
                      </label>
                    </div>
                    <div className="radio">
                      <label className="label_radio">
                        <Field type="radio" name="SI14_01" className="input_radio" value="2" />
                        <span>{t(IK.prmanent_tooth_item1_2)}</span>
                      </label>
                    </div>
                    <div className="frm_radio_indent">
                      <div className="frm_column">
                        <TeethCheck label="permanent_predict" code="SI14_01" studyInfo={studyInfo} handleChange={handleChange} values={values} config={PERMANENT_TOOTH_ARRAY} />
                        {values.SI14_01 === "2" && (!!values.SI14_02_2_01[0] || !!values.SI14_02_2_02[0] || !!values.SI14_02_2_03[0] || !!values.SI14_02_2_04[0]) && (
                          <div className="frm_line_field">
                            {t(IK.prmanent_tooth_item2_3)}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <Field name="SI14_03" type="number" />
                          </div>
                        )}
                      </div>
                      <FieldMessageErrorClick name="SI14_01_2_01" />
                      <FieldMessageErrorClick name="SI14_03" />
                    </div>
                  </div>
                  <FieldMessageErrorClick name="SI14_01" />
                </div>
              </div>
            </FormControlsStudyBox>
            <CardTail
              error={
                <>
                  {t(IK.prmanent_tooth_noti1)} <br /> {t(IK.prmanent_tooth_noti2)}
                </>
              }
              line
              study
            >
              <S3.CardFlex>
                <S.StyledButton $secondary as="button" type="button" onClick={() => setCurrentStepIndex(1)}>
                  {t(IK.select_treatment_options)}
                </S.StyledButton>
                <S.ButtonLtBox>
                  <S.StyledButton as="button" type="button" onClick={prev}>
                    {t(IK.prev)}
                  </S.StyledButton>
                  <S.StyledButton as="button" type="submit" $primary disabled={isLoading}>
                    {t(IK.next)}
                  </S.StyledButton>
                  {isFinish && (
                    <S.StyledButton as="button" type="submit" onClick={() => setSkip(true)} disabled={isLoading} $primary>
                      {t(IK.Skip_to_prescription_summary)}
                    </S.StyledButton>
                  )}
                </S.ButtonLtBox>
              </S3.CardFlex>
            </CardTail>
          </Form>
        )}
      </Formik>

      <Modal open={modalOpen} close={closeModal} headernone>
        <S2.ModalBoxBullet>
          <li>{t(IK.prmanent_tooth_pop_message1)}</li>
          <li>{t(IK.prmanent_tooth_pop_message2)}</li>
          <li>{t(IK.prmanent_tooth_pop_message3)}</li>
          <li>{t(IK.prmanent_tooth_pop_message4)}</li>
        </S2.ModalBoxBullet>
      </Modal>
    </>
  );
}

export default PermanentTooth;
