import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetAdditionalStep, useGetAdditionalSummaryForSummary } from "../../apis/additionalStudyApi";
import { useAuthAdminDoctor } from "../../apis/doctorApi";
import { CardInnerLayout } from "../../components/Layout/CardLayout/CardLayout";
import * as S from "../../components/Layout/CardLayout/style/CardLayout.style";
import LoadingIndicator from "../../components/loading/LoadingIndicator/LoadingIndicator";
import { useSessionStorageState } from "../../Hooks/useSessionStorageState";
import { ADDITIONAL_STUDY_STEP_LABEL, selectAdditionalPackageStep } from "../../utils/additionalStudyUtils";
import StudyFilter from "../Study/StudyFilter";
import { AdditionalStudyProvider } from "./AdditionalStudyProvider";
import AdditionalStudyStepLabel from "./AdditionalStudyStepLabel";

const AdditionalStudyPrescription = () => {
  const { additionalId, patientId, studyId } = useParams();
  const navigate = useNavigate();

  /**처방전 작성권한 조회 */
  useAuthAdminDoctor(patientId);

  /**추가 처방전 정보값 저장 */
  const [additionalInfo, setAdditionalInfo] = useState({
    patientName: "",
    age: "",
    packages: "",
    process: "",
  });

  /**처방전 스텝 배열 */
  const [additionalStudyStep, setAdditionalStudyStep] = useState([]);

  /**현재 스텝 순서 */
  const [currentStepIndex, setCurrentStepIndex] = useSessionStorageState("additionalStepIndex", 0);

  /**전체 로딩 관리 */
  const [isLoading, setIsLoading] = useState(true);

  /**요약화면으로 이동 가능한지 검사 */
  const [isFinish, setIsFinish] = useState(false);

  /**추가 처방전 정보 가져오기 */
  const { data: additionalData, isLoading: isAdditionalLoading } = useGetAdditionalSummaryForSummary(additionalId, patientId, studyId);
  const additionalStudyData = additionalData?.data;

  /**추가 처방전 현 스텝 조회 */
  const { data: additionalStepData, isLoading: isAdditionalStepLoading } = useGetAdditionalStep(additionalId);

  useEffect(() => {
    //이어서 작성하기로 들어온 경우
    if (!isAdditionalLoading && !isAdditionalStepLoading) {
      const { firstName, lastName, korName } = additionalStudyData?.patient;
      const { age, packages } = additionalStudyData?.study;
      const { process } = additionalStudyData?.additional;
      const { countryCode } = additionalStudyData?.doctor;

      const isSummaryStep = additionalStudyData.detail === "12" || additionalStudyData.detail === "11";

      //프로세스가 none이면 NOT_EDIT으로 이동 그외는 패키지별 스텝
      const nowAdditionalStudyStep = selectAdditionalPackageStep(packages, process, countryCode);
      const stepIndex = nowAdditionalStudyStep?.findIndex((step) => step === additionalStepData.currentAdditionalStep);

      if (isSummaryStep) {
        setIsFinish(true); //요약도달, 반려 둘다 요약화면으로 이동 가능
      }

      setAdditionalInfo({
        ...additionalStudyData,
        patientName: `${firstName} ${lastName}${!!korName ? ` (${korName})` : ""}`,
        process: process === "none" ? "NONE" : "EDIT",
        age,
        packages,
      });

      setAdditionalStudyStep(nowAdditionalStudyStep); //스텝 선택

      setCurrentStepIndex((prev) => {
        if (prev === -1 || stepIndex === 0) {
          ///못 찾은 경우
          return 0;
        }

        if (isSummaryStep) {
          //요약도달시 매번 요약화면으로 이동
          return nowAdditionalStudyStep.length - 1;
        }

        if (prev > stepIndex) {
          //stepIndex를 초과하면 보정
          return stepIndex + 1;
        }

        return prev === 0 ? stepIndex + 1 : prev;
      });

      setIsLoading(false);
    }
  }, [isAdditionalLoading, isAdditionalStepLoading, additionalStudyData, additionalStepData, setCurrentStepIndex]);

  // 다음 단계로 이동
  const next = () => {
    if (currentStepIndex < additionalStudyStep.length - 1) {
      setCurrentStepIndex(currentStepIndex + 1);
    }
  };

  // 이전 단계로 이동
  const prev = () => {
    if (currentStepIndex > 0) {
      setCurrentStepIndex(currentStepIndex - 1);
    } else {
      navigate(`/patient/${patientId}/profile`);
    }
  };

  return (
    <>
      {isLoading || isAdditionalLoading ? (
        <LoadingIndicator />
      ) : (
        <AdditionalStudyProvider
          value={{
            next,
            prev,
            additionalInfo,
            setAdditionalInfo,
            additionalStudyStep,
            setAdditionalStudyStep,
            currentStepIndex,
            setCurrentStepIndex,
            isFinish,
            setIsFinish,
            additionalId,
            patientId,
            studyId,
          }}
        >
          <AdditionalStudyStepLabel additionalStudyStep={additionalStudyStep} currentStepIndex={currentStepIndex} />
          <S.CardFlex>
            <S.CardColWd $full>
              <CardInnerLayout>{ADDITIONAL_STUDY_STEP_LABEL[additionalStudyStep[currentStepIndex]]}</CardInnerLayout>
            </S.CardColWd>
            {/**추가교정장치 스터디 수정 없음 선택시 사진 파일 없어서 필터 미표출 */}
            {additionalInfo.process !== "NONE" && (
              <StudyFilter studyInfo={additionalInfo} setCurrentStepIndex={setCurrentStepIndex} currentStepIndex={currentStepIndex} studyStep={additionalStudyStep} />
            )}
          </S.CardFlex>
        </AdditionalStudyProvider>
      )}
    </>
  );
};

export default AdditionalStudyPrescription;
