import { Form, Formik } from "formik";
import Notiflix from "notiflix";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import * as Yup from "yup";
import { useSaveAdditionalScan } from "../../../../../apis/additionalStudyApi";
import { userInfo } from "../../../../../atoms/userAtom";
import { CardTail, CardTitle } from "../../../../../components/Layout/CardLayout/CardLayout";
import Modal from "../../../../../components/Modal/Modal";
import * as S from "../../../../../components/Modal/style/Modal.style";
import * as S2 from "../../../../../components/element/Button/style/ButtonLayout.style";
import { FieldMessageErrorClick } from "../../../../../components/element/FieldMessage/FieldMessage";
import { ScanModelModal } from "../../../../../components/element/ScanPreview/ScanModelModal";
import { ScanPreview } from "../../../../../components/element/ScanPreview/ScanPreview";
import { IK } from "../../../../../utils/i18n/keyStore";
import { useAdditionalStudyContext } from "../../../../AdditionalStudy/AdditionalStudyProvider";

function Scan() {
  const { t } = useTranslation(["translation"]);

  const { additionalInfo, setAdditionalInfo, next, prev, setCurrentStepIndex, additionalStudyStep, isFinish, additionalId, patientId, studyId } = useAdditionalStudyContext();
  const { mutate: additionalStudyScanMutation, isLoading } = useSaveAdditionalScan();

  /**사용자 정보 가져오기 */
  const { countryCode } = useRecoilValue(userInfo);

  const [modalOpen, setModalOpen] = useState(false);
  const [emptyModalOpen, setEmptyModalOpen] = useState(false);
  const [deleteStudyFileIds, setDeleteStudyFileIds] = useState([]);

  /**메딧 파일 저장 */
  const [meditScan, setMeditScan] = useState([]);

  const openModal = () => setModalOpen(true);
  const closeModal = () => {
    setModalOpen(false);
    setDeliveryStaus("");
  };
  const openEmptyModal = () => setEmptyModalOpen(true);
  const closeEmptyModal = () => setEmptyModalOpen(false);

  /**요약으로 이동  */
  const [skip, setSkip] = useState(false);

  const validationSchema = Yup.lazy(() => {
    return Yup.object().shape({
      scan: meditScan.length > 0 ? Yup.array() : Yup.array().min(1, t(IK.scan_file_message)),
    });
  });

  /**다음단계로 이동 */
  const navigateNext = () => {
    if (isFinish && skip) {
      setCurrentStepIndex(additionalStudyStep.length - 1);
    } else {
      next();
    }
  };

  const handleSubmit = (values) => {
    const formData = new FormData();

    let formDataLength = 0;
    values.scan.forEach((value) => {
      if (!value.additionalFileId) {
        formData.append("scan", value);
        formDataLength++;
      }
    });

    if (meditScan.length > 0 || formDataLength > 0 || deleteStudyFileIds.length > 0) {
      Notiflix.Loading.standard("");

      if (meditScan.length > 0) {
        formData.append("meditFiles", JSON.stringify(meditScan));
      }

      if (deleteStudyFileIds.length > 0) {
        formData.append("deleteAdditionalFileIds", deleteStudyFileIds.join(","));
      }

      formData.append("studyPackagesCode", additionalInfo.packages);

      additionalStudyScanMutation(
        {
          formData,
          params: {
            additionalId,
            patientId,
            studyId,
            isSaveStep: !isFinish,
            submitTypeCode: "NOW",
          },
        },
        {
          onSuccess: () => {
            setAdditionalInfo((prev) => ({ ...prev, AI40: "NOW" }));
            setDeleteStudyFileIds([]);
            setMeditScan([]);
            Notiflix.Loading.remove();
            navigateNext();
          },
          onError: (res) => {
            Notiflix.Loading.remove();
            Notiflix.Report.failure(t(IK.file_faile), t(IK.file_faile_check), t(IK.confirm));
          },
        }
      );
    } else {
      navigateNext();
    }
  };

  /**택배희망접수 여부 */
  const [deliveryStatus, setDeliveryStaus] = useState({
    isSend: "",
    contact: "",
  });
  const [errorDelivery, setErrorDelivery] = useState("");
  const [errorDeliveryNumber, setErrorDeliveryNumber] = useState("");

  /**석고모델 또는 없음(다음) */
  const handleOnlyChangeTypeCode = (code) => {
    const summaryCode = code === "MODEL" ? "N" : "X";
    const formData = new FormData();
    formData.append("submitTypeCode", code);

    //택배희망접수 여부 체크
    if (code === "MODEL" && deliveryStatus.isSend === "") {
      return setErrorDelivery("address_delivery_check_message");
    }

    //택배희망 연락처 체크
    if (code === "MODEL" && deliveryStatus.isSend === "true" && deliveryStatus.contact === "") {
      return setErrorDeliveryNumber("number_required");
    }

    if (code === "MODEL") {
      formData.append("isDelivery", deliveryStatus.isSend);
      formData.append("phoneNumber", deliveryStatus.contact);
    }

    Notiflix.Loading.standard("");

    additionalStudyScanMutation(
      {
        formData: formData,
        params: {
          studyId,
          patientId,
          additionalId,
          isSaveStep: !isFinish,
        },
      },
      {
        onSuccess: () => {
          setAdditionalInfo((prev) => ({ ...prev, scan: [], AI40: summaryCode }));
          setDeleteStudyFileIds([]);
          setMeditScan([]);
          Notiflix.Loading.remove();
          navigateNext();
        },
        onError: (res) => {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure(t(IK.again_message));
        },
      }
    );
  };

  return (
    <>
      <CardTitle title={additionalInfo.patientName} />
      <Formik
        initialValues={{
          scan: additionalInfo.scan || [],
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
        validateOnMount={true}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <ScanPreview setFieldValue={setFieldValue} setDeleteStudyFileIds={setDeleteStudyFileIds} files={values.scan} meditScan={meditScan} setMeditScan={setMeditScan} />
            {meditScan.length === 0 && <FieldMessageErrorClick name="scan" />}
            <CardTail line>
              <S2.ButtonLtBox>
                <S2.StyledButton as="button" type="button" onClick={() => prev()}>
                  {t(IK.prev)}
                </S2.StyledButton>
                {countryCode === "01" && (
                  <S2.StyledButton as="button" $primary type="button" onClick={openModal}>
                    {t(IK.send_model)}
                  </S2.StyledButton>
                )}
                {additionalInfo.process !== "EDIT" && (
                  <S2.StyledButton as="button" $primary type="button" disabled={isLoading} onClick={openEmptyModal}>
                    {t(IK.empty)}
                  </S2.StyledButton>
                )}
                <S2.StyledButton as="button" $primary type="submit" disabled={isLoading}>
                  {t(IK.next)}
                </S2.StyledButton>
                {isFinish && (
                  <S2.StyledButton as="button" $primary type="submit" disabled={isLoading} onClick={() => setSkip(true)}>
                    {t(IK.Skip_to_prescription_summary)}
                  </S2.StyledButton>
                )}
              </S2.ButtonLtBox>
            </CardTail>
            <ScanModelModal
              modalOpen={modalOpen}
              closeModal={closeModal}
              handlePlasterModelSubmit={() => handleOnlyChangeTypeCode("MODEL")}
              deliveryStatus={deliveryStatus}
              setDeliveryStaus={setDeliveryStaus}
              errorDelivery={errorDelivery}
              setErrorDelivery={setErrorDelivery}
              errorDeliveryNumber={errorDeliveryNumber}
              setErrorDeliveryNumber={setErrorDeliveryNumber}
            />
            <Modal open={emptyModalOpen} close={closeEmptyModal} header={t(IK.caution)}>
              <S.ModalBoxBullet>
                <li>{t(IK.no_scan_no_overlapping_data)}</li>
              </S.ModalBoxBullet>
              <S.ModalBoxBtn>
                <S2.ButtonFlex>
                  <S2.StyledButton as="button" type="button" onClick={closeEmptyModal}>
                    {t(IK.cancel)}
                  </S2.StyledButton>
                  <S2.StyledButton as="button" type="button" $primary onClick={() => handleOnlyChangeTypeCode("NONE")}>
                    {t(IK.next)}
                  </S2.StyledButton>
                </S2.ButtonFlex>
              </S.ModalBoxBtn>
            </Modal>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default Scan;
