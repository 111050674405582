import { Link } from "react-router-dom";
import { css, styled } from "styled-components";

export const ButtonCtBox = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
`;

export const ButtonLtBox = styled.div`
  display: flex;
  gap: 10px;
  margin-left: auto;
`;

export const ButtonFlex = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  gap: 10px;
`;

export const StyledButton = styled(Link)`
  display: inline-flex;
  justify-content: center;
  gap: 5px;
  min-width: ${(props) => (props.$full ? "100%" : "103px")};
  height: 46px;
  line-height: 44px;
  padding: 0 17px;
  border: 1px solid ${(props) => props.theme.colors.gray300};
  border-radius: 5px;
  background-color: ${(props) => props.theme.colors.gray300};
  cursor: pointer;
  color: #7e8299;
  white-space: nowrap;
  font-size: 14px;
  &:hover {
    border-color: ${(props) => props.theme.colors.gray200};
    background-color: ${(props) => props.theme.colors.gray200};
  }

  ${(props) =>
    props.$hide &&
    css`
      display: none;
    `};

  ${(props) =>
    props.$primary &&
    css`
      border-color: ${(props) => props.theme.colors.blue400};
      background-color: ${(props) => props.theme.colors.blue400};
      color: ${(props) => props.theme.colors.white};
      &:hover {
        border-color: #5f97ff;
        background-color: rgba(55, 125, 255, 0.8);
      }
      &:disabled {
        border-color: #5f97ff;
        background-color: rgba(55, 125, 255, 0.7);
        cursor: auto;
        &:hover {
          background-color: rgba(55, 125, 255, 0.7);
        }
      }
    `};

  ${(props) =>
    props.$secondary &&
    css`
      border-color: ${(props) => props.theme.colors.blue200};
      background-color: ${(props) => props.theme.colors.blue200};
      color: ${(props) => props.theme.colors.blue400};
      font-weight: 600;
      &:hover {
        border-color: #dde6f6;
        background-color: #dde6f6;
      }
    `};

  ${(props) =>
    props.$excel &&
    css`
      border-color: #227447;
      background-color: #227447;
      color: ${(props) => props.theme.colors.white};
      &:hover {
        border-color: #4e906c;
        background-color: rgba(34, 116, 71, 0.8);
      }
    `};
`;

export const StyledSmallButton = styled(Link)`
  display: inline-flex;
  gap: 5px;
  padding: 4px 10px;
  border-radius: 5px;
  background-color: ${({ color }) => (color === "delete" ? "rgba(233, 30, 99, 0.1)" : "rgba(55, 125, 255, 0.1)")};
  color: ${({ color, theme }) => (color === "delete" ? theme.colors.red600 : theme.colors.blue400)};
  font-weight: 600;
  white-space: nowrap;
  cursor: pointer;
`;

export const StlyedSquareButton = styled.button`
  width: 46px;
  height: 46px;
  line-height: 46px;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 600;

  ${(props) =>
    props.$add &&
    css`
      color: ${(props) => props.theme.colors.blue400};
      background-color: rgba(55, 125, 255, 0.1);
    `};

  ${(props) =>
    props.$delete &&
    css`
      color: ${(props) => props.theme.colors.red600};
      background-color: rgba(233, 30, 99, 0.1);
    `};

  cursor: pointer;
`;
