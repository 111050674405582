import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useGetMeditInfo } from "../../apis/meditLinkApi";
import { CardInnerLayout, CardTail } from "../../components/Layout/CardLayout/CardLayout";
import * as S2 from "../../components/element/Button/style/ButtonLayout.style";
import { TitleV3 } from "../../components/element/Title/TitleText";
import LoadingIndicator from "../../components/loading/LoadingIndicator/LoadingIndicator";
import * as S3 from "../../styles/Common";
import { IK } from "../../utils/i18n/keyStore";
import * as S from "./style/Medit.style";

const MeditCaseInfo = () => {
  document.getElementById("wrapper").style.minWidth = "630px";
  const { t } = useTranslation(["translation"]);
  const { state } = useLocation();
  const paramId = useParams();
  const nav = useNavigate();

  /**상세 조회 데이터 */
  const { data: MeditInfo, isLoading } = useGetMeditInfo(paramId.uuid);

  const infoFiles = MeditInfo?.data.files;
  const infoData = MeditInfo?.data;

  /**메딧 파일 받기 */
  const getFileUuid = (medits, name) => {
    window.opener
      .getMeditFile(medits, name)
      .then((message) => {
        // 성공적으로 추가된 경우에만 창 닫기
        window.close();
      })
      .catch((error) => {
        console.error(error);
        // 오류 발생 시 창을 닫지 않음
      });
  };

  return (
    <S.MeditBox>
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <CardInnerLayout>
          <S3.ContentBox $regular>
            <TitleV3 title={t(IK.case_information)} />
            <S.MeditInfo>
              <li>
                {t(IK.case_name)} : {infoData?.name}
              </li>
              <li>
                {t(IK.creation_date)} : {infoData?.dateCreated}
              </li>
              <li>
                {t(IK.modification_date)} : {infoData?.dateUpdated}
              </li>
            </S.MeditInfo>
          </S3.ContentBox>

          <S3.ContentBox $regular>
            <TitleV3 title={t(IK.case_file)} />
            <S.MeditInfo>
              {infoFiles?.length > 0 ? (
                infoFiles?.map((file) => (
                  <li key={file.uuid}>
                    <p>{file?.name}</p>
                    <p>file type : {file?.fileType}</p>
                  </li>
                ))
              ) : (
                <li>{t(IK.case_file_empty)}</li>
              )}
            </S.MeditInfo>
          </S3.ContentBox>

          <CardTail line>
            <S2.ButtonLtBox>
              <S2.StyledButton as="button" type="button" onClick={() => nav(`/medit/case-list?page=${state.page}`)}>
                {t(IK.list)}
              </S2.StyledButton>
              {infoFiles?.length > 0 && (
                <S2.StyledButton as="button" $primary type="button" onClick={() => getFileUuid(infoFiles, infoData.name)}>
                  {t(IK.upload)}
                </S2.StyledButton>
              )}
              <S2.StyledButton as="button" $primary type="button" onClick={() => window.close()}>
                {t(IK.close)}
              </S2.StyledButton>
            </S2.ButtonLtBox>
          </CardTail>
        </CardInnerLayout>
      )}
    </S.MeditBox>
  );
};

export default MeditCaseInfo;
