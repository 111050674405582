import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ImageViewer from "react-simple-image-viewer";
import { downloadFile } from "../../../../apis/boardApi";
import loading from "../../../../assets/images/common/loading.gif";
import * as S2 from "../../../../styles/Common";
import * as S from "./Drop.style";

const DragImageBox = ({ file, preview, isRadiation }) => {
  const { t } = useTranslation(["translation"]);
  const [fileData, setFileData] = useState(null);
  const [success, setSuccess] = useState(false);
  const [imageViewer, setImageViewer] = useState({ imgSrc: [], isView: false });

  useEffect(() => {
    if (file) {
      setFileData(file); // 파일 데이터 설정
      setSuccess(true); // 성공 여부 설정
    }
  }, [file]);

  /** 이미지 다운로드 */
  const handleDownload = () => {
    if (!file?.fileId) return;
    downloadFile(file.fileId, file.orgFileName, t);
  };

  /** 이미지 뷰어 열기 */
  const handleImageViewer = () => {
    if (file?.distFileName) {
      setImageViewer({ imgSrc: [file.distFileName], isView: true });
    }
  };

  return (
    <S.DropList className={preview}>
      <S.DropItem $radiation={isRadiation}>
        {success ? (
          <S.DropItemImg>
            <img alt="drop_Img" src={fileData?.distFileName || loading} />
            <S.DropItemControlBtn type="button" onClick={handleDownload} $downloadbtn>
              <i className="ri-download-line"></i>
            </S.DropItemControlBtn>
            <S.DropItemControlBtn type="button" onClick={handleImageViewer} $enlargebtn>
              <i className="ri-fullscreen-line"></i>
            </S.DropItemControlBtn>

            {imageViewer.isView && (
              <S2.ImageViewerBox>
                <ImageViewer
                  src={imageViewer?.imgSrc}
                  currentIndex={0}
                  onClose={() => setImageViewer({ imgSrc: [], isView: false })}
                  disableScroll={false}
                  backgroundStyle={{ backgroundColor: "rgba(0,0,0,0.6)" }}
                  closeOnClickOutside={true}
                />
              </S2.ImageViewerBox>
            )}
          </S.DropItemImg>
        ) : null}
      </S.DropItem>
    </S.DropList>
  );
};

export default DragImageBox;
