import Extraoral from "../pages/Patients/AdditionalStudy/Section/Extraoral/Extraoral";
import Intraoral from "../pages/Patients/AdditionalStudy/Section/Intraoral/Intraoral";
import Radiation from "../pages/Patients/AdditionalStudy/Section/Radiation/Radiation";
import Scan from "../pages/Patients/AdditionalStudy/Section/Scan/Scan";
import Summary from "../pages/Patients/AdditionalStudy/Section/Summary/Summary";
import AdditionalDeviceDeliveryUnit from "../pages/Patients/AdditionalStudy/Section/item/AdditionalDeviceDeliveryUnit";
import ApAttachment from "../pages/Patients/AdditionalStudy/Section/item/ApAttachment";
import Attachment from "../pages/Patients/AdditionalStudy/Section/item/Attachment";
import CurrentDeviceNumber from "../pages/Patients/AdditionalStudy/Section/item/CurrentDeviceNumber";
import Instructions from "../pages/Patients/AdditionalStudy/Section/item/Instructions";
import TeethLimitedMobility from "../pages/Patients/AdditionalStudy/Section/item/TeethLimitedMobility";
import UpdateStudy from "../pages/Patients/AdditionalStudy/Section/item/UpdateStudy";

/**처방전 스텝 라벨 */
export const ADDITIONAL_STUDY_STEP_LABEL = {
  UPDATE_ADDITIONAL: <UpdateStudy />, //세라뷰 치료계획을 수정하시겠습니까?"
  AI02: <CurrentDeviceNumber />, //현재 환자가 착용하고 있는 장치 번호
  AI04: <Attachment />, //어태치먼트
  AI05: <TeethLimitedMobility />, //이동에 제한있는 치아
  AI06: <Instructions />, //특별지시사항
  AI07: <ApAttachment />, //AP 어태치먼트
  AI08: <AdditionalDeviceDeliveryUnit />, //장치배송의 기본 단위
  EXTRAORAL: <Extraoral />, //구외사진
  INTRAORAL: <Intraoral />, //구내사진
  RADIOGRAPH: <Radiation />, //방사선사진
  SCAN_FILE: <Scan />, //스캔파일
  SUMMARY: <Summary />, //처방전 요약
};

/**처방전 패키지 기준으로 스텝을 선택
 * @param {string} packages - 패키지
 * @param {string} process - 프로세스
 * @param {string} countryCode - 국가 코드
 * @returns {array} 선택된 스텝
 */
export const selectAdditionalPackageStep = (packages, process, countryCode) => {
  const skipCountryItems = ["AI08"];

  const steps = process?.toLowerCase() === "none" ? ADDITIONAL_STUDY_PACKAGES_STEP["NOT_EDIT"] : ADDITIONAL_STUDY_PACKAGES_STEP[packages];

  if (countryCode !== "01") {
    //해외 처방전은 장치배송의 기본 단위(SI17) 제거
    return steps.filter((e) => !skipCountryItems.includes(e));
  }

  return steps;
};

/**처방전 패키지 스텝
 * RP는 추가교정장치 없음
 * NOT_EDIT는 UPDATE_ADDITIONAL에서 처방전 수정 없음 선택하는 경우
 */
export const ADDITIONAL_STUDY_PACKAGES_STEP = {
  NOT_EDIT: ["UPDATE_ADDITIONAL", "AI06", "SCAN_FILE", "SUMMARY"],
  R: ["AI02", "AI04", "AI05", "AI06", "EXTRAORAL", "INTRAORAL", "RADIOGRAPH", "SCAN_FILE", "SUMMARY"],
  RP: [], //추가교정장치 없음
  S10: ["AI02", "AI04", "AI05", "AI06", "EXTRAORAL", "INTRAORAL", "RADIOGRAPH", "SCAN_FILE", "SUMMARY"],
  S20: ["AI02", "AI04", "AI05", "AI06", "EXTRAORAL", "INTRAORAL", "RADIOGRAPH", "SCAN_FILE", "SUMMARY"],
  SR: ["UPDATE_ADDITIONAL", "AI02", "AI04", "AI05", "AI06", "AI08", "EXTRAORAL", "INTRAORAL", "RADIOGRAPH", "SCAN_FILE", "SUMMARY"],
  SAP: ["UPDATE_ADDITIONAL", "AI02", "AI04", "AI05", "AI06", "AI08", "EXTRAORAL", "INTRAORAL", "RADIOGRAPH", "SCAN_FILE", "SUMMARY"],
  Si: ["UPDATE_ADDITIONAL", "AI02", "AI04", "AI05", "AI06", "EXTRAORAL", "INTRAORAL", "RADIOGRAPH", "SCAN_FILE", "SUMMARY"],
  SiAP: ["UPDATE_ADDITIONAL", "AI02", "AI04", "AI07", "AI05", "AI06", "EXTRAORAL", "INTRAORAL", "RADIOGRAPH", "SCAN_FILE", "SUMMARY"],
};
