import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useSaveStep } from "../../../../../apis/studyApi";
import { CardTail, CardTitle } from "../../../../../components/Layout/CardLayout/CardLayout";
import * as S3 from "../../../../../components/Layout/CardLayout/style/CardLayout.style";
import Modal from "../../../../../components/Modal/Modal";
import * as S2 from "../../../../../components/Modal/style/Modal.style";
import * as S from "../../../../../components/element/Button/style/ButtonLayout.style";
import { FieldMessageErrorClick } from "../../../../../components/element/FieldMessage/FieldMessage";
import { FormControlsStudyBox } from "../../../../../components/element/Form/FormLayout";
import { IK } from "../../../../../utils/i18n/keyStore";
import { useStudyContext } from "../../../../Study/StudyProvider";

function Crossbite() {
  const { t } = useTranslation(["translation"]);

  const { studyInfo, setStudyInfo, next, prev, setCurrentStepIndex, isFinish, studyStep } = useStudyContext();
  const { mutate: saveStepMutation, isLoading } = useSaveStep();

  /**요약인지 다음인지 구분 */
  const [skip, setSkip] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const validationSchema = Yup.object().shape({
    SI11: Yup.string().required(t(IK.crossbite_required)),
  });

  const handleSubmit = (data) => {
    saveStepMutation(
      {
        step: "SI11",
        data,
        params: {
          studyId: studyInfo.studyId,
          patientId: studyInfo.patientId,
          isSaveStep: !isFinish,
        },
      },
      {
        onSuccess: () => {
          setStudyInfo((prev) => ({ ...prev, ...data }));
          isFinish && skip ? setCurrentStepIndex(studyStep.length - 2) : next();
        },
      }
    );
  };

  return (
    <>
      <CardTitle title={studyInfo.patientName} subTitle={`${t(IK[studyInfo.packages])}`} required />

      <Formik
        initialValues={{
          SI11: studyInfo.SI11 || "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnMount={true}
      >
        <Form>
          <FormControlsStudyBox title={t(IK.posterior_crossbite)} required openModal={openModal}>
            <div className="frm_column">
              <div className="frm_div">
                <div className="radio">
                  <label className="label_radio">
                    <Field type="radio" name="SI11" className="input_radio" value="1" />
                    <span>{t(IK.crossbite_item1)}</span>
                  </label>
                </div>
              </div>
              <div className="frm_div">
                <div className="radio">
                  <label className="label_radio">
                    <Field type="radio" name="SI11" className="input_radio" value="2" />
                    <span>{t(IK.crossbite_item2)}</span>
                  </label>
                </div>
              </div>
            </div>
            <FieldMessageErrorClick name="SI11" />
          </FormControlsStudyBox>

          <CardTail line study>
            <S3.CardFlex>
              <S.StyledButton $secondary as="button" type="button" onClick={() => setCurrentStepIndex(1)}>
                {t(IK.select_treatment_options)}
              </S.StyledButton>
              <S.ButtonLtBox>
                <S.StyledButton as="button" type="button" onClick={prev}>
                  {t(IK.prev)}
                </S.StyledButton>
                <S.StyledButton as="button" $primary type="submit" disabled={isLoading}>
                  {t(IK.next)}
                </S.StyledButton>
                {isFinish && (
                  <S.StyledButton as="button" $primary type="submit" onClick={() => setSkip(true)} disabled={isLoading}>
                    {t(IK.Skip_to_prescription_summary)}
                  </S.StyledButton>
                )}
              </S.ButtonLtBox>
            </S3.CardFlex>
          </CardTail>
        </Form>
      </Formik>
      <Modal open={modalOpen} close={closeModal} headernone>
        <S2.ModalBoxBullet>
          <li>{t(IK.crossbite_pop_message1)}</li>
        </S2.ModalBoxBullet>
      </Modal>
    </>
  );
}

export default Crossbite;
