import Notiflix from "notiflix";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { skipStep, useDeleteStudyItem, useGetStudySummary } from "../../../../../apis/studyApi";
import { CardTail, CardTitle } from "../../../../../components/Layout/CardLayout/CardLayout";
import * as S2 from "../../../../../components/element/Button/style/ButtonLayout.style";
import { SummaryTable } from "../../../../../components/element/Summary/SummaryTable";
import * as S from "../../../../../components/element/Summary/style/Summary.style";
import LoadingIndicator from "../../../../../components/loading/LoadingIndicator/LoadingIndicator";
import { IK } from "../../../../../utils/i18n/keyStore";
import { useStudyContext } from "../../../../Study/StudyProvider";

function Summary() {
  const { t } = useTranslation(["translation"]);
  const { patientId } = useParams();

  const { studyInfo, setStudyInfo, next, prev, setCurrentStepIndex, studyStep, setIsFinish } = useStudyContext();

  /**서버에서 데이터 받아와 새로 넣어주기 */
  const { data: studyData, isLoading: isStudyLoading } = useGetStudySummary(patientId, studyInfo.studyId);
  const studyPatientData = studyData?.data;

  const SUMMARY_STEP_MAP = new Set(studyStep);

  /**스캔파일만 업데이트 */
  useEffect(() => {
    if (studyPatientData?.scan?.length > 0) {
      setStudyInfo((prev) => ({ ...prev, scan: studyPatientData.scan }));
    }
  }, [studyPatientData, setStudyInfo]);

  /**처방전 요약까지 작성으로 상태 업데이트 */
  useEffect(() => {
    skipStep({ studyId: studyInfo.studyId, patientId: studyInfo.patientId }).then(() => {
      setIsFinish(true); //성공시 스킵 버튼 활성화
    });
  }, [studyInfo.studyId, studyInfo.patientId, setIsFinish]);

  /**없어진 처방전 항목 제거 처리 */
  const { mutate: deleteItem } = useDeleteStudyItem();

  /**수정하러 이동하기 */
  const handleClickForUpdate = (e) => {
    const SI_number = e.currentTarget.dataset.location;
    const stepIndex = studyStep.findIndex((step) => step === SI_number);

    if (stepIndex === -1)
      //존재하지 않는 경우
      return Notiflix.Confirm.show(
        t(IK.summary_error_1),
        t(IK.summary_error_2),
        t(IK.confirm),
        t(IK.cancel),
        () => deleteItem({ studyId: studyInfo.studyId, studyItem: SI_number }),
        () => {}
      );

    if (stepIndex === 2) {
      //악궁을 수정하는 경우
      return Notiflix.Confirm.show(
        t(IK.is_edit),
        t(IK.edit_dental_arch_treated),
        t(IK.confirm),
        t(IK.cancel),
        () => setCurrentStepIndex(stepIndex),
        () => {}
      );
    }

    //기본 수정
    setCurrentStepIndex(stepIndex);
  };

  return (
    <>
      {isStudyLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          <CardTitle
            title={
              <>
                {studyInfo.patientName}
                <span>({`${t(IK[studyInfo.packages])}`})</span>
              </>
            }
          />
          <S.PrintWrapper>
            <SummaryTable studyInfo={studyPatientData} summaryStepMap={SUMMARY_STEP_MAP} handleClickForUpdate={handleClickForUpdate} />
          </S.PrintWrapper>
          <CardTail line>
            <S2.ButtonLtBox>
              <S2.StyledButton as="button" type="button" onClick={prev}>
                {t(IK.prev)}
              </S2.StyledButton>
              <S2.StyledButton as="button" $primary type="submit" onClick={next}>
                {t(IK.next)}
              </S2.StyledButton>
            </S2.ButtonLtBox>
          </CardTail>
        </>
      )}
    </>
  );
}

export default Summary;
