import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useSaveStep } from "../../../../../apis/studyApi";
import { CardTail, CardTitle } from "../../../../../components/Layout/CardLayout/CardLayout";
import * as S3 from "../../../../../components/Layout/CardLayout/style/CardLayout.style";
import Modal from "../../../../../components/Modal/Modal";
import * as S2 from "../../../../../components/Modal/style/Modal.style";
import * as S from "../../../../../components/element/Button/style/ButtonLayout.style";
import { FieldMessageErrorClick } from "../../../../../components/element/FieldMessage/FieldMessage";
import { FormControlsStudyBox } from "../../../../../components/element/Form/FormLayout";
import { IK } from "../../../../../utils/i18n/keyStore";
import { removeSIKeys } from "../../../../../utils/objectUtils";
import { removeBiteRamp, STUDY_FORM_CONFIG } from "../../../../../utils/studyUtils";
import { useStudyContext } from "../../../../Study/StudyProvider";

function DentalArchTreated() {
  const { t } = useTranslation(["translation"]);

  const { studyInfo, setStudyInfo, next, prev, studyStep, currentStepIndex, setCurrentStepIndex, setStudyStep, setIsFinish } = useStudyContext();

  const [modalOpen, setModalOpen] = useState(false);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const { mutate: saveStepMutation, isLoading } = useSaveStep();
  const SI01_CONFIG = STUDY_FORM_CONFIG[studyInfo.packages][studyStep[currentStepIndex]];

  const schema = {
    SI01: Yup.string().required(t(IK.dental_arch_treated_required)),
  };

  if (SI01_CONFIG.SI01_2_1 || SI01_CONFIG.SI01_2_2) {
    schema.SI01_2_01 = Yup.string().when("SI01", {
      is: (val) => val === "2",
      then: (val) => Yup.string().required(t(IK.sub_option_required)),
    });
  }

  if (SI01_CONFIG.SI01_3_1 || SI01_CONFIG.SI01_3_2) {
    schema.SI01_3_01 = Yup.string().when("SI01", {
      is: (val) => val === "3",
      then: (val) => Yup.string().required(t(IK.sub_option_required)),
    });
  }

  const validationSchema = Yup.object().shape(schema);

  const handleChange = (e) => {
    const index = e.currentTarget.value;
    if (index === "1" && document.getElementById("sub_2") && document.getElementById("sub_3")) {
      document.getElementById("sub_2").classList.add("hide");
      document.getElementById("sub_3").classList.add("hide");
    } else if (index === "2" && document.getElementById("sub_2") && document.getElementById("sub_3")) {
      document.getElementById("sub_2").classList.remove("hide");
      document.getElementById("sub_3").classList.add("hide");
    } else if (index === "3" && document.getElementById("sub_2") && document.getElementById("sub_3")) {
      document.getElementById("sub_2").classList.add("hide");
      document.getElementById("sub_3").classList.remove("hide");
    }
  };

  const submit = (values) => {
    const data = {
      SI01: values.SI01,
      SI01_2_01: values.SI01 === "2" ? values.SI01_2_01 : "",
      SI01_3_01: values.SI01 === "3" ? values.SI01_3_01 : "",
    };

    saveStepMutation(
      {
        step: "SI01",
        data,
        params: {
          studyId: studyInfo.studyId,
          patientId: studyInfo.patientId,
          isSaveStep: true, //악궁선택시 이어서 작성하기 스텝 저장
        },
      },
      {
        onSuccess: () => {
          //악궁선택에 따른 조건 처리
          setStudyStep((prev) => removeBiteRamp(prev, data.SI01));
          //SI가 들어간 값은 다 지움 => 악궁선택시 기존 값 초기화
          setStudyInfo({ ...removeSIKeys(studyInfo), ...data });
          next();
        },
      }
    );
  };

  useEffect(() => {
    setIsFinish(false);
  }, [setIsFinish]);

  return (
    <>
      <CardTitle title={studyInfo.patientName} subTitle={`${t(IK[studyInfo.packages])}`} required />

      <Formik
        initialValues={{
          SI01: studyInfo?.SI01 || "",
          SI01_2_01: studyInfo?.SI01_2_01 || "",
          SI01_3_01: studyInfo?.SI01_3_01 || "",
        }}
        validationSchema={validationSchema}
        onSubmit={submit}
        validateOnMount={true}
      >
        {({ setFieldValue }) => (
          <Form>
            <FormControlsStudyBox title={t(IK.dental_arch_treated)} required openModal={openModal}>
              <div className="frm_column">
                {SI01_CONFIG.SI01_1 && (
                  <div className="radio">
                    <label className="label_radio">
                      <Field
                        type="radio"
                        name="SI01"
                        onChange={(e) => {
                          handleChange(e);
                          setFieldValue("SI01", e.currentTarget.value);
                        }}
                        className="input_radio"
                        value="1"
                      />
                      <span>{t(IK.bimaxillay)}</span>
                    </label>
                  </div>
                )}
                {SI01_CONFIG.SI01_2 && (
                  <div className="radio">
                    <label className="label_radio">
                      <Field
                        type="radio"
                        name="SI01"
                        onChange={(e) => {
                          handleChange(e);
                          setFieldValue("SI01", e.currentTarget.value);
                        }}
                        className="input_radio"
                        value="2"
                      />
                      <span>{t(IK.maxilla)}</span>
                    </label>
                  </div>
                )}
                {(SI01_CONFIG.SI01_2_1 || SI01_CONFIG.SI01_2_2) && (
                  <>
                    <div id="sub_2" className={studyInfo.SI01 === "2" ? "" : "hide"}>
                      <div className="chk_area frm_radio_indent">
                        {SI01_CONFIG.SI01_2_1 && (
                          <div className="radio">
                            <label className="label_radio">
                              <Field type="radio" name="SI01_2_01" className="input_radio" value="1" />
                              <span>{t(IK.dental_arch_treated_item2_1)}</span>
                            </label>
                          </div>
                        )}
                        {SI01_CONFIG.SI01_2_2 && (
                          <div className="radio">
                            <label className="label_radio">
                              <Field type="radio" name="SI01_2_01" className="input_radio" value="2" />
                              <span>{t(IK.dental_arch_treated_item2_2)}</span>
                            </label>
                          </div>
                        )}
                      </div>
                    </div>
                    <FieldMessageErrorClick name="SI01_2_01" />
                  </>
                )}
                {SI01_CONFIG.SI01_3 && (
                  <div className="radio">
                    <label className="label_radio">
                      <Field
                        type="radio"
                        name="SI01"
                        onChange={(e) => {
                          handleChange(e);
                          setFieldValue("SI01", e.currentTarget.value);
                        }}
                        className="input_radio"
                        value="3"
                      />
                      <span>{t(IK.mandible)}</span>
                    </label>
                  </div>
                )}
                {(SI01_CONFIG.SI01_3_1 || SI01_CONFIG.SI01_3_2) && (
                  <>
                    <div id="sub_3" className={studyInfo.SI01 === "3" ? "" : "hide"}>
                      <div className="chk_area frm_radio_indent">
                        {SI01_CONFIG.SI01_3_1 && (
                          <div className="radio">
                            <label className="label_radio">
                              <Field type="radio" name="SI01_3_01" className="input_radio" value="1" />
                              <span>{t(IK.dental_arch_treated_item3_1)}</span>
                            </label>
                          </div>
                        )}
                        {SI01_CONFIG.SI01_3_2 && (
                          <div className="radio">
                            <label className="label_radio">
                              <Field type="radio" name="SI01_3_01" className="input_radio" value="2" />
                              <span>{t(IK.dental_arch_treated_item3_2)}</span>
                            </label>
                          </div>
                        )}
                      </div>
                    </div>
                    <FieldMessageErrorClick name="SI01_3_01" />
                  </>
                )}
                <FieldMessageErrorClick name="SI01" />
              </div>
            </FormControlsStudyBox>

            <CardTail line study>
              <S3.CardFlex>
                <S.StyledButton $secondary as="button" type="button" onClick={() => setCurrentStepIndex(1)}>
                  {t(IK.select_treatment_options)}
                </S.StyledButton>
                <S.ButtonLtBox>
                  <S.StyledButton as="button" type="button" onClick={prev}>
                    {t(IK.prev)}
                  </S.StyledButton>
                  <S.StyledButton as="button" type="submit" $primary disabled={isLoading}>
                    {t(IK.next)}
                  </S.StyledButton>
                </S.ButtonLtBox>
              </S3.CardFlex>
            </CardTail>
          </Form>
        )}
      </Formik>

      <Modal open={modalOpen} close={closeModal} headernone>
        <S2.ModalBoxBullet>
          <li>{t(IK.dental_arch_treated_pop_message1)}</li>
          <li>{t(IK.dental_arch_treated_pop_message2)}</li>
        </S2.ModalBoxBullet>
      </Modal>
    </>
  );
}

export default DentalArchTreated;
