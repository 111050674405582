import { BrowserRouter, Route, Routes } from "react-router-dom";
import { IsToken } from "../components/IsToken";
import Layout from "../components/Layout/Layout";
import BoardView from "../components/element/BoardView/BoardView";
import isKoreaAccount from "../hoc/isKoreaAccount";
import AddressManage from "../pages/Account/AddressManage/AddressManage";
import BillingDone from "../pages/Account/Billing/BillingDone";
import BillingList from "../pages/Account/Billing/List";
import BillingPayment from "../pages/Account/Billing/Payment";
import ClinicalConfigurations from "../pages/Account/ClinicalConfigurations/ClinicalConfigurations";
import DentistInfo from "../pages/Account/DentistInfo/DentistInfo";
import DoctorInfo from "../pages/Account/DoctorInfo/DoctorInfo";
import EtcInfo from "../pages/Account/EtcInfo/EtcInfo";
import Grade from "../pages/Account/Grade/Grade";
import AdditionalStudyPrescription from "../pages/AdditionalStudy/AdditionalStudyPrescription";
import AdditionalSummaryPage from "../pages/AdditionalStudy/AdditionalSummaryPage";
import AdminLogin from "../pages/AdminLogin";
import CertifiedTraining from "../pages/Education/CertifiedTraining/CertifiedTraining";
import ClinicalCasesList from "../pages/Education/ClinicalCases/List";
import ClinicalCasesView from "../pages/Education/ClinicalCases/View";
import CorrectionImageList from "../pages/Education/CorrectionImage/List";
import EducationalMaterialsList from "../pages/Education/EducationalMaterials/List";
import LibraryList from "../pages/Education/Library/List";
import SampleSeraviewList from "../pages/Education/SampleSeraview/List";
import FindIdForm from "../pages/FindIdPw/FindIdForm";
import FindPw from "../pages/FindIdPw/FindPw";
import Join from "../pages/Join/Join";
import Login from "../pages/Login/Login";
import MeditCallBack from "../pages/Medit/MeditCallBack";
import MeditCaseInfo from "../pages/Medit/MeditCaseInfo";
import MeditCaseList from "../pages/Medit/MeditCaseList";
import PatientsList from "../pages/Patients/List/List";
import Profile from "../pages/Patients/Profile/Profile";
import ClinicalCasesRegister from "../pages/Patients/Profile/section/ClinicalCases/Register";
import Confirm from "../pages/Patients/Register/Confirm/Confirm";
import Register from "../pages/Patients/Register/Register";
import Replace from "../pages/Patients/ReplaceStudy/Replace";
import ReplacementSummary from "../pages/Patients/ReplaceStudy/ReplacementSummary";
import SeraquickLoading from "../pages/Patients/Seraquick/SeraquickLoading";
import ClinicalConditionEdit from "../pages/Patients/Update/ClinicalCondition/ClinicalConditionEdit";
import ExtraoralEdit from "../pages/Patients/Update/Extraoral/ExtraoralEdit";
import IntraoralEdit from "../pages/Patients/Update/Intraoral/IntraoralEdit";
import RadiationEdit from "../pages/Patients/Update/Radiation/RadiationEdit";
import RegisterFormEdit from "../pages/Patients/Update/RegisterForm/RegisterFormEdit";
import ScanEdit from "../pages/Patients/Update/Scan/ScanEdit";
import PreferStudyDetail from "../pages/PreferredStudy/PreferStudyDetail";
import PreferStudyPlan from "../pages/PreferredStudy/PreferStudyPlan";
import StudyPrescription from "../pages/Study/StudyPrescription";
import StudySummaryPage from "../pages/Study/StudySummaryPage";
import EventInfo from "../pages/Support/Event/EventInfo";
import EventList from "../pages/Support/Event/List";
import Faq from "../pages/Support/Faq/Faq";
import NoticeList from "../pages/Support/Notice/NoticeList";
import NotFound from "../pages/error/404";
import i18n from "../utils/i18n";

const Router = () => {
  /* i18n 실행 확인용 지우지말 것 */
  i18n.getDataByLanguage("en");

  const BillingIsKorea = isKoreaAccount(BillingList);
  const BillingPaymentIsKorea = isKoreaAccount(BillingPayment);
  const BillingDoneIsKorea = isKoreaAccount(BillingDone);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route element={<Layout />}>
          {/* patient */}
          <Route path="/patient" element={<PatientsList />} /> {/* 환자 리스트 */}
          <Route path="/patient/list" element={<PatientsList />} /> {/* 환자 리스트 */}
          <Route path="/patient/register" element={<Register />} /> {/* 환자 등록 */}
          <Route path="/patient/:patientId/confirm" element={<Confirm />} /> {/* 환자 등록 완료 */}
          <Route path="/patient/:patientId/profile" element={<Profile />} /> {/* 환자 프로필 */}
          <Route path="/patient/:patientId/update" element={<RegisterFormEdit />} /> {/* 환자 수정 */}
          <Route path="/patient/:patientId/update/clinic-condition" element={<ClinicalConditionEdit />} /> {/* 환자 임상상태 수정 */}
          <Route path="/patient/:patientId/update/extraoral" element={<ExtraoralEdit />} /> {/* 환자 extraoral 수정 */}
          <Route path="/patient/:patientId/update/intraoral" element={<IntraoralEdit />} /> {/* 환자 intraoral 수정 */}
          <Route path="/patient/:patientId/update/raditaion" element={<RadiationEdit />} /> {/* 환자 radiation 수정 */}
          <Route path="/patient/:patientId/update/scan" element={<ScanEdit />} /> {/* 환자 scan 수정 */}
          <Route path="/patient/:patientId/study/:studyId?" element={<StudyPrescription />} /> {/* 본장치 */}
          <Route path="/patient/:patientId/study/:studyId/summary" element={<StudySummaryPage />} /> {/* 본장치 요약 */}
          <Route path="/patient/:patientId/study/:studyId/additional/:additionalId" element={<AdditionalStudyPrescription />} /> {/* 추가교정장치 */}
          <Route path="/patient/:patientId/study/:studyId/summary/:additionalId" element={<AdditionalSummaryPage />} /> {/* 추가교정장치 요약 */}
          <Route path="/patient/:patientId/study/:studyId/clinical-cases-register" element={<ClinicalCasesRegister />} /> {/* 임상증례 제출 등록*/}
          <Route path="/patient/:patientId/study/:studyId/replace/:replacementId" element={<Replace />} /> {/* 대체교정장치*/}
          <Route path="/patient/:patientId/study/:studyId/summary/replace/:replacementId" element={<ReplacementSummary />} /> {/* 대체교정장치 요약*/}
          {/* account */}
          <Route path="/account" element={<DentistInfo />} /> {/*치과정보*/}
          <Route path="/account/mypage" element={<DentistInfo />} /> {/*치과정보*/}
          <Route path="/account/billing-list" element={<BillingIsKorea />} /> {/* 지불 리스트 */}
          <Route path="/account/billing-list/payment/:billingId" element={<BillingPaymentIsKorea />} /> {/* 지불 상세 */}
          <Route path="/account/billing-list/billing-done" element={<BillingDoneIsKorea />} /> {/* 지불이 완료되었습니다 */}
          <Route path="/account/mypage/doctor-info" element={<DoctorInfo />} /> {/* 마이페이지 의사정보 */}
          <Route path="/account/mypage/etc-info" element={<EtcInfo />} /> {/* 마이페이지 기타정보 */}
          <Route path="/account/mypage/address-manage" element={<AddressManage />} /> {/* 마이페이지 주소지관리 */}
          <Route path="/account/grade" element={<Grade />} /> {/* 등급 */}
          <Route path="/account/clinical-configurations" element={<ClinicalConfigurations />} /> {/* 임상환경설정 */}
          {/* education */}
          <Route path="/education" element={<CertifiedTraining />} /> {/* 인증교육 동영상 */}
          <Route path="/education/certified-training" element={<CertifiedTraining />} /> {/* 인증교육 동영상 */}
          <Route path="/education/correction-image-list" element={<CorrectionImageList />} /> {/* 세라핀 교정 THE 잘하기 */}
          <Route path="/education/correction-image-list/:boardId" element={<BoardView />} /> {/* 세라핀 교정 THE 잘하기 상세 */}
          <Route path="/education/educational-materials-list" element={<EducationalMaterialsList />} /> {/* 교육자료 */}
          <Route path="/education/educational-materials-list/:boardId" element={<BoardView />} /> {/* 교육자료 상세*/}
          <Route path="/education/sample-seraview-list" element={<SampleSeraviewList />} /> {/* Sample Seraview 보기*/}
          <Route path="/education/clinical-cases-list" element={<ClinicalCasesList />} /> {/* 임상증례 */}
          <Route path="/education/clinical-cases-list/:galleryId" element={<ClinicalCasesView />} /> {/* 임상증례 상세 */}
          <Route path="/education/library-list" element={<LibraryList />} /> {/* 자료실 */}
          <Route path="/education/library-list/:boardId" element={<BoardView />} /> {/* 자료실 상세 */}
          {/* support */}
          <Route path="/support" element={<Faq />} /> {/* 자주묻는질문  */}
          <Route path="/support/faq" element={<Faq />} /> {/* 자주묻는질문 */}
          <Route path="/support/event-list" element={<EventList />} /> {/* 이벤트 */}
          <Route path="/support/event-list/:eventBoardId" element={<EventInfo />} /> {/* 이벤트게시판 상세 */}
          <Route path="/support/notice-list" element={<NoticeList />} /> {/* 공지사항 */}
          <Route path="/support/notice-list/:boardId" element={<BoardView />} /> {/* 공지사항 상세 */}
        </Route>
        {/*메딧링크 연동 */}
        <Route path="/medit/callback" element={<MeditCallBack />} />
        <Route path="/medit/case-list" element={<MeditCaseList />} />
        <Route path="/medit/case-list/:uuid" element={<MeditCaseInfo />} />
        {/*선호 치료계획 새창열림 */}
        <Route path="/study-plan/:studyId" element={<PreferStudyPlan />} />
        <Route path="/study-plan/:studyId/:studyPlanId" element={<PreferStudyDetail />} />
        {/*세라퀵 연동 */}
        <Route path="/seraquick/callback" element={<SeraquickLoading />} />
        {/*관리자에서 의사계정으로 로그인 연동 */}
        <Route path="/ott-login" element={<AdminLogin />} />
        <Route element={<IsToken />}>
          <Route path="/" element={<Login />} />
          <Route path="/join" element={<Join />} />
          <Route path="/find-id-form" element={<FindIdForm />} />
          <Route path="/find-pw" element={<FindPw />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
